import { View, Text, Pressable, StyleSheet } from 'react-native';
import { Feather } from '@expo/vector-icons';

const Checkbox = ({
  disabled, // boolean
  onPress,
  indeterminateIcon='minus',
  checkedIcon='check',
  color="#5b5b5b",
  value, 
  status, // 'checked' | 'unchecked' | 'indeterminate'
  testID,
  index,
  width=20,
  height=20,
  borderSize=2,
  iconSize=15,
  ...rest
}) => {
  const checked = status === 'checked';
  const indeterminate = status === 'indeterminate';

  return (
    <Pressable
      {...rest}
      role="checkbox"
      accessibilityState={{ disabled, checked }}
      onPress={disabled ? undefined : onPress}
      style={[styles.container, {width, height, borderColor: color, borderWidth: borderSize}]}
    >
      <View style={{ opacity: indeterminate || checked ? 1 : 0, alignItems: 'center', marginVertical: 'auto' }}>
        <Feather name={indeterminate ? indeterminateIcon : checkedIcon} size={iconSize} color={color} />
      </View>
    </Pressable>
  );
};

export default Checkbox;

const styles = StyleSheet.create({
  container: {
    borderRadius: 3,
    backgroundColor: 'transparent',
  },
});

