import React from 'react';
import PropTypes from 'prop-types'
import { Text, ScrollView } from 'react-native';
import { ScreenContainer, Section } from '../../../components';
import { useTheme } from '@react-navigation/native';

const RulesScreen = () => {

  const { rulesBodyText } = useTheme();

  return (
    <ScreenContainer>
      <ScrollView style={{paddingHorizontal: 20}}>
        <Section accordion heading="POLITIKA PRIVATNOSTI">
          <Text style={rulesBodyText}>Ova politika ima za cilj da vas obavesti kako Lenina putujuće nosiljke, (u daljem tekstu "LPN" ili "mi") prikupljamo i koristimo informacije koje posetioci naše veb stranice www.lenastravelingcarriers.rs, u daljem tekstu, naša "Veb lokacija", ljudi koji se registruju na našoj veb lokaciji, svako ko rentira ili testira nosiljke, kao i pojedinci koji komuniciraju sa našim Veb lokacija putem e-maila ili na neki drugi način (u daljem tekstu, "vi", "vas", "vaš").</Text>
          <Text style={rulesBodyText}>Posetom naše veb stranice, gledanjem njenog sadržaja, registrovanjem na našoj veb lokaciji, prijavljivanjem na rentiranje i testiranje nosiljki, i komunikacijom sa nama putem e-maila ili na neki drugi način, vi pristajete na uslove ove Politike privatnosti.</Text>
          <Text style={rulesBodyText}>Sve informacije i podaci koje dobijamo od vas ili o vama, kao što su vaše ime, adresa, geografska lokacija, e-mail adresa, korisničko ime, lozinke, izbor nosiljki za rentiranje ili testiranje, i komunikacije regulišu ova Politika privatnosti. Ova pravila o privatnosti ne odnose se na veb lokacije trećih strana sa kojih možete pristupiti našoj veb lokaciji ili bilo koji od materijala na našoj veb lokaciji.</Text>
          <Text style={rulesBodyText}>Ako ne želite da pristanete na uslove ove Politike privatnosti, molimo vas da ne koristite našu veb lokaciju, nemojte se registrovati na našoj veb lokaciji, davati vaše podatke, nemojte se prijavljivati za rentiranja i testiranja nosiljki.</Text>
          <Text style={rulesBodyText}></Text>
          <Text style={rulesBodyText}></Text>
        </Section>
        <Section heading="Kada prikupljamo podatke?">
          <Text style={rulesBodyText}>Okolnosti pod kojim možemo da prikupljamo vaše lične podatke i obrađujemo ih su sledeće:</Text>
          <Text style={rulesBodyText}>kada se registrujete na našu Veb lokaciju, kada posetite našu veb lokaciju i prijavite se za trenutno dostupne nosiljke za rentiranje i testiranje. Ne mogu se svi podaci koje prikupljamo lično identifikovati.</Text>
          <Text style={rulesBodyText}>Naša Veb lokacija i materijali i usluge pruženi putem Veb stranice nisu namenjeni deci ili onima mlađim od 16 godina. Ako neko mlađi od 16 godina želi da koristi našu Veb lokaciju, ta osoba je odgovorna za dobijanje saglasnosti od roditelja te osobe ili pravni staratelji.</Text>
        </Section>
        <Section heading="Zašto prikupljamo vaše podatke?" 
          text={[
            'Podatke koje prikupljamo od vas koristimo na sledeće načine:',
            'Da bismo vam pružili usluge rentiranja i testiranja nosiljki putem naše veb stranice Da bismo vam pružili informacije o nosiljkama koje ste rentirali ili bukirali, a niste uneli potvrdu o tome na našu Veb lokaciju. Možete odustati od primanja ovih komunikacija ako su u skladu sa pravilnikom.'
          ]} 
        />
        <Section heading="Sa kim delimo ove informacije?" 
          text={[
            'Informacije koje dajete trećim licima ćemo deliti samo na načine koji su opisani u ovoj politici privatnosti. Ne prodajemo, ne trgujemo, iznajmljujemo ili na drugi način ne objavljujemo lične podatke, osim kako je navedeno niže:',
            'Ako je potrebno za pružanje naših usluga, možemo otkriti podatke (kao što su ime i kontakt informacije) našim korisnicima usluga renitranja i testiranja kako bi nosiljke nesmetano mogle da se šalju. Otkrićemo podatke prikupljene od vas ili pružene od vas ako se od nas traži da to uradimo zakonom ili pravnim postupkom ili ako sarađujemo sa istragom o sprovođenju zakona ili ako to moramo učiniti da bismo zaštitili naša prava, ugled, imovinu ili sigurnost ili nas ili druge ili za odbranu ili sprovođenje naših prava ili obaveza. Podatke ćemo otkriti trećim stranama uz vaš prethodni pristanak. Ne mogu se svi podaci koje prikupljamo lično identifikovati.'
          ]} 
        />
        <Section heading="Uvid u podatke i njihovo brisanje" 
          text={[
            'Od nas možete zatražiti da izbrišemo neke ili sve informacije koje ste podelili sa nama, pod uslovom da, međutim, od nas nije potrebno da te podatke čuvamo kao deo ugovora sklopljenog između nas ili naše crne liste. Na primer, možda ćemo trebati da čuvamo evidenciju o vašim ličnim podacima, HE linkovima tokom određenog vremena, u slučaju da dođe do bilo kakvog spora ili druge prilike zbog koje bi te informacije mogle da zatraže treće strane ili vladini subjekti.',
            'Možete tražiti da izbrišemo podatke koje ste nam pružili putem e-maila lenastravelingcarriers@gmail.com.',
            'Ako verujete da imamo netačne ili nepotpune podatke o vama, možete zatražiti ažuriranje svojih podataka putem adrese e-pošte lenastravelingcarriers@gmail.com.',
            'Možete tražiti da ograničimo upotrebu vaših podataka samo na one podatke potrebne za obavljanje bilo koje saradnje sa nama. Na primer, možete tražiti da prestanemo da vam šaljemo e-mailove o napomenama koje šaljemo i koji nisu u skladu sa pravilnikom. Možete se odjaviti iz ovih komunikacija slanjem e-pošte na lenastravelingcarriers@gmail.com kao i sledeći uputstva o samim komunikacijama.',
            'Ako ne zatražite da izbrišemo podatke koje ste dobili, mi ćemo ih čuvati razumno vreme u slučaju da ubuduće odlučite da rentirate i testirate nosiljke za bebe. Na primer, ne morate se svaki put registrovati zbog rentiranja i testiranja nosiljki, kao i da to činite u budućnosti kada dobijete sledećedete. Čuvanjem vaših podataka olakšavamo proces rentiranja i testiranja, eventualnu zloupotrebu, kao i što nam omogućava da pratimo istoriju rentiranih i testiraih nosiljki.'
          ]} 
        />
        <Section heading="Promene u našoj Politici privatnosti" 
          text={[
            'Bilo kakvo usaglašavanje ili promene koje unesemo u ovu Politiku privatnosti biće objavljene na našem sajtu, te se preporučuje da, s vremena na vreme, isto proverite. Saglasni ste da mi zadržavamo pravo da u svakom trenutku vršimo adekvatne izmene, takođe i u slučaju promena pozitivnih propisa. Takođe ćemo vam poslati e-mail na adresu e-pošte koju ste nam dostavili i obavestiti vas da je ažurirana Politika privatnosti dostupna za pregled na našoj veb lokaciji. Saglasni ste za primanje takvih e-mailova i slažete se da ćete nas obaveštavati o svim promenama na vašoj željenoj adresi e-pošte.'
          ]} 
        />
        <Section heading="Kako da nas kontaktirate?" 
          text={[
            'Ukoliko imate bilo kakvih pitanja o našoj Politici privatnosti možete nas kontaktirati na našu e-mail adresu lenastravelingcarriers@gmail.com.'
          ]} 
        />
        <Section heading="ODGOVORNOST I SIGURNOST" 
          text={[
            'Lenine putujuće nosiljke ističu da preuzimanjem i korišćenjem naših marama i nosiljki, preuzimate i odgovornost za sigurnost sebe i svoje dece prilikom montiranja, nošenja i demontiranja.',
            'Molimo vas da informišete i pročitate upute savetnica za nošenje kako sigurno i pravilno nositi svoju bebu u marami ili nosiljci (npr. Nošenje na siguran način i Saveti za početnike u nošenju beba)',
            'Prilikom preuzimanja i slanja marame ili nosiljke molimo vas da ih pregledate da nije došlo do oštećenja, te ukoliko pronađete oštećenje odmah obavestite admine i ne koristite maramu ili nosiljku.',
            'Molimo vas da posebno obratite pažnju na deo koji se tiče održavanja, pranja i čišćenja, jer sve to utiče na sigurnost iste i životni vek.',
            'Nosiljke ne smeju biti izložene duvanskom dimu i vodite računa o kontaktu sa životinjama. Sama nosiljka ili marama ne sme da bude u kontaktu sa životinjama zbog alergijskih reakcija druge dece. Pazite na svoje bebe.'
          ]} 
        />
        <Section heading="VREMENSKI ROK" 
          text={[
            'Osnovna razlika između testiranja i dugoročne asistencije je u vremenu rentiranja i razlogu uzimanja.',
            '-Testiranje-',
            'Testiranja su nezavisna od rentiranja i kao i do sada svako će moći na ista da se prijavi.',
            'U slučaju da imate svoju odgovarajuću nosiljku ili maramu ili nedoumice šta vam odgovara pre kupovine iste, želite nešto da probate za vas su redovna testiranja koja obično traju 5 dana osim ako nije naglašeno drugačije.',
            'U svakom trenutku na testiranju se nalazi po jedan tip nosiljke za one koji nisu sigurni šta im tačno odgovara, i koju mogu dobiti na određeni dogovoreni period 5 ili 7 dana. Voljni smo da vam ustupimo na testiranje i probu određenu maramu ili nosiljku ukoliko za to postoje mogućnosti do maksimalno 7 dana, ali za te posebne želje, van redovnog testiranja plaćate obe poštarine.',
            'Prednost za testere je što možete da probate šta sve ima na tržištu i osetite razliku ili isprobate određeni tip nosiljke pre kupovine iste. Svi mogu da se prijave na redovna testiranja i imaju tu mogućnost probe.',
            'Nama zaista znači svako od vas kada se prijavi da testira i napiše obaveznu recenziju. Kada dobijemo donaciju mi se obavežemo na testiranje i recenzije i na taj način promovišemo brend i ujedno "otplacujemo" donaciju, što je jeftinije nego da kupujemo sve. Uz sve to neki od brendova nam daju svoje uslove i to je obično minimum 10 testera ili 20, vreme trajanja itd. Ovde timski rad stupa na snagu i vaše prijave.',
            'Ako odustanete od testiranja bez naravno validnog razloga (dete bolesno, ne nosi se više itd.) vi narušavate naš ugled i terate nas da opet tražimo testere koje smo možda odbili zbog vas. U skladu sa tim, odustajanje i nepisanje recenzija ćemo sankcionisati zabranom na dalja testiranja i rentiranja. Hvala na razumevanju i zaista se nadamo da za ovim neće biti potrebe.',
            '-Rentiranje-',
            'Dugoročna asistencija je namenjena roditeljima koji nemaju svoje marame i nosiljke, a žele da nose svoju decu i tu je vremenski rok stvar dogovora izmedu admina grupe i korisnika, a najčešće je minimum 3 meseca, maksimalno 9 meseci za određen tip nosiljke ukoliko drugačije nije dogovoreno.',
            'Moguće je samo jednom bukirati jedan tip nosiljke - recimo SSC baby, a potom je moguće ostaviti prijavu za toddler, ali ne i opet za baby. Svaka osoba finansijski ugrožena će moći dugoročno da rentira samo jednom jednu vrstu nosiljke - na primer jednom RS manje gramaže, jednom veće, SSC baby ili po potrebi SSC toddler ili onbu. Isto važi i za marame, elasticne, mei tai itd. Za jedno dete, ako dođe drugo, treće sve ponovo.',
            'Moguće je bukirati samo jednu maramu ili nosiljku za određeni vremenski period (za testiranje to ne važi, dešava se da imate u isto vreme 2 tesne nosiljke), te nije moguce da ikome ustupimo nekoliko stvari istovremeno jer time drugim roditeljima uskraćujete priliku da nose svoje dete, pa vas molimo da imate razumevanja.',
            'Admin tim zadržava pravo da iz bilo kog razloga odbije, povuče ili promeni rezervaciju i o tome vas pravovremeno obavesti. Takode zadržavamo pravo da određene nosiljke povučemo po potrebi ili vas zamolimo da nekome ustupite na neki vremenski period. Admin tim u ovom slučaju nije dužan da vam obrazlaže razloge i neće zloupotrebljavati ovo pravilo.',
            'Ukoliko odustajete od neke rezervacije (kupili ste svoje u međuvremenu) molimo vas da nas blagovremeno obavestite (makar 15 dana ranije kako bismo mogli nekome drugome da ponudimo), ali zamena rezervacija jer se pojavilo nešto bolje što vam se vise sviđa u budućnosti će rezultirati zabranom rentiranja. Svako odstupanje od ovog pravila biće sankcionisano zabranom daljeg rentiranja. Hvala na razumevanju i zaista se nadamo da za ovim neće biti potrebe.',
            'Za vreme rentiranja u obavezi ste da u proseku jednom nedeljno sa nama podelite sliku nosiljke u upotrebi. Nama je važno da one ne stoje i da se koriste.',
            'Molimo da ukoliko imate već svoju nosiljku i samo želite nešto da isprobate i bukirate na neki kraći vremenski period kontaktirate direktno admine kako bismo vam izašli u susret ako je moguće, jer prvenstveno želimo da obezbedimo nosiljke i marame dugoročno onima koji nemaju mogucnost da odvoje za kupovinu istih.',
            'Molimo vas za razumevanje da je nas fond ograničen iako se nadamo da će vremenom rasti.',
            'I dalje važi pravilo da nosiljke perete uz konsultaciju sa admin timom i da nas uvek obavestite pre svakog pranja, i čuvate ih kako bi i drugi roditelji mogli da ih koriste.'
          ]} 
        />
        <Section heading="RECENZIJA" 
          text={[
            'U obavezi ste da postavite minimum jednu sliku vas i rentirane-testirane nosiljke/marame u album "Recenzije" u našoj facebook grupi sa opisom iskustva (Recenzija na engleskom ako je moguće, a ukoliko imate problema sa prevodom obratite nam se i mi ćemo vam rado pomoći), obaveznim tagom #lenastravelingcarriers, osnovnim podacima o težini i starosti deteta, vas, i opisom iskustvo svojim rečima - ne morate da zvučite profesionalno, samo iskreno.',
            'Slika ne mora pokazivati vaše ili lice deteta ukoliko to ne želite. Molimo vas da makar jednu sliku napravite visokog kvaliteta na kojoj ne mora da se vidi lice vas ili deteta opet ponavljamo, ali nošene nosiljke, marame da. Ispod slike i recenzije u daljem nastavku svog komentara možete dodati sve slike koje želite, uz napomenu da ove slike i recenzija nema veze sa vašom drugom obavezom redovnog nedeljnog postavljanja slike nošene nosiljke u komentaru posta Gde smo se nosili ovog meseca.',
            'Na ovaj način i mi i drugi korisnici imamo uvid u prednosti i mane nosiljke na osnovu vaše recenzije.',
            'Ukoliko se u zahtevu brenda i opisu uslova testiranja traži još negde recenzija, dužni ste da za određenu nosiljku ili maramu ostavite i recenziju na sajtu-grupi proizvođača sa svim zahtevanim tagovima.',
            'Savet: Napišite recenziju što pre, jer posle smetnete sa uma.',
            'Još jednom, RECENZIJE SU OBAVEZNE za sve, POGOTOVO ZA TESTERE i molimo vas da odvojite 2 minuta i napisete iste u Album Recenzije u grupi i sve gde je još navedeno da je obavezno pisanje recenzija.',
            'Ukoliko ne pišete recenzije automatski ćete biti isključeni iz svih narednih testiranja i rentiranja. Hvala na razumevanju i zaista se nadamo da za ovim neće biti potrebe.',
            'Recenzije su "otplata" fonda koje dobijamo i ako vi ne pišete recenzije mi gubimo ugled i ne možemo da nabavimo ništa novo. Recenzije su način da se odužimo brendovima i mogućnost da od njih tražimo nešto opet u budućnosti.',
            'Ceo admin tim vredno radi zbog vas ali ne možemo sami, mora da postoji zajednički doprinos. Na ovaj način pomažete grupi i sebi da nam fond raste, jer u protivnom nam vezujete ruke da tražimo nešto.'
          ]} 
        />
        <Section heading="ROKOVI I POŠTARINA" 
          text={[
            'Molimo vas da poštujete ugovorene rokove slanja nosiljki i marama.',
            'Ukoliko postoji rezervacija posle vas molimo vas da obavezno kontaktirate sledećeg korisnika i proverite odgovarajuće vreme i dan slanja, kao i način preuzimanja.',
            'Prilikom rentiranja troškove slanja ste dužni da preuzmete na sebe u jednom pravcu u slučaju da je nosiljka/marama rentirana drugom korisniku ili u oba pravca kada se vraća admin timu.',
            'Ovo pravilo se često previdi od strane korisnika, pa imajte u vidu da ćemo zabraniti rentiranje ukoliko se ne ispoštuje.',
            'Ukoliko niste u mogućnosti da snosite troškove slanja molimo vas da nas pravovremeno obavestite o tome kako bi našli najbolji način da nađemo neko rešenje.'
          ]} 
        />
        <Section accordion heading="NAČIN SLANJA PAKETA" 
          text={[
            '1. Uvek šaljite express poštom, danas za sutra.',
            '2. Prilikom slanja uredno složite nosiljku i detaljno pregledajte.',
            '3. Nemojte nosiljku slati vlažnu ukoliko ste je oprali, produžite dok ne bude u potpunosti suva jer se u protivnom može ubuđati.',
            '4. Prilikom slanja molimo vas da nosiljku upakujete u dve kese - platnenu ukoliko je bila uz nosiljku ili neku drugu i plastičnu obavezno.',
            '5. Nemojte paket skroz oblepiti trakom jer možete onemogućiti sledećem roditelju da otvori bez da koristi makaze ili nož koji nikako ne preporučujemo prilikom otvaranja, jer možete slučajno oštetiti nosiljku.',
            '6. Nemojte dozvolite da koriste heftalicu prilikom zatvaranja paketa-pisma u pošti jer takođe to može oštetiti nosiljku.',
            '7. Imajte u vidu da su paketi često izloženi kiši, snegu, blatu, bacanju, pa u skladu sa tim i pakujte nosiljku.',
            '8. Proverite pre nego pošaljete da li ste spakovali sve delove (torbice, gricke, kaiševe, uputstva za nošenje, brošure proizvođača, promo materijal proizvođača,...).'
          ]} 
        />
      </ScrollView>
    </ScreenContainer>
  )
}

export default RulesScreen;
