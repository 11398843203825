import React from 'react';
import { useTheme } from '@react-navigation/native';
import { View } from 'react-native';

const UserSearchListSeparator = () => {
  const { UserSearchListSeparatorContainer } = useTheme();

  return (
    <View style={UserSearchListSeparatorContainer} />
  )
}

export default UserSearchListSeparator;