import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, ActivityIndicator } from 'react-native';
import { useQuery } from '@apollo/client';
import { useAuthContext } from '../../../context/useAuthContext';
import { ErrorMessage } from '../../../components';
import UserView from '../components/UserView';
import { fullUser } from '../../../queries';

const UserViewScreen = ({ navigation, route}) => {
  const { user_id, disabled } = route?.params || {};

  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const { loading, error, data } = useQuery(fullUser, { variables: { user_id }, context });

  const backToSearchScreen = () => {
    navigation.navigate('Users', {screen: 'Search'});
  }

  const editUserScreen = () => {
    navigation.navigate('Users', {screen: 'Edit', params: route.params});
  }

  if (loading) return <ActivityIndicator />;

  return (
    <>
      <ErrorMessage error={error} />
      <UserView 
        disabled={disabled}
        userData={data}
        onBack={backToSearchScreen}
        onEdit={editUserScreen}
      />
    </>
  )
}

UserViewScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default UserViewScreen;