import 'expo-asset';
import { apolloClient } from './utils/api';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';
import { useFonts } from 'expo-font';
import App from './App';
import { store, persistor } from './store';
import { AuthContextProvider } from './context/useAuthContext';

const Root = (props) => {
  // Global stuff like location tracking, task manager,......
  const [fontsLoaded] = useFonts({
    Roboto: require('./assets/fonts/Roboto-Regular.ttf'),
    RobotoBold: require('./assets/fonts/Roboto-Bold.ttf'),
  });
  
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {fontsLoaded && (
            <AuthContextProvider>
              <App />
            </AuthContextProvider>
          )}
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

export default Root;
