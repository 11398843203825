import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { View, Text, ScrollView } from 'react-native';
import { ErrorMessage } from '../../../components';
import CreateUserForm from '../components/CreateUserForm';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_ALL_IDP_USERS, CREATE_FULL_USER  } from '../../../queries';
import { useMutation } from '@apollo/client';


const UserCreateScreen = ({ navigation }) => {
  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const [createUser] = useMutation(CREATE_FULL_USER, { context });
  const [error, setError] = useState(null);

  const backToSearchScreen = () => {
    navigation.navigate('Users', {screen: 'Search'});
  }

  const handleCreateNewUser = async (data) => {
    try {
      const x = await createUser({
        variables: { data },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: GET_ALL_IDP_USERS, context }
        ]
      });
      navigation.navigate('Users', {screen: 'Search'});
    } catch (error) {
      setError(error)
    }
  }

  return (
    <ScrollView style={{paddingHorizontal: 20}}>
      <ErrorMessage error={error} />
      <CreateUserForm
        buttonText="Create User" 
        onBack={backToSearchScreen} 
        onSubmit={handleCreateNewUser} 
      />
    </ScrollView>
  )
}

UserCreateScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default UserCreateScreen;