import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { View, Text, ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import Constants from 'expo-constants';
import { ErrorMessage } from '../../../components';
import CarriesSearchList from '../components/CarriesSearchList';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_CARRIES } from '../../../queries';
import { useQuery, useApolloClient } from '@apollo/client';

const DEFAULT_PAGE_SIZE = 10;

const CarrySearchScreen = ({ navigation, where, setWhere }) => {
  const i18n = Constants?.expoConfig?.extra?.i18n;
  const config = useSelector((state) => state.config);
  const client = useApolloClient();
  const { token } = useAuthContext();
  const [refreshing, setRefreshing] = useState(false);
  const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * limit;

  const [fullData, setFullData] = useState(data);
  const [loadingMore, setLoadingMore] = useState(false);

  const context = {headers: {Authorization: `Bearer ${token}`}};
  const handleView = ({ carry_id }) => navigation.navigate('view', { carry_id });

  const handleRefresh = async () => {
    setRefreshing(true);
    await client.refetchQueries({
      include: [GET_CARRIES],
    });
    setRefreshing(false);
  }

  const handleCreate = () => navigation.navigate('create');

  const { loading, error, data, fetchMore } = useQuery(GET_CARRIES, { 
    variables: { where, limit, offset, orderBy: {column: 'createdAt', order: 'DESC'} },
    context,
  });

  const onLoadMore = async (offset) => {
    setLoadingMore(true);
    const x = await fetchMore({variables: {offset }});
    const merged =  {
      carries: {
        ...fullData.carries, 
        ...x.data.carries,
        items: [...fullData.carries.items, ...x.data.carries.items]
      }          
    };
    setFullData(merged);
    setLoadingMore(false);
  }

  useEffect(() => { setFullData(data) }, [data])

  return (
    <>
      {error && <ErrorMessage error={error} />}
      {!loading && (
        <CarriesSearchList 
          data={fullData}
          onSelect={handleView}
          onWhere={setWhere}
          where={where}
          onRefresh={handleRefresh}
          refreshing={refreshing}
          emptyText={i18n[config.locale].carry_search_empty}
          onCreate={handleCreate}
          onLoadMore={onLoadMore}
          footerActions={[
            {label: `Load More${loadingMore ? ' ...' : ''}`, action: () => onLoadMore(fullData.carries.items.length)}
          ]}
        />
      )}
      {loading && <ActivityIndicator />}
    </>
  )
}

CarrySearchScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default CarrySearchScreen;