import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { View, Text, ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import Constants from 'expo-constants';
import { ErrorMessage } from '../../../components';
import BrandsSearchList from '../components/BrandsSearchList';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_BRANDS } from '../../../queries';
import { useQuery, useApolloClient } from '@apollo/client';
 
const BrandSearchScreen = ({ navigation, where, setWhere, limit, setLimit, currentPage, setCurrentPage }) => {
  const i18n = Constants?.expoConfig?.extra?.i18n;
  const config = useSelector((state) => state.config);
  const client = useApolloClient();
  const { token } = useAuthContext();
  const [refreshing, setRefreshing] = useState(false);
  const offset = (currentPage - 1) * limit;

  const [fullData, setFullData] = useState(data);
  const [loadingMore, setLoadingMore] = useState(false);

  const context = {headers: {Authorization: `Bearer ${token}`}};
  const handleView = ({ brand_id }) => navigation.navigate('view', { brand_id });

  const handleRefresh = async () => {
    setRefreshing(true);
    await client.refetchQueries({
      include: [GET_BRANDS],
    });
    setRefreshing(false);
  }

  const handleCreate = () => navigation.navigate('create');

  const handlePageSizeChange = (value) => {
    setLimit(parseInt(value));
    setCurrentPage(1);
  };

  const { loading, error, data, fetchMore } = useQuery(GET_BRANDS, { 
    variables: { where, limit, offset },
    context 
  });

  const onLoadMore = async (offset) => {
    setLoadingMore(true);
    const x = await fetchMore({variables: {offset }});
    const merged =  {
      brands: {
        ...fullData.brands, 
        ...x.data.brands,
        items: [...fullData.brands.items, ...x.data.brands.items]
      }          
    };
    setFullData(merged);
    setLoadingMore(false);
  }

  useEffect(() => { setFullData(data) }, [data])

  return (
    <>
      {error && <ErrorMessage error={error} />}
      {!loading && (
        <BrandsSearchList 
          data={fullData}
          onSelect={handleView}
          onWhere={setWhere}
          where={where}
          onRefresh={handleRefresh}
          refreshing={refreshing}
          emptyText={i18n[config.locale].brand_search_empty}
          onCreate={handleCreate}
          onLoadMore={onLoadMore}
          footerActions={[
            {label: `Load More${loadingMore ? ' ...' : ''}`, action: () => onLoadMore(fullData.brands.items.length)}
          ]}
        />
      )}
      {loading && <ActivityIndicator />}
    </>
  )
}

BrandSearchScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default BrandSearchScreen;