import PropTypes from 'prop-types';
import Constants from 'expo-constants';
import { View, Text, TextInput, Platform, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import Button from '../../../components/Button';
import ErrorMessage from '../../../components/ErrorMessage';
import FormInputText from '../../form/components/fields/FormInputText';
import FormInputTextarea from '../../form/components/fields/FormInputTextarea';
import FormInputDate from '../../form/components/fields/FormInputDate';
import FormInputSelectBrand from '../../brands/components/fields/FormInputSelectBrand';
import FormInputSelectConfig from '../../config/components/FormInputSelectConfig';
import FormInputCheckboxGroupConfig from '../../config/components/FormInputCheckboxGroupConfig';
import FileSingle from '../../upload/components/FileSingle';
import useUpdate from '../hooks/useUpdate';
import { timestamptoDate } from '../../../utils/date';

import { buildSchema } from '../utils';

const EditCarryForm = ({
  carryData,
  buttonText = '',
  onBack,
  navigation,
  where,
}) => {
		const config = useSelector((state) => state.config);
  	const i18n = Constants?.expoConfig?.extra?.i18n;
  	const schema = buildSchema(true);

  	const { handleSubmit, control, reset, watch, formState, trigger, setValue } = useForm({
	  	resolver: yupResolver(schema),
	    mode: 'onBlur',
	  	defaultValues: {
	  		carry_id: carryData?.carry?.carry_id,
	      name: carryData?.carry?.name || "",
	      description: carryData?.carry?.description || "",
	      type: carryData?.carry?.type || "",
	      brand: carryData?.carry?.brand?.brand_id || "",
	      size: carryData?.carry?.size || "",
	      experienceLevel: carryData?.carry?.experienceLevel || "",
	      color: carryData?.carry?.color || "",
	      origin: carryData?.carry?.origin || "",
	      fabricDensity: carryData?.carry?.fabricDensity || "",
	      material: carryData?.carry?.material || "",
	      monthFrom: carryData?.carry?.monthFrom || "",
	      monthTo: carryData?.carry?.monthTo || "",
	      weightFrom: carryData?.carry?.weightFrom || "",
	      weightTo: carryData?.carry?.weightTo || "",
	      recension: carryData?.carry?.recension || "",
	      tags: carryData?.carry?.tags || "",
	      adminComment: carryData?.carry?.adminComment || "",
	      state: carryData?.carry?.state || "",
	      bookTest: carryData?.carry?.bookTest || "",
	      countriesBooking: carryData?.carry?.countriesBooking || [],
	      bookableFrom: timestamptoDate(carryData?.carry?.bookableFrom),
	      image: carryData?.carry?.image || null,
	  	}
		});

		const { image } = watch();
		if (image) delete(image['__typename']);

		const [onUpdate, { updating, errorUpdating }] = useUpdate(navigation, where);
  
  	return (
    	<View style={styles.container}>
      		<FormInputText 
		        control={control} 
		        name="name" 
		        label={i18n[config.locale].carry_field_name_label || "Name"}
		        placeholder={i18n[config.locale].carry_field_name_placeholder || "Unesite model, naziv kolekcije"}
		        rules={{required: schema.fields['name']?.exclusiveTests.required }} 
		      />
		      <FileSingle 
		        onChange={(v) => setValue('image', v)}
		        value={image}
		        type='carry' 
		        id={carryData?.carry?.carry_id} 
		      />
		      <FormInputSelectBrand
		        validate={trigger}
		        control={control} 
		        name="brand" 
		        label={i18n[config.locale].carry_field_brand_label || "Brand"}
		        placeholder={i18n[config.locale].carry_field_brand_placeholder || "Izaberi brand"}
		        rules={{required: schema.fields['brand']?.exclusiveTests.required }} 
		      />
		      <FormInputSelectConfig
		        validate={trigger}
		        control={control} 
		        name="type" 
		        type="CarryTypes"
		        label={i18n[config.locale].carry_field_type_label || "Type"} 
		        rules={{required: schema.fields['type']?.exclusiveTests.required }}
		      />
		      <FormInputSelectConfig
		        validate={trigger}
		        control={control} 
		        name="size" 
		        type="Sizes"
		        label={i18n[config.locale].carry_field_size_label || "Size"} 
		        rules={{required: schema.fields['size']?.exclusiveTests.required }}
		      />
		      <FormInputSelectConfig
		        validate={trigger}
		        control={control} 
		        name="experienceLevel" 
		        type="ExperienceRequirement"
		        label={i18n[config.locale].carry_field_experienceLevel_label || "Experience Level"} 
		        rules={{required: schema.fields['experienceLevel']?.exclusiveTests.required }}
		      />
		      <FormInputTextarea 
		        control={control} 
		        name="description" 
		        label={i18n[config.locale].carry_field_description_label || "Description"}
		        placeholder={i18n[config.locale].carry_field_description_placeholder || "Unesite description"}
		        rules={{required: schema.fields['description']?.exclusiveTests.required }} 
		      />
		      <FormInputText 
		        control={control} 
		        name="color" 
		        label={i18n[config.locale].carry_field_color_label || "Color"}
		        placeholder={i18n[config.locale].carry_field_color_placeholder || "Placeholder"}
		        rules={{required: schema.fields['color']?.exclusiveTests.required }} 
		      />
		      <FormInputText 
		        control={control} 
		        name="origin" 
		        label={i18n[config.locale].carry_field_origin_label || "Origin"}
		        placeholder={i18n[config.locale].carry_field_origin_placeholder || "Placeholder"}
		        rules={{required: schema.fields['origin']?.exclusiveTests.required }} 
		      />
		      <FormInputText 
		        control={control} 
		        name="fabricDensity" 
		        label={i18n[config.locale].carry_field_fabricDensity_label || "Fabric Density (g/m2)"}
		        placeholder={i18n[config.locale].carry_field_fabricDensity_placeholder || "Placeholder"}
		        rules={{required: schema.fields['fabricDensity']?.exclusiveTests.required }} 
		      />
		      <FormInputText 
		        control={control} 
		        name="material" 
		        label={i18n[config.locale].carry_field_material_label || "Materials"}
		        placeholder={i18n[config.locale].carry_field_material_placeholder || "Placeholder"}
		        rules={{required: schema.fields['material']?.exclusiveTests.required }} 
		      />
		      <FormInputText 
		        control={control} 
		        name="monthFrom" 
		        label={i18n[config.locale].carry_field_monthFrom_label || "Meseci (od)"}
		        placeholder={i18n[config.locale].carry_field_monthFrom_placeholder || "Unesite minimalan dozvoljen uzrast deteta"}
		        rules={{required: schema.fields['monthFrom']?.exclusiveTests.required }} 
		      />
		      <FormInputText 
		        control={control} 
		        name="monthTo" 
		        label={i18n[config.locale].carry_field_monthTo_label || "Meseci (do)"}
		        placeholder={i18n[config.locale].carry_field_monthTo_placeholder || "Unesite maximalan dozvoljen uzrast deteta"}
		        rules={{required: schema.fields['monthTo']?.exclusiveTests.required }} 
		      />
		      <FormInputText 
		        control={control} 
		        name="weightFrom" 
		        label={i18n[config.locale].carry_field_weightFrom_label || "Tezina kg (od)"}
		        placeholder={i18n[config.locale].carry_field_weightFrom_placeholder || "Unesite minimalnu dozvoljenu tezinu deteta"}
		        rules={{required: schema.fields['weightFrom']?.exclusiveTests.required }} 
		      />
		      <FormInputText 
		        control={control} 
		        name="weightTo" 
		        label={i18n[config.locale].carry_field_weightTo_label || "Tezina kg (do)"}
		        placeholder={i18n[config.locale].carry_field_weightTo_placeholder || "Unesite maximalnu dozvoljenu tezinu deteta"}
		        rules={{required: schema.fields['weightTo']?.exclusiveTests.required }} 
		      />
		      <FormInputTextarea 
		        control={control} 
		        name="recension" 
		        label={i18n[config.locale].carry_field_recension_label || "Links and groups for all required recensions"}
		        placeholder={i18n[config.locale].carry_field_recension_placeholder || "Unesite ovde"}
		        rules={{required: schema.fields['recension']?.exclusiveTests.required }} 
		      />
		      <FormInputTextarea 
		        control={control} 
		        name="tags" 
		        label={i18n[config.locale].carry_field_tags_label || "All required tags"}
		        placeholder={i18n[config.locale].carry_field_tags_placeholder || "#lenastravelingcarriers"}
		        rules={{required: schema.fields['tags']?.exclusiveTests.required }} 
		      />
		      <FormInputTextarea 
		        control={control} 
		        name="adminComment" 
		        label={i18n[config.locale].carry_field_adminComment_label || "Admin comment"}
		        placeholder={i18n[config.locale].carry_field_adminComment_placeholder || ""}
		        rules={{required: schema.fields['adminComment']?.exclusiveTests.required }} 
		      />
		      <FormInputTextarea 
		        control={control} 
		        name="state" 
		        label={i18n[config.locale].carry_field_state_label || "State of carrier"}
		        placeholder={i18n[config.locale].carry_field_state_placeholder || ""}
		        rules={{required: schema.fields['adminComment']?.exclusiveTests.required }} 
		      />
		      <FormInputSelectConfig
		        validate={trigger}
		        control={control} 
		        name="bookTest" 
		        type="Usage"
		        label={i18n[config.locale].carry_field_usedFor_label || "Used For"} 
		        rules={{required: schema.fields['bookTest']?.exclusiveTests.required }}
		      />
		      <FormInputCheckboxGroupConfig
		        validate={trigger}
		        type="Counties"
		        control={control}
		        name="countriesBooking" 
		        label="Used in Counties"
		        rules={{required: schema.fields['countriesBooking']?.exclusiveTests.required }} 
		        containerStyle={{marginBottom: 24}}
		        optionLabelStyle={{marginLeft: 10}}
		        optionsContainerStyle={{ minHeight: 100, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between'}}
		        color="#5b5b5b"
		      />
		      <FormInputDate
		        control={control} 
		        name="bookableFrom" 
		        label={i18n[config.locale].carry_field_bookableFrom_label || "Bookable/Testable From"}
		        description={i18n[config.locale].carry_field_bookableFrom_description || null}
		        rules={{required: schema.fields['bookableFrom']?.exclusiveTests.required }} 
		      />
		      {errorUpdating && <ErrorMessage error={errorUpdating} />}
		      <View style={styles.buttons}>
		        <Button onPress={() => onBack()} size="small" type="info" text="Back" />
		        <Button onPress={handleSubmit(onUpdate)} disabled={updating} size="small" text={buttonText} showLoading />
		      </View>
    	</View>
  	)
}

EditCarryForm.propTypes = {
  carryData: PropTypes.object,
  buttonText: PropTypes.string,
  onBack: PropTypes.func,
  navigation: PropTypes.object.isRequired, 
  where: PropTypes.object
}

export default EditCarryForm;

const styles = StyleSheet.create({
	container: {},
	buttons: {
		flexDirection: 'row', 
		justifyContent: 'space-between',
		marginBottom: 50  
	},
});