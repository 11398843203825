import React from 'react';
import { useTheme } from '@react-navigation/native';
import { View, Text } from 'react-native';
import { dateTimeRenderer } from '../../../utils/date';

const UserSearchListItem = ({ item }) => {
  const { displayName, email, createdAt, emailVerified, role } = item;
  const {
    userSearchListItemContainer, 
    userSearchListItemName,
    userSearchListItemEmail,
    userSearchListItemCreated,
    userSearchListUnverified,
    userSearchListUnapproved,
    userSearchListRole
  } = useTheme();

  return (
    <View style={userSearchListItemContainer}>
      <View>
        <Text style={userSearchListItemName}>{`${displayName}`}</Text>
        <Text style={userSearchListItemEmail}>{`${email}`}</Text>
        <Text style={userSearchListItemCreated}>{`${dateTimeRenderer(createdAt)}`}</Text>
      </View>
      <View>
        {!emailVerified && <Text style={userSearchListUnverified}>NOT VERIFIED</Text>}
        {!role && <Text style={userSearchListUnapproved}>NOT APPROVED</Text>}
        {role && <Text style={userSearchListRole}>{role}</Text>}
      </View>
    </View>
  )
}

export default UserSearchListItem;