import { useState } from 'react';
import { View } from 'react-native';
import SearchScreen from '../../../features/brands/screens/AdminSearch';
import ViewScreen from '../../../features/brands/screens/View';
import CreateScreen from '../../../features/brands/screens/Create';
import EditScreen from '../../../features/brands/screens/Edit';

const DEFAULT_PAGE_SIZE = 10;

const AdminBrandsScreen = ({ navigation, route }) => {

  const [page, setPage] = useState('search'); // view | create | edit | search (default)
  const [params, setParams] = useState({}); // brand_id
  const [where, setWhere] = useState(null); // {country: {eq: "ME"}}
  const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = (page, params) => {
    setPage(page);
    setParams(params);
  }

  return (
    <View style={{flex: 1}}>
      {page === 'search' && <SearchScreen navigation={{ navigate }} where={where} setWhere={setWhere} limit={limit} setLimit={setLimit} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
      {page === 'view' && <ViewScreen navigation={{ navigate }} {...params} route={{}} where={where} limit={limit} setLimit={setLimit} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
      {page === 'edit' && <EditScreen navigation={{ navigate }} {...params} where={where} limit={limit} setLimit={setLimit} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
      {page === 'create' && <CreateScreen navigation={{ navigate }} where={where} limit={limit} setLimit={setLimit} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
    </View>
  );
}

export default AdminBrandsScreen;
