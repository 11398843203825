import { gql } from '@apollo/client';

const GET_BRANDS = gql`
  query GetBrands($where: BrandsListFilter,$limit: Int, $offset: Int, $orderBy: [OrderByInput]) {
    brands(where: $where, limit: $limit, offset: $offset, orderBy: $orderBy) {
      totalCount
      items {
        brand_id
        name
        contact
        email
        comment
        deleted
        country
        lastModifiedBy {
          firstname
          lastname
        }
        modifiedAt
        createdAt
      }
    }
  }
`;

const GET_BRAND_OPTIONS = gql`
  query GetBrands($where: BrandsListFilter,$limit: Int, $offset: Int, $orderBy: [OrderByInput]) {
    brands(where: $where, limit: $limit, offset: $offset, orderBy: $orderBy) {
      totalCount
      items {
        brand_id
        name
      }
    }
  }
`;

const GET_BRAND = gql`
  query GetBrand($brand_id: String!) {
    brand(brand_id: $brand_id) {
      brand_id
      name
      contact
      email
      comment
      deleted
      country
      lastModifiedBy {
        firstname
        lastname
      }
      modifiedAt
      createdAt
    }
  }
`;

const CREATE_BRAND = gql`
  mutation CreateBrand($data: CreateBrandInput!) {
    createBrand(data: $data) {
      brand_id
      createdAt
    }
  }
`;

const UPDATE_BRAND = gql`
  mutation UpdateBrand($brand_id: String!, $data: UpdateBrandInput!) {
    updateBrand(brand_id: $brand_id, data: $data) {
      modifiedAt
    }
  }
`;

const DELETE_BRAND = gql`
  mutation DeleteBrand($brand_id: String!) {
    deleteBrand(brand_id: $brand_id)
  }
`;

const AUTHORIZE = gql`
  query Authorize($data: CreateUserInput) {
    authorize(data: $data){
      token
      payload{
        user_id
        email
        firstname
        lastname
        facebookName
        address
        postcode
        city
        country
        mobile
        facebookLink
        heLink
        signupReason
        whatYouKnowAboutUs
        referrals
        ownedCarries
        monthsUsed
        confusion
        childrenAgeWidth
        additionalComments
        termsAccepted
        role
      }
    }
  }
`;

const GET_TYPE = gql`
  query GetType($name: String!) {
    __type(name: $name) {
      name
      enumValues{
        name
      }
    }
  }
`;

const GET_BOOKINGS = gql`
  query GetBookings($where: BookingsListFilter,$limit: Int, $offset: Int, $orderBy: [OrderByInput]) {
    bookings(where: $where, limit: $limit, offset: $offset, orderBy: $orderBy) {
      totalCount
      items {
        booking_id
        status
        dateFrom
        dateTo
        carry {
          name
          image {
            filename
          }
        }
        user {
          firstname
        }
        comment
        adminComment
        createdAt
        modifiedAt
        lastModifiedBy {
          firstname
        }
      }
    }
  }
`;

const CREATE_BOOKING = gql`
  mutation CreateBooking($data: CreateBookingInput!) {
    createBooking(data: $data) {
      booking_id
      createdAt
    }
  }
`;

const GET_CARRIES = gql`
  query GetCarries($where: CarriesListFilter,$limit: Int, $offset: Int, $orderBy: [OrderByInput]) {
    carries(where: $where, limit: $limit, offset: $offset, orderBy: $orderBy) {
      totalCount
      items {
        carry_id
        name
        description
        type
        model
        createdAt
        brand {
          name
        }
        size
        color
        origin
        fabricDensity
        material
        monthFrom
        monthTo
        weightFrom
        weightTo
        tags
        state
        experienceLevel
        countriesBooking
        bookableFrom
        bookTest
        image {
          filename
          size
          uploadTimestamp
        }
        #currentUser {
        #  firstname
        #  lastname
        #}
      }
    }
  }
`;

const GET_CARRIES_FOR_OPTIONS = gql`
  query GetCarries($where: CarriesListFilter,$limit: Int, $offset: Int, $orderBy: [OrderByInput]) {
    carries(where: $where, limit: $limit, offset: $offset, orderBy: $orderBy) {
      totalCount
      items {
        carry_id
        name
      }
    }
  }
`;

const GET_CARRY = gql`
  query GetCarry($carry_id: String!) {
    carry(carry_id: $carry_id) {
      carry_id
      name
      description
      createdAt
      modifiedAt
      lastModifiedBy {
        firstname
        lastname
      }
      type
      brand {
        name
        brand_id
      }
      size
      experienceLevel
      color
      origin
      fabricDensity
      material
      monthFrom
      monthTo
      weightFrom
      weightTo
      recension
      tags
      adminComment
      state
      bookTest
      countriesBooking
      bookableFrom
      image {
        filename
        size
        uploadTimestamp
      }
    }
  }
`;

const CREATE_CARRY = gql`
  mutation CreateCarry($data: CreateCarryInput!) {
    createCarry(data: $data) {
      carry_id
      createdAt
    }
  }
`;

const UPDATE_CARRY = gql`
  mutation UpdateCarry($carry_id: String!, $data: UpdateCarryInput!) {
    updateCarry(carry_id: $carry_id, data: $data) {
      modifiedAt
    }
  }
`;

const GET_ALL_IDP_USERS = gql`
  query GetAllIdpUsers {
    idpUsers {
      user_id
      email 
      displayName
      disabled
      emailVerified
      createdAt
      role
    }
  }
`;

const GET_FULL_USER = gql`
  query GetUser($user_id: String!) {
    user(user_id: $user_id) { 
      user_id
      firstname
      lastname
      facebookName
      address
      postcode
      city
      country
      mobile
      email
      facebookLink
      heLink
      signupReason
      whatYouKnowAboutUs
      referrals
      ownedCarries
      monthsUsed
      confusion
      childrenAgeWidth
      additionalComments
      adminComment
      deleted
      approved
      role
      lastModifiedBy {
        firstname
        lastname
      }
      modifiedAt
      createdAt
    }
  }
`;

const GET_USERS = gql`
  query GetUsers($where: UsersListFilter,$limit: Int, $offset: Int, $orderBy: [OrderByInput]) {
    users(where: $where, limit: $limit, offset: $offset, orderBy: $orderBy) {
      totalCount
      items {
        user_id
        firstname
        lastname
        email 
        role
      }
    }
  }
`;

const GET_USERS_FOR_OPTIONS = gql`
  query GetUsers($where: UsersListFilter,$limit: Int, $offset: Int, $orderBy: [OrderByInput]) {
    users(where: $where, limit: $limit, offset: $offset, orderBy: $orderBy) {
      totalCount
      items {
        user_id
        firstname
        lastname
        country
      }
    }
  }
`;

const SET_USER_ROLE = gql`
  mutation SetUserRole($user_id: String!, $role: Role!) {
    setUserRole(user_id: $user_id, role: $role)
  }
`;

const DISABLE_USER = gql`
  mutation DisableUser($user_id: String!) {
    disableUser(user_id: $user_id)
  }
`;

const ENABLE_USER = gql`
  mutation EnableUser($user_id: String!) {
    enableUser(user_id: $user_id)
  }
`;

const CREATE_FULL_USER = gql`
  mutation CreateUser($data: AdminCreateUserInput!) {
    createUser(data: $data) {
      user_id
      createdAt
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($user_id: String!, $data: AdminUpdateUserInput!) {
    updateUser(user_id: $user_id, data: $data) {
      modifiedAt
    }
  }
`;

const UPLOAD_ASSET = gql`
  mutation UploadAsset($id: String!, $type: String!, $asset: Upload!) {
    uploadAsset(id: $id, type: $type, asset: $asset)
  }
`;

const GET_ASSET = gql`
  query Assets($id: String!, $type: String!, $filename: String!) {
    assets(id: $id, type: $type, filename: $filename) {
      url
    }
  }
`;

export {
  AUTHORIZE,
  GET_CARRIES as carries,
  GET_CARRY,
  GET_TYPE,
  GET_CARRIES,
  GET_CARRIES_FOR_OPTIONS,
  CREATE_CARRY,
  UPDATE_CARRY,
  GET_USERS as users,
  GET_FULL_USER as fullUser,
  SET_USER_ROLE,
  DISABLE_USER,
  ENABLE_USER,
  CREATE_FULL_USER,
  GET_ALL_IDP_USERS,
  GET_USERS_FOR_OPTIONS,
  GET_FULL_USER,
  UPDATE_USER,
  GET_BRANDS,
  GET_BRAND_OPTIONS,
  GET_BRAND,
  CREATE_BRAND,
  UPDATE_BRAND,
  DELETE_BRAND,
  UPLOAD_ASSET,
  GET_ASSET,
  GET_BOOKINGS,
  CREATE_BOOKING
  // MAKE_USER_ADMIN
}