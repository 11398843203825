import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Constants from 'expo-constants';
import { View, Text, ActivityIndicator, Platform, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { Picker } from '@react-native-picker/picker';
import { ErrorMessage } from '../../../components';
import Button from '../../../components/Button';
import FormInputMonthRangeCalendar from '../../form/components/fields/FormInputMonthRangeCalendar';
import FormInputTextarea from '../../form/components/fields/FormInputTextarea';
import FormInputSelectCarry from '../../carries/components/fields/FormInputSelectCarry';
import useCreate from '../hooks/useCreate';
import { useAuthContext } from '../../../context/useAuthContext';
import { monthYearToTimestamp, monthSelectionTimestamp, monthsYearsBetweenDates, dateTimeRenderer } from '../../../utils/date';
import { GET_BOOKINGS, GET_USERS_FOR_OPTIONS } from '../../../queries';
import { useQuery } from '@apollo/client';
import { buildSchema } from '../utils';

const BookingSearchScreen = ({ navigation, where, setWhere }) => {
  const i18n = Constants?.expoConfig?.extra?.i18n;
  const [onCreate, { creating, errorCreating }] = useCreate(navigation, where);
  const { token, role, user: authUser } = useAuthContext();

  const schema = buildSchema();
  const { handleSubmit, control, reset, watch, formState, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      carry: "",
      user: selectedUser,
      dateFrom: "",
      dateTo: "",
      comment: "",
      adminComment: "",
    }
  });
  const { carry, dateFrom, dateTo } = watch();

  const [booked, setBooked] = useState([]);
  const [selectedUser, setSelectedUser] = useState(role === 'ADMIN' ? usersData?.users.items.find(x => x.user_id === where?.user?.eq) : authUser);
  const config = useSelector((state) => state.config);
  

  const context = {headers: {Authorization: `Bearer ${token}`}};
  // const handleView = ({ booking_id }) => navigation.navigate('view', { booking_id });
  // const handleCreate = () => navigation.navigate('create');

  const { loading, error, data } = useQuery(GET_BOOKINGS, { 
    variables: { where },
    context 
  });

  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(GET_USERS_FOR_OPTIONS, { context });

  const whereConfig = [
    ...(role === 'ADMIN' ? [{
      name: "user",
      type: "select",
      placeholder: "Choose user",
      operation: "eq",
      options: usersData?.users.items.reduce((a,c) => ({...a, [c.user_id]: `${c.firstname} ${c.lastname}`}), {})
      // options: usersData?.idpUsers.reduce((a,c) => ({...a, [c.user_id]: c.email}), {})
    }]: [])
  ];

  console.log('dateFrom', dateFrom, 'dateTo', dateTo)

  const handleWhere = (field) => (value) => {
    const newWhere = { ...where, [field.name]: value ? {[field.operation || 'eq']: value} : undefined };
    const hasFilters = Object.keys(newWhere).find(k => newWhere[k]);
    setWhere(hasFilters ? newWhere : null);
  }

  useEffect(() => {
    setValue('user', selectedUser?.user_id)
  }, [selectedUser])

  useEffect(() => {
    reset();
    setSelectedUser(usersData?.users.items.find(x => x.user_id === where?.user?.eq))
  }, [where])
  

  return (
    <>
      <View style={{flex: 1}}>
        {!usersLoading && whereConfig.map(f => (
          <View key={f.name}>
            <Picker
              style={styles.formPicker}
              itemStyle={styles.formSelectItem}
              selectedValue={!where ? '' : where[f.name] && where[f.name][f.operation || 'eq']}
              onValueChange={handleWhere(f)}
            >
              <Picker.Item label={f.placeholder || "Select"} value="" />
              {Object.keys(f.options).map((id) => (<Picker.Item label={f.options[id]} value={id} key={id} />))}
            </Picker>
          </View>
        ))}
      </View>
      {booked && booked.map(b => {
        return (
          <View style={styles.bookWrap} key={b.booking_id}>
            <View style={styles.fieldWrap}>
              <Text style={styles.fieldLabel}>Carrier: </Text>
              <Text>{b.carry.name}</Text>
            </View>
            <View style={styles.fieldWrap}>
              <Text style={styles.fieldLabel}>From: </Text>
              <Text>{dateTimeRenderer(b.dateFrom)}</Text>
            </View>
            <View style={styles.fieldWrap}>
              <Text style={styles.fieldLabel}>To: </Text>
              <Text>{dateTimeRenderer(b.dateTo)}</Text>
            </View>
            <View style={styles.fieldWrap}>
              <Text style={styles.fieldLabel}>Comment: </Text>
              <Text>{b.comment}</Text>
            </View>
            <View style={styles.fieldWrap}>
              <Text style={styles.fieldLabel}>Admin Comment: </Text>
              <Text>{b.adminComment}</Text>
            </View>
          </View>
        );
      })}
      {loading && <ActivityIndicator />}
      {!loading && selectedUser && (
        <FormInputMonthRangeCalendar 
          fromFieldName="dateFrom"
          toFieldName="dateTo"
          control={control} 
          label="Range"
          rules={{required: schema.fields['dateFrom']?.exclusiveTests.required || schema.fields['dateTo']?.exclusiveTests.required }} 
          selectedMonthStyle={{backgroundColor: 'red'}}
          disabledMonthStyle={{backgroundColor: '#f0ebeb'}}
          booked={data.bookings.items.reduce((a,c) => a.concat(monthsYearsBetweenDates(new Date(c.dateFrom), new Date(c.dateTo-86400000))), [])}
          onBookedHide={() => setBooked([])}
          onBookedView={(y, m) => setBooked(data.bookings.items.filter(x => monthYearToTimestamp(y,m) >= x.dateFrom && monthYearToTimestamp(y,m) <= x.dateTo) || [])}
        />
      )}
      {dateFrom && dateTo && (
        <FormInputSelectCarry 
          name="carry"
          control={control} 
          label="Select Carrier"
          filter={{bookTest: {eq : 'BOOKING'}, countriesBooking: {contains: selectedUser.country}, bookableFrom: {lt: dateFrom}}}
        />
      )}
      {carry && (
        <>
          <FormInputTextarea 
            control={control} 
            name="comment" 
            label={i18n[config.locale].booking_field_comment_label || "Comment"}
            placeholder={i18n[config.locale].booking_field_comment_placeholder || "Unesite komentar"}
            rules={{required: schema.fields['comment']?.exclusiveTests.required }} 
          />
          {role === "ADMIN" && (
            <FormInputTextarea 
              control={control} 
              name="adminComment" 
              label={i18n[config.locale].booking_field_adminComment_label || "ÄdminComment"}
              placeholder={i18n[config.locale].booking_field_adminComment_placeholder || "Unesite komentar"}
              rules={{required: schema.fields['adminComment']?.exclusiveTests.required }} 
            />
          )}
          {errorCreating && <ErrorMessage error={errorCreating} />}
          <View style={styles.buttons}>
            <Button onPress={reset} size="small" type="info" text="Reset" />
            <Button onPress={handleSubmit(onCreate)} disabled={creating} size="small" text={'Book'} showLoading />
          </View>
        </>
      )}
    </>
  )
}

BookingSearchScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default BookingSearchScreen;

const styles = StyleSheet.create({
  container: {},
  formSelectItem: {
    fontFamily: 'Roboto',
    height: Platform.OS === 'ios' ? 88 : 36,
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 16
  },
  formPicker: {
    alignSelf: 'stretch',
    padding: 10,
    marginVertical: Platform.OS === 'web' ? 5 : 0,
    marginHorizontal: 'auto',
    borderRadius: 5,
    width: '100%',
    height: Platform.OS === 'ios' ? 88 : 'auto',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 16,
    backgroundColor: Platform.OS === 'web' ? '#f9f9f9' : 'transparent',
    borderColor: '#949494',
    borderWidth: Platform.OS === 'web' ? 1 : 0
  },
  buttons: {
    flexDirection: 'row', 
    justifyContent: 'space-between',
    marginBottom: 50  
  },
  bookWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    // justifyContent: 'space-between',
    backgroundColor: 'lightgreen',
    padding: 5
  },
  fieldWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '30%'
  },
  fieldLabel: {
    fontWeight: '700',
  }
});