import React from 'react';
import PropTypes from 'prop-types';
import Constants from 'expo-constants';
import { View, Text, TextInput, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import Button from '../../../components/Button';
import ErrorMessage from '../../../components/ErrorMessage';
import FormInputText from '../../form/components/fields/FormInputText';
import FormInputTextarea from '../../form/components/fields/FormInputTextarea';
import FormInputSelect from '../../form/components/fields/FormInputSelect';
import FormInputDate from '../../form/components/fields/FormInputDate';
import FormInputSelectBrand from '../../brands/components/fields/FormInputSelectBrand';
import FormInputSelectConfig from '../../config/components/FormInputSelectConfig';
import FormInputCheckboxGroupConfig from '../../config/components/FormInputCheckboxGroupConfig';
import useCreate from '../hooks/useCreate';

import { buildSchema } from '../utils';

const CreateCarryForm = ({
  buttonText = '',
  navigation,
  where,
  onBack,
}) => {
  const config = useSelector((state) => state.config);
  const i18n = Constants?.expoConfig?.extra?.i18n;
  const schema = buildSchema();
  const { handleSubmit, control, reset, watch, formState, trigger } = useForm({
  	resolver: yupResolver(schema),
    mode: 'onBlur',
  	defaultValues: {
      name: "",
      description: "",
      type: "",
      brand: "",
      size: "",
      experienceLevel: "",
      color: "",
      origin: "",
      fabricDensity: "",
      material: "",
      monthFrom: "",
      monthTo: "",
      weightFrom: "",
      weightTo: "",
      recension: "",
      tags: "",
      adminComment: "",
      state: "",
      bookTest: "",
      countriesBooking: [],
      bookableFrom: "",
  	}
  });
  const { isValid, errors } = formState;
  const { brand } = watch();

  const [onCreate, { creating, errorCreating }] = useCreate(navigation, where);

  return (
    <View style={styles.container}>
      <FormInputText 
        control={control} 
        name="name" 
        label={i18n[config.locale].carry_field_name_label || "Name"}
        placeholder={i18n[config.locale].carry_field_name_placeholder || "Unesite model, naziv kolekcije"}
        rules={{required: schema.fields['name']?.exclusiveTests.required }} 
      />
      <FormInputSelectBrand
        validate={trigger}
        control={control} 
        name="brand" 
        label={i18n[config.locale].carry_field_brand_label || "Brand"}
        placeholder={i18n[config.locale].carry_field_brand_placeholder || "Izaberi brand"}
        rules={{required: schema.fields['brand']?.exclusiveTests.required }} 
      />
      <FormInputSelectConfig
        validate={trigger}
        control={control} 
        name="type" 
        type="CarryTypes"
        label={i18n[config.locale].carry_field_type_label || "Type"} 
        rules={{required: schema.fields['type']?.exclusiveTests.required }}
      />
      <FormInputSelectConfig
        validate={trigger}
        control={control} 
        name="size" 
        type="Sizes"
        label={i18n[config.locale].carry_field_size_label || "Size"} 
        rules={{required: schema.fields['size']?.exclusiveTests.required }}
      />
      <FormInputSelectConfig
        validate={trigger}
        control={control} 
        name="experienceLevel" 
        type="ExperienceRequirement"
        label={i18n[config.locale].carry_field_experienceLevel_label || "Experience Level"} 
        rules={{required: schema.fields['experienceLevel']?.exclusiveTests.required }}
      />
      <FormInputTextarea 
        control={control} 
        name="description" 
        label={i18n[config.locale].carry_field_description_label || "Description"}
        placeholder={i18n[config.locale].carry_field_description_placeholder || "Unesite description"}
        rules={{required: schema.fields['description']?.exclusiveTests.required }} 
      />
      <FormInputText 
        control={control} 
        name="color" 
        label={i18n[config.locale].carry_field_color_label || "Color"}
        placeholder={i18n[config.locale].carry_field_color_placeholder || "Placeholder"}
        rules={{required: schema.fields['color']?.exclusiveTests.required }} 
      />
      <FormInputText 
        control={control} 
        name="origin" 
        label={i18n[config.locale].carry_field_origin_label || "Origin"}
        placeholder={i18n[config.locale].carry_field_origin_placeholder || "Placeholder"}
        rules={{required: schema.fields['origin']?.exclusiveTests.required }} 
      />
      <FormInputText 
        control={control} 
        name="fabricDensity" 
        label={i18n[config.locale].carry_field_fabricDensity_label || "Fabric Density (g/m2)"}
        placeholder={i18n[config.locale].carry_field_fabricDensity_placeholder || "Placeholder"}
        rules={{required: schema.fields['fabricDensity']?.exclusiveTests.required }} 
      />
      <FormInputText 
        control={control} 
        name="material" 
        label={i18n[config.locale].carry_field_material_label || "Materials"}
        placeholder={i18n[config.locale].carry_field_material_placeholder || "Placeholder"}
        rules={{required: schema.fields['material']?.exclusiveTests.required }} 
      />
      <FormInputText 
        control={control} 
        name="monthFrom" 
        label={i18n[config.locale].carry_field_monthFrom_label || "Meseci (od)"}
        placeholder={i18n[config.locale].carry_field_monthFrom_placeholder || "Unesite minimalan dozvoljen uzrast deteta"}
        rules={{required: schema.fields['monthFrom']?.exclusiveTests.required }} 
      />
      <FormInputText 
        control={control} 
        name="monthTo" 
        label={i18n[config.locale].carry_field_monthTo_label || "Meseci (do)"}
        placeholder={i18n[config.locale].carry_field_monthTo_placeholder || "Unesite maximalan dozvoljen uzrast deteta"}
        rules={{required: schema.fields['monthTo']?.exclusiveTests.required }} 
      />
      <FormInputText 
        control={control} 
        name="weightFrom" 
        label={i18n[config.locale].carry_field_weightFrom_label || "Tezina kg (od)"}
        placeholder={i18n[config.locale].carry_field_weightFrom_placeholder || "Unesite minimalnu dozvoljenu tezinu deteta"}
        rules={{required: schema.fields['weightFrom']?.exclusiveTests.required }} 
      />
      <FormInputText 
        control={control} 
        name="weightTo" 
        label={i18n[config.locale].carry_field_weightTo_label || "Tezina kg (do)"}
        placeholder={i18n[config.locale].carry_field_weightTo_placeholder || "Unesite maximalnu dozvoljenu tezinu deteta"}
        rules={{required: schema.fields['weightTo']?.exclusiveTests.required }} 
      />
      <FormInputTextarea 
        control={control} 
        name="recension" 
        label={i18n[config.locale].carry_field_recension_label || "Links and groups for all required recensions"}
        placeholder={i18n[config.locale].carry_field_recension_placeholder || "Unesite ovde"}
        rules={{required: schema.fields['recension']?.exclusiveTests.required }} 
      />
      <FormInputTextarea 
        control={control} 
        name="tags" 
        label={i18n[config.locale].carry_field_tags_label || "All required tags"}
        placeholder={i18n[config.locale].carry_field_tags_placeholder || "#lenastravelingcarriers"}
        rules={{required: schema.fields['tags']?.exclusiveTests.required }} 
      />
      <FormInputTextarea 
        control={control} 
        name="adminComment" 
        label={i18n[config.locale].carry_field_adminComment_label || "Admin comment"}
        placeholder={i18n[config.locale].carry_field_adminComment_placeholder || ""}
        rules={{required: schema.fields['adminComment']?.exclusiveTests.required }} 
      />
      <FormInputTextarea 
        control={control} 
        name="state" 
        label={i18n[config.locale].carry_field_state_label || "State of carrier"}
        placeholder={i18n[config.locale].carry_field_state_placeholder || ""}
        rules={{required: schema.fields['adminComment']?.exclusiveTests.required }} 
      />
      <FormInputSelectConfig
        validate={trigger}
        control={control} 
        name="bookTest" 
        type="Usage"
        label={i18n[config.locale].carry_field_usedFor_label || "Used For"} 
        rules={{required: schema.fields['bookTest']?.exclusiveTests.required }}
      />
      <FormInputCheckboxGroupConfig
        validate={trigger}
        type="Counties"
        control={control}
        name="countriesBooking" 
        label="Used in Counties"
        rules={{required: schema.fields['countriesBooking']?.exclusiveTests.required }} 
        containerStyle={{marginBottom: 24}}
        optionLabelStyle={{marginLeft: 10}}
        optionsContainerStyle={{ height: 100, flexWrap: 'wrap'}}
        color="#5b5b5b"
      />
      <FormInputDate
        control={control} 
        name="bookableFrom" 
        label={i18n[config.locale].carry_field_bookableFrom_label || "Bookable/Testable From"}
        description={i18n[config.locale].carry_field_bookableFrom_description || null}
        rules={{required: schema.fields['bookableFrom']?.exclusiveTests.required }} 
      />
      {errorCreating && <ErrorMessage error={errorCreating} />}
      <View style={styles.buttons}>
        <Button onPress={() => onBack()} size="small" type="info" text="Back" />
        <Button onPress={handleSubmit(onCreate)} disabled={creating} size="small" text={buttonText} showLoading />
      </View>
    </View>
  )
}

CreateCarryForm.propTypes = {
  buttonText: PropTypes.string,
  onBack: PropTypes.func,
  navigation: PropTypes.object.isRequired, 
  where: PropTypes.object
}

export default CreateCarryForm;

const styles = StyleSheet.create({
	container: {},
  buttons: {
    flexDirection: 'row', 
    justifyContent: 'space-between',
    marginBottom: 50  
  },
});