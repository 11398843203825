import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { View, Text, Pressable } from 'react-native';
import { useTheme } from '@react-navigation/native';

const Section = ({accordion, heading, text, children}) => {
  const { 
    sectionContainer, 
    sectionHeader, 
    sectionHeaderText, 
    sectionBody, 
    sectionBodyText 
  } = useTheme();

  const [expanded, setExpanded] = useState(false);

  const onToggle = () => setExpanded(!expanded);
  
  const TriggerWrapper = ({ wrap, children }) => (wrap ? <Pressable onPress={onToggle}>{children}</Pressable> : <>{children}</>);
  
  return (
    <View style={[sectionContainer]}>
      <View style={[sectionHeader]}>
        <TriggerWrapper wrap={accordion}>
          <Text style={[sectionHeaderText]}>{heading}</Text>
        </TriggerWrapper>
      </View>
      <View style={[sectionBody, expanded ? {display: 'none'} : {}]}>
        {children}
        {text && text.map(t => (<Text key={Math.random()} style={[sectionBodyText]}>{t}</Text>))}
      </View>
    </View>
  )
}

export default Section;