import React from 'react';
import { Text, View, Platform } from 'react-native';
import { useRoute, useNavigation } from '@react-navigation/native';
import { SimpleLineIcons } from '@expo/vector-icons';
import Button from './Button';

const CreateButton = () => {
  const route = useRoute();
  const navigation = useNavigation();

  // console.log(navigation.getState(), route) 
  const handleClick = () => {
    navigation.navigate(route.name, {screen: 'Create'});
  }
  const allowedRoutes = ['Users'];
  if (!allowedRoutes.includes(route.name)) return null;

  return (
    <View>
      <Button 
        wrapStyleCustom={{paddingVertical: 0}}
        buttonStyleCustom={{paddingVertical: 0, margin: 0}}
        type="control" 
        icon={() => <SimpleLineIcons name="plus" size={24} color="black" />} 
        onPress={handleClick} 
      />
    </View>
  );
}

export default CreateButton;