import React from 'react';
import { SafeAreaView, View } from 'react-native';
import { useTheme } from '@react-navigation/native';

const ScreenContainer = ({
  children
}) => {
  const { screenContainerStyle, safeAreaStyle } = useTheme();

  return (
    <SafeAreaView style={safeAreaStyle}>
      <View style={screenContainerStyle}>
        {children}
      </View>
    </SafeAreaView>
  )
}

export default ScreenContainer;