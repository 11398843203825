import { useState } from 'react';
import PropTypes from 'prop-types';
import Constants from 'expo-constants';
import { View, Text, TextInput, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthContext } from '../../../context/useAuthContext';
import Button from '../../../components/Button';
import ErrorMessage from '../../../components/ErrorMessage';
import FormInputTextarea from '../../form/components/fields/FormInputTextarea';
import FormInputDate from '../../form/components/fields/FormInputDate';
import FormInputSelectCarry from '../../carries/components/fields/FormInputSelectCarry';
import useCreate from '../hooks/useCreate';

import { buildSchema } from '../utils';
import { timestamptoDate } from '../../../utils/date';

const CreateBookingForm = ({
  buttonText = '',
  navigation,
  where,
  onBack,
}) => {
  const [step, setStep] = useState(1);
  const { user, role } = useAuthContext();
  const config = useSelector((state) => state.config);
  const i18n = Constants?.expoConfig?.extra?.i18n;
  const schema = buildSchema();
  const { handleSubmit, control, reset, watch, formState, trigger } = useForm({
  	resolver: yupResolver(schema),
    mode: 'onBlur',
  	defaultValues: {
      carry: "",
      user: role === 'ADMIN' ? "" : user.user_id,
      dateFrom: "",
      dateTo: "",
      comment: "",
      adminComment: "",
      carries: []
  	}
  });
  const { isValid, errors } = formState;
  const { dateFrom, dateTo } = watch();

  const [onCreate, { creating, errorCreating }] = useCreate(navigation, where);

  return (
    <View style={styles.container}>
      {step === 1 && (
        <View style={{paddingVertical: 24}}>
          <FormInputDate
            control={control} 
            name="dateFrom" 
            label={i18n[config.locale].booking_field_dateFrom_label || "From"}
            description={i18n[config.locale].booking_field_dateFrom_description || null}
            rules={{required: schema.fields['dateFrom']?.exclusiveTests.required }} 
            min={timestamptoDate(new Date().valueOf())}
          />
          <FormInputDate
            control={control} 
            name="dateTo" 
            label={i18n[config.locale].booking_field_dateTo_label || "To"}
            description={i18n[config.locale].booking_field_dateTo_description || null}
            rules={{required: schema.fields['dateTo']?.exclusiveTests.required }} 
            min={dateFrom}
          />
          <View style={styles.buttons}>
            <Button onPress={() => setStep(2)} size="small" text={'Next'} />
          </View>
        </View>
      )}
      {step === 2 && (
        <>
          <FormInputTextarea 
            control={control} 
            name="comment" 
            label={i18n[config.locale].booking_field_comment_label || "Comment"}
            placeholder={i18n[config.locale].booking_field_comment_placeholder || "Unesite komentar"}
            rules={{required: schema.fields['comment']?.exclusiveTests.required }} 
          />
          {role === "ADMIN" && (
          	<FormInputTextarea 
    	        control={control} 
    	        name="adminComment" 
    	        label={i18n[config.locale].booking_field_adminComment_label || "ÄdminComment"}
    	        placeholder={i18n[config.locale].booking_field_adminComment_placeholder || "Unesite komentar"}
    	        rules={{required: schema.fields['adminComment']?.exclusiveTests.required }} 
    	    />
          )}
          {errorCreating && <ErrorMessage error={errorCreating} />}
          <View style={styles.buttons}>
            <Button onPress={() => onBack()} size="small" type="info" text="Back" />
            <Button onPress={handleSubmit(onCreate)} disabled={creating} size="small" text={buttonText} showLoading />
          </View>
        </>
      )}
    </View>
  )
}

CreateBookingForm.propTypes = {
  buttonText: PropTypes.string,
  onBack: PropTypes.func,
  navigation: PropTypes.object.isRequired, 
  where: PropTypes.object
}

export default CreateBookingForm;

const styles = StyleSheet.create({
	container: {},
  buttons: {
    flexDirection: 'row', 
    justifyContent: 'space-between',
    marginBottom: 50  
  },
});