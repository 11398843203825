// import firebase from 'firebase'
import { 
  getAuth, 
  EmailAuthProvider, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  onAuthStateChanged, 
  signOut, 
  sendEmailVerification, 
  sendPasswordResetEmail ,
  reauthenticateWithCredential
} from 'firebase/auth';

const auth = getAuth();

export const getUser = () => auth.currentUser

export const listenAuthStateChanged = (args) => onAuthStateChanged(auth, args)
// Notice Firebase automatically signs user in when their account is created
export const signUp = async ({ email = '', password = '' }) => {
  await createUserWithEmailAndPassword(auth, email, password);
  sendVerification();
}

export const signIn = ({ email = '', password = '' }) => signInWithEmailAndPassword(auth, email, password);
export const sendVerification = () => sendEmailVerification(auth.currentUser);
export const logOut = () => signOut(auth);
export const reload = () => getUser().reload();
export const sendPasswordReset = ({ email = '' }) => sendPasswordResetEmail(auth, email);



export const reauthenticate = ({ email = '', password = '' }) =>
  reauthenticateWithCredential(getUser(), EmailAuthProvider.credential(email, password))

export const updatePassword = ({ password = '' }) => getUser().updatePassword(password)
  