import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@react-navigation/native';
import { View, Text } from 'react-native';
import { ScreenContainer, ErrorMessage, Button } from '../../../components';
import { reload, getUser, sendVerification, logOut } from '../../../utils/user';
import { useAuthContext } from '../../../context/useAuthContext';

const UnverifiedWelcomeScreen = ({navigation}) => {

  const { setEmailVerified, user } = useAuthContext();
  const {firstname, lastname, email} = user || {};

  const [error, setError] = useState(null);
  const {heading1, paragraph, rowSpaceBetween} = useTheme();

  const handleReload = async () => {
    try {
      await reload();
      const firebaseUser = getUser();
      await setEmailVerified(firebaseUser.emailVerified);
    } catch (error) {
      setError(error);
    }
  }

  const handleSendVerification = async () => {
    try {
      await sendVerification();
    } catch (error) {
      setError(error);
    }
  }

  const handleCancel = async () => {
    try {
      await logOut();
    } catch (error) {
      setError(error);
    }
  }

  return (
    <ScreenContainer>
      <ErrorMessage error={error} />
      <Text style={heading1}>Welcome {firstname} {lastname}</Text>
      <Text style={paragraph}>
        Please check your email "{email}"
        We have sent you an email with instructions on how to verify your email
        address. Click on the link in the email to get started.
      </Text>
      <View style={rowSpaceBetween}>
        <Button text="Done" onPress={handleReload} showLoading />
        <Button text="Resend Email" onPress={handleSendVerification} showLoading />
        <Button type="danger" text="Cancel" onPress={handleCancel} showLoading />
      </View>
    </ScreenContainer>
  )
}

UnverifiedWelcomeScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default UnverifiedWelcomeScreen;