import React from 'react';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';
import { SimpleLineIcons } from '@expo/vector-icons';
import UnverifiedWelcomeScreen from '../features/unverified-welcome/screens/UnverifiedWelcomeScreen';
import LanguageMenuItems from '../features/language-switcher/components/LanguageMenuItems';
import theme from '../utils/theme';
import { logOut } from '../utils/user';

const MyTheme = { ...DefaultTheme, ...theme };
const Drawer = createDrawerNavigator();

const UnverifiedNavigator = (props) => {

  return (
    <NavigationContainer theme={MyTheme} linking={{prefixes: ['/'], config: {
      initialRouteName: 'UnverifiedWelcome', 
      screens: {
        UnverifiedWelcome: {
          path: ''
        }
      }
    }}}>
      <Drawer.Navigator 
        initialRouteName='UnverifiedWelcome'
        screenOptions={{
          drawerStyle: {backgroundColor: theme.colors.headerBackground},
          drawerActiveTintColor: theme.colors.headerActive,
          drawerActiveBackgroundColor: theme.colors.headerBackground,
          drawerInactiveTintColor: theme.colors.headerInactive,
          sceneContainerStyle: {backgroundColor: theme.colors.primaryBackgroundColor},
          headerStyle: {backgroundColor: theme.colors.headerBackground}
        }}
        drawerContent={(props) => {
          const { navigation, state } = props;
          return (
            <DrawerContentScrollView {...props}>
                <DrawerItemList {...props} />
                <DrawerItem 
                  label="Logout" 
                  onPress={() => logOut()}
                  icon={({size, color}) => <SimpleLineIcons name="logout" size={size} color={color} />}
                />
                <LanguageMenuItems />
            </DrawerContentScrollView>
          );
        }}
      >
        <Drawer.Screen 
          name="UnverifiedWelcome" 
          component={UnverifiedWelcomeScreen} 
          options={{ 
            title: 'Verification',
            drawerIcon: ({size, color}) => <SimpleLineIcons name="shield" size={size} color={color} />
          }} 
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
}

export default UnverifiedNavigator;
