import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import { useTheme } from '@react-navigation/native';

const ErrorMessage = ({ error }) => {
  const { errorContainer, errorText } = useTheme();

  if (!error) return null

  return (
    <View style={errorContainer}>
      <Text style={errorText}>{error.message}</Text>
    </View>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.object,
}

export default ErrorMessage;