import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, ActivityIndicator } from 'react-native';
import { useQuery } from '@apollo/client';
import { useAuthContext } from '../../../context/useAuthContext';
import { ErrorMessage } from '../../../components';
import CarryView from '../components/CarryView';
import { GET_CARRY } from '../../../queries';

const CarryViewScreen = ({ navigation, route, carry_id, where}) => {

  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const { loading, error, data } = useQuery(GET_CARRY, { variables: { carry_id }, context });

  const backToSearchScreen = () => navigation.navigate('search');
  const editScreen = () => navigation.navigate('edit', { carry_id });

  if (loading) return <ActivityIndicator />;

  return (
    <>
      <ErrorMessage error={error} />
      <CarryView 
        carryData={data}
        onBack={backToSearchScreen}
        onEdit={editScreen}
        where={where}
      />
    </>
  )
}

CarryViewScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default CarryViewScreen;