import { useState } from 'react';
import { Text, View, StyleSheet, Platform, Pressable } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Constants from 'expo-constants';
import theme from '../../../../utils/theme';
import { TODAY, THIS_YEAR, THIS_MONTH, MONTHS, monthSelectionTimestamp, monthYearToTimestamp, monthsYearsBetweenDates } from '../../../../utils/date';
const { colors } = theme;

const FormInputMonthRangeCalendar = ({
  fromFieldName,
  toFieldName,
  control, 
  label, 
  rules={}, 
  containerStyle={}, 
  labelContainerStyle={},
  labelStyle={},
  requiredStyle={},
  errorStyle={},
  selectedMonthStyle={},
  disabledMonthStyle={},
  showYearsBack=1, 
  showYearsAhead=1,
  booked=[],
  onBookedHide=() => null,
  onBookedView=() => null
}) => {
  const i18n = Constants?.expoConfig?.extra?.i18n;
  const config = useSelector((state) => state.config);
  const { field: fromField, fieldState: fromFieldState } = useController({name: fromFieldName, control});
  const { onChange: fromFieldOnChange, value: fromFieldValue } = fromField;
  const { invalid: fromFieldInvalid, error: fromFieldError } = fromFieldState;

  const { field: toField, fieldState: toFieldState } = useController({name: toFieldName, control});
  const { onChange: toFieldOnChange, value: toFieldValue } = toField;
  const { invalid: toFieldInvalid, error: toFieldError } = toFieldState;

  const minYear = THIS_YEAR - showYearsBack;
  const maxYear = THIS_YEAR + showYearsAhead;
  const yearRange = Array.from({length: maxYear - minYear + 1}, (v, k) => k + minYear);
  const [blocks, setBlocks] = useState({[THIS_YEAR]: true, [THIS_YEAR+1]: THIS_MONTH === 11 ? true : false});
  const isBooked = (y, m) => booked.includes(`${y}-${m}`);

  const isMonthSelected = (y, m) => {
  	if (fromFieldValue && !toFieldValue) {
  		const fromDate = new Date(fromFieldValue);
  		return fromDate.getFullYear() === y && MONTHS[fromDate.getMonth()] === m;
  	} 

  	if (fromFieldValue && toFieldValue) {
  		const fromDate = new Date(fromFieldValue);
  		const toDate = new Date(toFieldValue);
  		const monthDate = monthYearToTimestamp(y, m);
  		return monthDate >= fromDate.valueOf() && monthDate < toDate.valueOf();
  	}
  }

  const onSelect = (y,m) => {
  	// console.log('from', fromFieldValue, 'to', toFieldValue)
    
    if (isBooked(y, m)) {
      // onBookedView(y, m);
      // return;
    } else {
      onBookedHide();
    }
    
    // Can't select past time
    if (monthYearToTimestamp(y,m) < TODAY) return;
      
    if (!fromFieldValue && !toFieldValue) {
    	fromFieldOnChange(monthYearToTimestamp(y,m));
    } 

    if (fromFieldValue && toFieldValue) {
	  	fromFieldOnChange("");
		toFieldOnChange("");
	}

	if (fromFieldValue && !toFieldValue) {
	  	if (fromFieldValue === monthYearToTimestamp(y,m)) {
    		toFieldOnChange(monthYearToTimestamp(y, m, true));
	  	} else {
	  		if (monthYearToTimestamp(y,m) > fromFieldValue) {
	  			const betweenMonths = monthsYearsBetweenDates(new Date(fromFieldValue), new Date(monthYearToTimestamp(y, m)));
	          	fromFieldOnChange(monthSelectionTimestamp([...betweenMonths].shift()));
    		  	toFieldOnChange(monthSelectionTimestamp([...betweenMonths].pop(), true));
	  		}
	  	}
	}
  }

  return (
    <View style={[styles.container, containerStyle]}>    
      <View style={[styles.labelContainer, labelContainerStyle]}>
        <Text style={[styles.label, labelStyle]}>{label}</Text>
        {rules.required && (<Text style={[styles.required, requiredStyle]}>*</Text>)}
        {fromFieldInvalid && (<Text style={[styles.error, errorStyle]}>{fromFieldError.message}</Text>)}
        {toFieldInvalid && (<Text style={[styles.error, errorStyle]}>{toFieldError.message}</Text>)}
      </View>

      <View style={styles.calendarContainer}>
	      {yearRange.map(y => (
	        <View key={`${y}`} style={styles.yearBlock}>
	            <Pressable style={styles.yearTrigger} onPress={() => setBlocks({ ...blocks, [y]: !blocks[y] })}>
	              <Text style={styles.yearText}>{y}</Text>
	            </Pressable>
	            <View style={[styles.yearContent, {display: blocks[y] ? 'flex' : 'none' }]}>
	              {MONTHS.map(m => {
	                return (
	                  <Pressable 
	                    key={`${y}-${m}`} 
	                    style={[
	                      styles.monthTrigger,
	                      isMonthSelected(y, m) ? selectedMonthStyle : {},
	                      // isBooked(y, m) ? {backgroundColor: 'blue'} : {},
	                      monthYearToTimestamp(y,m) < TODAY ? disabledMonthStyle : {},
	                    ]} 
	                    onPress={() => onSelect(y,m)}
	                  >
	                    <Text style={styles.monthText}>{i18n[config.locale][m] || m}</Text>
	                    {isBooked(y, m) && (<Pressable onPress={() => onBookedView(y, m)} style={styles.tag}><MaterialIcons name="book-online" size={24} color="black" /></Pressable>)}
	                  </Pressable>
	                );
	              })}
	            </View>
	        </View>
	      ))}
	  </View>
      
    </View>
  );
}

export default FormInputMonthRangeCalendar;

const styles = StyleSheet.create({
  container: {
    marginBottom: 15
  },
  label: {
    fontFamily: 'RobotoBold',
    fontSize: 14,
    color: colors.primaryTextColor,
    textAlign: 'left'
  },
  labelContainer: {
    flexDirection: 'row',
    marginBottom: 5,
    alignItems: 'center'
  },
  required: {
    fontFamily: 'Roboto',
    fontSize: 14,
    textAlign: 'left',
    color: colors.errorTextColor,
    marginLeft: 5
  },
  error: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: colors.errorTextColor,
    flex: 1,
    textAlign: 'right'
  },
  calendarContainer: {
  	paddingHorizontal: 16
  },
  yearBlock: {},
  yearTrigger: {
    backgroundColor: '#f9f9f9',
    paddingHorizontal: 12,
    paddingVertical: 10
  },
  yearText: {
    fontSize: 14,
    color: colors.primaryTextColor,
    fontFamily: 'RobotoBold',
    textAlign: 'left',
  },
  yearContent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  monthTrigger: {
    width: '25%',
    alignItems: 'center',
    padding: 10
  },
  monthText: {
    fontSize: 14,
    color: colors.primaryTextColor,
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  tag: {
  	alignSelf: 'flex-end',
    backgroundColor: 'lightgreen',
    borderRadius: 18,
    paddingVertical: 6,
    paddingHorizontal: 10
  }
});