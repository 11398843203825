import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Constants from 'expo-constants';
import { View, ScrollView, Text, FlatList, Pressable, ActivityIndicator, Platform, StyleSheet, Image } from 'react-native';
import { useQuery } from '@apollo/client';
import { useAuthContext } from '../../../context/useAuthContext';
import theme from '../../../utils/theme';
import Button from '../../../components/Button';
import { GET_CARRIES } from '../../../queries';


const ApprovedStep2 = ({ handleNext, handlePrevious, dateFrom, carryExternal }) => {
  const { token, user } = useAuthContext();
  const [carry, setCarry] = useState(carryExternal || '');
  const [filters, setFilters] = useState({bookTest: {eq : 'BOOKING'}, countriesBooking: {contains: user.country}, bookableFrom: {lt: dateFrom}});
  const [stepError, setStepError] = useState();
  const [showModal, setShowModal] = useState(false);

  const { loading, error, data } = useQuery(GET_CARRIES, { 
    fetchPolicy: "no-cache",
    variables: { where: filters },
    context: {headers: { "Authorization": `Bearer ${token}` }}
  });


  const onCarrySelect = (item) => {
    setCarry(item.carry_id)
  }

  const canSubmit = carry;
  const onSubmitStep = () => {
    handleNext(carry);
  }

	return (
		<View style={styles.container}>
      {showModal && (
        <View style={styles.modal}>
          <Pressable onPress={() => setShowModal(false)}><Text>Close</Text></Pressable>
        </View>
      )}
			<View style={{flex: 1}}>
				<View style={{padding: 10}}>
          <Text>Click on carrier to select it</Text>
        </View>
        {loading && <ActivityIndicator />}
        {!loading && (
          <ScrollView style={{padding: 10}}>
            <FlatList
              ListEmptyComponent={() => <Text>{'No carries available for booking'}</Text>}
              data={data.carries.items}
              renderItem={({ item }) => (
                <Pressable 
                  style={{backgroundColor: item.carry_id === carry ? '#ceead9' : '#ffffff'}}
                  onPress={() => onCarrySelect(item)}
                >
                  <View style={{flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                    <View style={styles.image}>
                      <Image style={{flex: 1}} resizeMode="contain" source={{ 
                          uri: item.image 
                            ? `${Constants?.expoConfig?.extra?.imageBaseUrl}carry/${item.carry_id}/${item.image.filename}`
                            : 'https://placehold.co/100/8fc342/white?font=roboto&text=img' 
                        }} 
                      /> 
                    </View>
                    <View style={{flex: 1, paddingLeft: 24}}>
                      <Text style={styles.name}>{item.name}</Text>
                      <Text style={{}}>Description: {item.description}</Text>
                      <Text style={{}}>Type: {item.type}</Text>
                      <Text style={{}}>Model: {item.model}</Text>
                      <Text style={{}}>Brand: {item.brand.name}</Text>
                    </View>
                    <View style={{flex: 1, paddingLeft: 24}}>
                      <Text style={{}}>Size: {item.size}</Text>
                      <Text style={{}}>Experience: {item.experienceLevel}</Text>
                      <Text style={{}}>Color: {item.color}</Text>
                      <Text style={{}}>fabricDensity: {item.fabricDensity} g/m2</Text>
                      <Text style={{}}>Material: {item.material}</Text>
                      <Text style={{}}>Age: {item.monthFrom} - {item.monthTo} months</Text>
                      <Text style={{}}>Weight: {item.weightFrom} - {item.weightTo} kg</Text>
                    </View>
                  </View>
                </Pressable>
              )}
              keyExtractor={item => item.carry_id}
            />
          </ScrollView>
        )}
			</View>
			<View style={styles.footer}>
				<Text style={styles.error}>{stepError}</Text>
        <Button wrapStyleCustom={styles.button} text={'BACK'} onPress={handlePrevious} />
        <Button disabled={!canSubmit} wrapStyleCustom={styles.button} text={'NEXT'} onPress={onSubmitStep} />
			</View>
		</View>
	);
}

ApprovedStep2.propTypes = {

}

export default ApprovedStep2;

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  footer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: -2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    borderTopWidth: 1,
    borderTopColor: '#dddddd',
    flexWrap: 'wrap'
  },
  button: {
  },
  error: {
    fontSize: 14,
    fontFamily: 'Roboto',
    textAlign: 'left',
    userSelect: 'text',
    color: theme.colors.errorTextColor,
    width: '100%'
  },
  bookWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    // justifyContent: 'space-between',
    backgroundColor: 'lightgreen',
    padding: 5
  },
  fieldWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '30%'
  },
  modal: {
    position: 'absolute', 
    bottom: 0,
    left:0, 
    right:0,
    zIndex: 2,
    top: '25%',
    backgroundColor: '#ffffff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 10,
    // height: '100%',
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: -2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    borderTopWidth: 1,
    borderTopColor: '#dddddd',
  },
  image: {
    marginRight: 10, 
    height: 100, 
    width: 100
  },
  name: {
    fontSize: 14,
    color: theme.colors.primaryTextColor,
    textAlign: 'left',
    marginVertical: 5,
    fontFamily: 'RobotoBold'
  },
});