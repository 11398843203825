import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { View, Text, ScrollView } from 'react-native';
import { ErrorMessage } from '../../../components';
import CreateCarryForm from '../components/CreateCarryForm';


const CarriesCreateScreen = ({ navigation, where }) => {
  const [error, setError] = useState(null);

  const backToSearchScreen = () => navigation.navigate('search');

  return (
    <ScrollView style={{paddingHorizontal: 20}}>
      <ErrorMessage error={error} />
      <CreateCarryForm
        buttonText="Create Carry" 
        onBack={backToSearchScreen} 
        {...{ navigation, where }}
      />
    </ScrollView>
  )
}

CarriesCreateScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default CarriesCreateScreen;