import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_BRAND_OPTIONS } from '../../../queries';

const useGetBrandsOptions = () => {
  const { token } = useAuthContext();
  const [getBrands, { loading, error, data }] = useLazyQuery(GET_BRAND_OPTIONS, { 
    context: {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    }
  });
  const [state, setState] = useState({ isFetching: false, errorFetching: null, brands: [] });
  
  const handle = async () => {
    setState(prev => ({ ...prev, isFetching: true, errorFetching: null }))
    try {
      if (!state.brands.length) {
        const brands = await getBrands();
        const items = brands?.data?.brands?.items || [];
        setState({ isFetching: false, errorFetching: null, brands: items.map(x => ({value: x.brand_id, label: x.name})) });
      } else {
        setState(prev => ({ ...prev, isFetching: false, errorFetching: null }))
      }
    } catch (error) {
      setState({ isFetching: false, errorFetching: error?.response?.data || error, brands: [] })
    }
  }

  return [handle, { ...state }]
}

export default useGetBrandsOptions;