import React from 'react';
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, ActivityIndicator } from 'react-native';
import { ErrorMessage } from '../../../components';
import EditUserForm from '../components/EditUserForm';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_ALL_IDP_USERS, GET_FULL_USER, UPDATE_USER } from '../../../queries';
import { useQuery, useMutation } from '@apollo/client';


const UserEditScreen = ({ navigation, route }) => {
  const { user_id, disabled } = route?.params || {};

  const scrollViewRef = useRef(null);
  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const { loading, error: getUserError, data } = useQuery(GET_FULL_USER, { variables: { user_id }, context });
  const [updateUser] = useMutation(UPDATE_USER, { context });
  const [error, setError] = useState(getUserError || null);

  const backToSearchScreen = () => {
    navigation.navigate('Users', {screen: 'View', params: { user_id, disabled }})
  }

  const handleUpdateUser = async (data) => {
    try {
      const x = await updateUser({
        variables: { user_id, data },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: GET_FULL_USER, variables: { user_id }, context },
          { query: GET_ALL_IDP_USERS, context }
        ]
      });
      navigation.navigate('Users', {screen: 'View', params: { user_id, disabled }})
    } catch (error) {
      setError(error)
      scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
    }
  }

  if (loading) return <ActivityIndicator />;

  return (
    <ScrollView ref={scrollViewRef} style={{paddingHorizontal: 20}}>
      <ErrorMessage error={error} />
      <EditUserForm
        buttonText="Save" 
        onBack={backToSearchScreen} 
        onSubmit={handleUpdateUser} 
        userData={data}
      />
    </ScrollView>
  )
}

UserEditScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default UserEditScreen;