import React from 'react';
import { useState } from 'react';
import { View } from 'react-native';
import SearchScreen from '../../../features/carries/screens/AdminSearch';
import ViewScreen from '../../../features/carries/screens/View';
import CreateScreen from '../../../features/carries/screens/Create';
import EditScreen from '../../../features/carries/screens/Edit';

const AdminCarriesScreen = ({ navigation, route }) => {

  const [page, setPage] = useState('search'); // view | create | edit | search (default)
  const [params, setParams] = useState({}); // carry_id
  const [where, setWhere] = useState(null); // {country: {eq: "ME"}}

  const navigate = (page, params) => {
    setPage(page);
    setParams(params);
  }

  return (
    <View style={{flex: 1}}>
      {page === 'search' && <SearchScreen navigation={{ navigate }} where={where} setWhere={setWhere} />}
      {page === 'view' && <ViewScreen navigation={{ navigate }} {...params} route={{}} where={where} />}
      {page === 'edit' && <EditScreen navigation={{ navigate }} {...params} route={{}} where={where} />}
      {page === 'create' && <CreateScreen navigation={{ navigate }} where={where} />}
    </View>
  );
}

export default AdminCarriesScreen;
