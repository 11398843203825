import React from 'react';
import { useState } from 'react';
import { ScrollView } from 'react-native';
import SignUpForm from '../components/SignUpForm';
import { ScreenContainer, ErrorMessage } from '../../../components';
import { signUp } from '../../../utils/user';
import { useAuthContext } from '../../../context/useAuthContext';


const SignUpScreen = (props) => {

  const [error, setError] = useState(null);
  const { setUser, resetUser } = useAuthContext();

  const handleSignUp = async ({ password, passwordConfirmation, ...rest }) => {
    try {
      const { email } = rest;
      await setUser(rest);
      await signUp({email, password});
    } catch (er) {
      setError(er)
      await resetUser();
    }
  }

  return (
    <ScreenContainer>
      <ScrollView style={{paddingHorizontal: 20}}>
        <ErrorMessage error={error} />
        <SignUpForm
          onSubmit={handleSignUp}
          withPasswordConfirmation={true}
        />
      </ScrollView>
    </ScreenContainer>
  )
}

export default SignUpScreen;