import Constants from 'expo-constants';
import { useSelector } from 'react-redux';
import { View, Text, Image, StyleSheet } from 'react-native';
import { dateTimeRenderer } from '../../../utils/date';
import { getConfigOptionLabel } from '../../config/utils';
import theme from '../../../utils/theme';
const { colors } = theme;

const CarriesSearchListItem = ({ item }) => {
  const { name, brand, createdAt, type, size, bookTest, image, carry_id } = item;
  const config = useSelector((state) => state.config);

  return (
    <View style={styles.container}>
      <View style={styles.leftside}>
        <View style={styles.image}>
          <Image style={{flex: 1}} resizeMode="contain" source={{ 
              uri: image 
                ? `${Constants?.expoConfig?.extra?.imageBaseUrl}carry/${carry_id}/${image.filename}`
                : 'https://placehold.co/100/8fc342/white?font=roboto&text=img' 
            }} 
          /> 
        </View>
        <View>
          <Text style={styles.name}>{`${name}`}</Text>
          <Text style={styles.country}>{type}</Text>
          <Text style={styles.country}>{brand.name}</Text>
          <Text style={styles.country}>{getConfigOptionLabel(config, 'Sizes', size)}</Text>
          <Text style={styles.created}>{`${dateTimeRenderer(createdAt)}`}</Text>
        </View>
      </View>
      <View style={styles.rightside}>
        {bookTest && <Text style={styles.usedFor}>{bookTest}</Text>}
      </View>
    </View>
  )
}

export default CarriesSearchListItem;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    flex: 1,
    flexWrap: 'wrap'
  },
  leftside: {
    flexDirection: 'row', 
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginRight: 10
  },
  image: {
    marginRight: 10, 
    height: 100, 
    width: 100
  },
  rightside: {
  },
  name: {
    fontSize: 14,
    color: colors.primaryTextColor,
    textAlign: 'left',
    marginVertical: 5,
    fontFamily: 'RobotoBold'
  },
  country: {
    fontSize: 14,
    color: colors.primaryTextColor,
    fontFamily: 'Roboto',
    textAlign: 'left',
    marginVertical: 5
  },
  created: {
    color: colors.primaryTextColor,
    fontFamily: 'Roboto',
    textAlign: 'left',
    fontStyle: 'italic', 
    fontSize: 12, 
  },
  usedFor: {
    fontSize: 14,
    color: colors.primaryTextColor,
    fontFamily: 'Roboto',
    textAlign: 'left',
    userSelect: 'text',
    marginVertical: 5,
    textAlign: 'right', 
    color: colors.carryUsageColor,
  },
});