import React from 'react';
import { View, TextInput, StyleSheet, Platform } from 'react-native';

const MULTILINE = true;
const NUMBER_OF_LINES = 5;

const Textarea = ({
  disabled, // boolean 
  onBlur,
  onChange,
  value,
  placeholder,
  placeholderTextColor, 
  containerStyle={},
  inputStyle={},
  ...rest
}) => {
  return (
    <View style={[styles.container, containerStyle]}>
        <TextInput
            {...rest}
            style={[inputStyle]}
            readOnly={disabled ? true : false}
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholder={placeholder}
            placeholderTextColor={placeholderTextColor || "#5b5b5b"}
            multiline={MULTILINE}
            numberOfLines={Platform.OS === "android" ? NUMBER_OF_LINES : null}
        />
    </View>
  );
};
export default Textarea;
const styles = StyleSheet.create({
  container: {},
  input: {
    flex: 1,
    verticalAlign: 'top',
    height: 100
  }
});