import React from 'react';
import { Pressable } from 'react-native';
import { SimpleLineIcons } from '@expo/vector-icons';

const ListItem = ({ 
  selected, 
  onPress, 
  backgroundColor,
  selectedBgColor='#ceead9',
  unselectedBgColor='#ffffff',
  itemContent: ItemContent,
  icon // "arrow-right"
}) => {
  return (      
    <Pressable onPress={onPress} style={[{
      backgroundColor: selected ? selectedBgColor : (backgroundColor || unselectedBgColor),
      padding: 16,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }]}>
      {ItemContent && <ItemContent />}
      {icon && <SimpleLineIcons name={icon} size={24} color="black" />}
    </Pressable>
  );
}

export default ListItem;
