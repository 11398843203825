import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Constants from 'expo-constants';
import { useSelector } from 'react-redux';
import { View, ScrollView, Text, FlatList, Pressable, ActivityIndicator, Platform, StyleSheet, Image } from 'react-native';
import theme from '../../../utils/theme';
import Button from '../../../components/Button';


const ApprovedSummary = ({ handleSubmit, handlePrevious, formValues }) => {
  const i18n = Constants?.expoConfig?.extra?.i18n;
  const config = useSelector((state) => state.config);

	return (
		<View style={styles.container}>
			<View style={{flex: 1}}>
				<View style={{marginBottom: 15}}>
					<View style={styles.labelContainer}>
				        <Text style={styles.label}>{"Summary"}</Text>
				    </View>
				    <Text>{JSON.stringify(formValues)}</Text>
				</View>
			</View>
			<View style={styles.footer}>
        		<Button wrapStyleCustom={styles.button} text={'BACK'} onPress={handlePrevious} />
        		<Button wrapStyleCustom={styles.button} text={'SUBMIT'} onPress={handleSubmit} />
			</View>
		</View>
	);
}

ApprovedSummary.propTypes = {

}

export default ApprovedSummary;

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  footer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: -2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    borderTopWidth: 1,
    borderTopColor: '#dddddd',
    flexWrap: 'wrap'
  },
  button: {
  },
  error: {
    fontSize: 14,
    fontFamily: 'Roboto',
    textAlign: 'left',
    userSelect: 'text',
    color: theme.colors.errorTextColor,
    width: '100%'
  },
  bookWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    // justifyContent: 'space-between',
    backgroundColor: 'lightgreen',
    padding: 5
  },
  fieldWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '30%'
  },
  label: {
    fontFamily: 'RobotoBold',
    fontSize: 14,
    color: theme.colors.primaryTextColor,
    textAlign: 'left'
  },
  labelContainer: {
    flexDirection: 'row',
    marginBottom: 5,
    alignItems: 'center'
  },
  inputContainer: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: theme.colors.formInputBackgroundColor,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: theme.colors.formInputBorderColor,
  },
  input: {
    marginHorizontal: 0,
    marginVertical: 0,
    verticalAlign: 'top',
    height: 100,
    ...(Platform.OS === 'web' && {
      // outline: 'none',
      overflow: 'auto'
    }),
  },
});