import React from 'react';
import { Text, View, StyleSheet, Platform } from 'react-native';
import { useController } from 'react-hook-form';
import Textinput from './Textinput';
import theme from '../../../../utils/theme';
const { colors } = theme;

const FormInputText = ({
  name, 
  control, 
  label, 
  placeholder,
  rules={}, 
  i18n=false, 
  containerStyle={}, 
  labelContainerStyle={},
  labelStyle={},
  requiredStyle={},
  errorStyle={},
  inputStyle={},
  onBlur: onBlurExternal,
  onChange: onChangeExternal,
  value: valueExternal,
  secureTextEntry=false,
  disabled=false,
  icon
}) => {
  const { field, fieldState } = useController({name, control});
  const { onBlur, onChange, value='' } = field;
  const { isTouched, isDirty, invalid, error } = fieldState;

  return (
    <View style={[styles.container, containerStyle]}>    
      <View style={[styles.labelContainer, labelContainerStyle]}>
        <Text style={[styles.label, labelStyle]}>{label}</Text>
        {rules.required && (<Text style={[styles.required, requiredStyle]}>*</Text>)}
        <Text style={[styles.error, errorStyle]}>
          {invalid && error.message}
        </Text>
      </View>

      {i18n && Object.keys(value).map( ln => {
        const onChangeLocalozed = (v) => onChange({...value, [ln]: v});
        return (
          <Textinput 
            key={`${field.name}-${ln}`} 
            placeholder={ln} 
            containerStyle={[styles.inputContainer, {marginBottom: 5}]} 
            onBlur={onBlurExternal || onBlur}
            onChange={onChangeExternal || onChangeLocalozed}
            value={valueExternal || value[ln] || ''} 
            disabled={disabled}
            secureTextEntry={secureTextEntry}
            textInputStyle={[styles.input, inputStyle]}
          />
        );
      })}
      {!i18n && (
        <Textinput 
          value={valueExternal || value}
          onChange={onChangeExternal || onChange}
          onBlur={onBlurExternal || onBlur}
          containerStyle={styles.inputContainer}
          disabled={disabled}
          placeholder={placeholder}
          secureTextEntry={secureTextEntry}
          textInputStyle={[styles.input, inputStyle]}
          icon={icon}
        />
      )}
    </View>
  );
}

export default FormInputText;

const styles = StyleSheet.create({
  container: {
    marginBottom: 15
  },
  label: {
    fontFamily: 'RobotoBold',
    fontSize: 14,
    color: colors.primaryTextColor,
    textAlign: 'left'
  },
  labelContainer: {
    flexDirection: 'row',
    marginBottom: 5,
    alignItems: 'center'
  },
  required: {
    fontFamily: 'Roboto',
    fontSize: 14,
    textAlign: 'left',
    color: colors.errorTextColor,
    marginLeft: 5
  },
  error: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: colors.errorTextColor,
    flex: 1,
    textAlign: 'right'
  },
  inputContainer: {
    flexDirection: 'row',
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: colors.formInputBackgroundColor,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.formInputBorderColor,
  },
  input: {
    width: 'auto', 
    marginHorizontal: 0,
    marginVertical: 0,
    ...(Platform.OS === 'web' && {
      // outline: 'none',
      overflow: 'auto'
    })
  }
});