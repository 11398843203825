import { ApolloLink, Observable } from '@apollo/client/core';
import {
  createSignalIfSupported,
  fallbackHttpConfig,
  parseAndCheckHttpResponse,
  rewriteURIForGET,
  selectHttpOptionsAndBody,
  selectURI,
  serializeFetchParameter,
} from '@apollo/client/link/http';

const isPlainObject = (value) => {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const prototype = Object.getPrototypeOf(value);
  return (prototype === null || prototype === Object.prototype || Object.getPrototypeOf(prototype) === null) && !(Symbol.toStringTag in value) && !(Symbol.iterator in value);
}

const extractFiles = (value, isExtractable, path = "") => {
  // if (!arguments.length) throw new TypeError("Argument 1 `value` is required.");

  if (typeof isExtractable !== "function")
    throw new TypeError("Argument 2 `isExtractable` must be a function.");

  if (typeof path !== "string")
    throw new TypeError("Argument 3 `path` must be a string.");

  const clones = new Map();
  const files = new Map();

  const recurse = (value, path, recursed) => {
    if (isExtractable(value)) {
      const filePaths = files.get(value);
      filePaths ? filePaths.push(path) : files.set(value, [path]);
      return null;
    }

    const valueIsList = Array.isArray(value) || (typeof FileList !== "undefined" && value instanceof FileList);
    const valueIsPlainObject = isPlainObject(value);

    if (valueIsList || valueIsPlainObject) {
      let clone = clones.get(value);
      const uncloned = !clone;
      if (uncloned) {
        clone = valueIsList
          ? []
          : // Replicate if the plain object is an `Object` instance.
          value instanceof Object
          ? {}
          : Object.create(null);

        clones.set(value, clone);
      }

      if (!recursed.has(value)) {
        const pathPrefix = path ? `${path}.` : "";
        const recursedDeeper = new Set(recursed).add(value);

        if (valueIsList) {
          let index = 0;

          for (const item of value) {
            const itemClone = recurse(
              item,
              pathPrefix + index++,
              recursedDeeper
            );

            if (uncloned) clone.push(itemClone);
          }
        } else
          for (const key in value) {
            const propertyClone = recurse(
              value[key],
              pathPrefix + key,
              recursedDeeper
            );

            if (uncloned) clone[key] = propertyClone;
          }
      }

      return clone;
    }

    return value;
  }

  return { clone: recurse(value, path, new Set()), files };
}

const formDataAppendFile = (formData, fieldName, file) => {
  formData.append(fieldName, file, file.name);
};


const isExtractableFile = (value) => {
  return (
    (typeof File !== "undefined" && value instanceof File) ||
    (typeof Blob !== "undefined" && value instanceof Blob)
  );
}

const createUploadLink = ({
  uri: fetchUri = "/graphql",
  useGETForQueries,
  isExtractableFile: customIsExtractableFile = isExtractableFile,
  FormData: CustomFormData,
  formDataAppendFile: customFormDataAppendFile = formDataAppendFile,
  fetch: customFetch,
  fetchOptions,
  credentials,
  headers,
  includeExtensions,
} = {}) => {
  const linkConfig = {
    http: { includeExtensions },
    options: fetchOptions,
    credentials,
    headers,
  };

  return new ApolloLink((operation) => {
    const context = operation.getContext();
    const {
      clientAwareness: { name, version } = {},
      headers,
    } = context;

    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: {
        ...(name && { "apollographql-client-name": name }),
        ...(version && { "apollographql-client-version": version }),
        ...headers,
      },
    };

    const { options, body } = selectHttpOptionsAndBody(
      operation,
      fallbackHttpConfig,
      linkConfig,
      contextConfig
    );

    const { clone, files } = extractFiles(body, customIsExtractableFile, "");
    let uri = selectURI(operation, fetchUri);

    if (files.size) {
      delete options.headers["content-type"];
      const RuntimeFormData = CustomFormData || FormData;
      const form = new RuntimeFormData();

      form.append("operations", serializeFetchParameter(clone, "Payload"));

      const map = {};
      let i = 0;
      files.forEach((paths) => { map[++i] = paths; });
      form.append("map", JSON.stringify(map));

      i = 0;
      files.forEach((paths, file) => { customFormDataAppendFile(form, ++i, file); });

      options.body = form;
    } else {
      if (
        useGETForQueries &&
        !operation.query.definitions.some(
          (definition) =>
            definition.kind === "OperationDefinition" &&
            definition.operation === "mutation"
        )
      ) {
        options.method = "GET";
      }

      if (options.method === "GET") {
        const { newURI, parseError } = rewriteURIForGET(uri, body);
        if (parseError)
          return new Observable((observer) => {
            observer.error(parseError);
          });
        uri = newURI;
      } else {
        options.body = serializeFetchParameter(clone, "Payload");
      }
    }

    const { controller } = createSignalIfSupported();

    if (controller) {
      if (options.signal) {
        options.signal.aborted
          ? controller.abort()
          : options.signal.addEventListener( "abort", () => { controller.abort(); }, { once: true } );
      }
      options.signal = controller.signal;
    }

    const runtimeFetch = customFetch || fetch;

    return new Observable((observer) => {
      let cleaningUp;

      runtimeFetch(uri, options)
        .then((response) => {
          operation.setContext({ response });
          return response;
        })
        .then(parseAndCheckHttpResponse(operation))
        .then((result) => {
          observer.next(result);
          observer.complete();
        })
        .catch((error) => {
          if (!cleaningUp) {
            if (error.result && error.result.errors && error.result.data) { 
              observer.next(error.result); 
            }

            observer.error(error);
          }
        });

      return () => {
        cleaningUp = true;
        if (controller) controller.abort();
      };
    });
  });
};

export default createUploadLink;