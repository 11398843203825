import React from 'react';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_CARRIES, GET_CARRY, UPDATE_CARRY } from '../../../queries';

const useUpdate = (navigation, where) => {
  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const [updateCarry] = useMutation(UPDATE_CARRY, { context });
  const [state, setState] = useState({ updating: false, errorUpdating: null });

  const handle = async ({carry_id, ...data}) => {
    setState({ updating: true, errorUpdating: null });
    try {       
       const x = await updateCarry({
        variables: { carry_id, data: { 
          ...data, 
          size: !data.size ? null : data.size,
          experienceLevel: !data.experienceLevel ? null : data.experienceLevel,
          bookableFrom: new Date(data.bookableFrom).valueOf() || undefined,
        }},
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: GET_CARRY, variables: { carry_id }, context },
          { query: GET_CARRIES, variables: { where }, context }
        ]
      });
      
      setState({ updating: false, errorUpdating: null });
      navigation.navigate('view', { carry_id });
    } catch (error) {
      setState({ updating: false, errorUpdating: error });
    }
  }

  return [handle, { ...state }];
}

export default useUpdate;