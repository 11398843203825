export const TODAY = new Date().valueOf();
export const THIS_YEAR = new Date().getFullYear();
export const THIS_MONTH = new Date().getMonth();

export const timestamptoDate = (timestamp) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  const month = date.getMonth();
  const year = date.getFullYear();
  const day = date.getDate();
  return `${year}-${month < 9 ? '0' : ''}${month+1}-${day < 10 ? '0' : ''}${day}`;
}

export const dateTimeRenderer = (value) => {
  const date = new Date(parseInt(value));
  return date.toLocaleString('sr', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'});
};

export const unixToDateRenderer = (value, data) => {
  const date = new Date(parseInt(value));
  if (date.valueOf() < 86400) return '-'; // less then 1 day from 1st Jan 1970
  return date.toLocaleDateString('sr', { month: '2-digit', day: '2-digit', year: 'numeric', timeZone: 'UTC'});
};

export const unixToDateTimeRenderer = (value, data) => {
  const date = new Date(parseInt(value));
  if (date.valueOf() < 86400) return '-'; // less then 1 day from 1st Jan 1970
  return date.toLocaleDateString('sr', { month: '2-digit', day: '2-digit', year: 'numeric', hour:'2-digit', minute: '2-digit', second:'2-digit', timeZone: 'UTC'});
};

export const MONTHS = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

export const monthsYearsBetweenDates = (startDate, endDate) => {
  const codes = [];
  while (startDate <= endDate) {
    var monthIndex = startDate.getMonth();
    var yearName = startDate.getFullYear();
    var monthName = MONTHS[monthIndex];
    // Push the code to the codes array
    codes.push(`${yearName}-${monthName}`);
    // Increment the month by one
    startDate.setMonth(startDate.getMonth() + 1);
  }
  return codes;
}

export const monthYearToTimestamp = (year, month, next) => {
  const date = new Date();
  const monthIndex = MONTHS.indexOf(month);
  if (next) {
    date.setMonth(monthIndex === 11 ? 0 : (monthIndex + 1));
    date.setFullYear(monthIndex === 11 ? (year + 1) : year);
  } else {
    date.setMonth(monthIndex);
    date.setFullYear(year);
  }
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.valueOf();
}

export const monthSelectionYear = (x) => x.split('-')[0];
export const monthSelectionMonth = (x) => x.split('-')[1];
export const monthSelectionTimestamp = (x, next) => monthYearToTimestamp(monthSelectionYear(x), monthSelectionMonth(x), next);
