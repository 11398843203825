import React from 'react';
import { ActivityIndicator } from 'react-native';
import { useState } from 'react';
import PropTypes from 'prop-types'; 
import SignInForm from '../components/SignInForm';
import { ErrorMessage, ScreenContainer } from '../../../components';
import { signIn } from '../../../utils/user';

const SignInScreen = ({ navigation }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSignIn = async (values) => {
    try {
      setLoading(true);
      const user = await signIn(values);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  return (
    <ScreenContainer>
      <ErrorMessage error={error} />
      {loading && <ActivityIndicator />}
      {!loading && <SignInForm onSubmit={handleSignIn} buttonText="Sign in" />}
    </ScreenContainer>
  )
}

SignInScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default SignInScreen;