import Constants from 'expo-constants';
import { SET_CURRENT_LOCALE, SET_SETTINGS } from '../actions';

export default (state = {
  allowedLocales: ['sr', 'en'],
  locale: Constants?.expoConfig?.extra?.defaultLanguage || 'fi',
  settings: []
}, action) => {
  switch (action.type) {
    case SET_CURRENT_LOCALE:
      return {
        ...state,
        locale: state.allowedLocales.includes(action.locale) ? action.locale : state.locale,
      };
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    default:
      return state;
  }
};
