import React from 'react';
import { useTheme } from '@react-navigation/native';
import { View, Text } from 'react-native';
import { dateTimeRenderer } from '../../../utils/date';
import { BRAND_COUNTRIES_MAP } from '../../../utils/config';

const BrandsSearchListItem = ({ item }) => {
  const { name, country, createdAt } = item;
  const {
    brandSearchListItemContainer, 
    brandSearchListItemName,
    brandSearchListItemCountry,
    brandSearchListItemCreated,
  } = useTheme();

  return (
    <View style={brandSearchListItemContainer}>
      <View>
        <Text style={brandSearchListItemName}>{`${name}`}</Text>
        <Text style={brandSearchListItemCountry}>{`${BRAND_COUNTRIES_MAP[country]}`}</Text>
        <Text style={brandSearchListItemCreated}>{`${dateTimeRenderer(createdAt)}`}</Text>
      </View>
    </View>
  )
}

export default BrandsSearchListItem;