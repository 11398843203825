import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';
import { SimpleLineIcons } from '@expo/vector-icons';
import ApprovedWelcomeScreen from '../features/approved-welcome/screens/ApprovedWelcomeScreen';
import ApprovedBookingScreen from '../features/booking/screens/ApprovedBookingScreen';
import LanguageMenuItems from '../features/language-switcher/components/LanguageMenuItems';
import theme from '../utils/theme';
import { logOut } from '../utils/user';
import { useSelector } from 'react-redux';
import Constants from 'expo-constants';

const MyTheme = { ...DefaultTheme, ...theme };
const Drawer = createDrawerNavigator();

const ApprovedNavigator = (props) => {
  const config = useSelector((state) => state.config);
  const i18n = Constants?.expoConfig?.extra?.i18n;

  return (
    <NavigationContainer theme={MyTheme} linking={{prefixes: ['/'], config: {
      screens: {
        ApprovedWelcome: {path: ''},
        Booking: {path: 'booking'}
      }
    }}}>
      <Drawer.Navigator 
        initialRouteName='ApprovedWelcome'
        screenOptions={{
          drawerStyle: {backgroundColor: theme.colors.headerBackground},
          drawerActiveTintColor: theme.colors.headerActive,
          drawerActiveBackgroundColor: theme.colors.headerBackground,
          drawerInactiveTintColor: theme.colors.headerInactive,
          sceneContainerStyle: {backgroundColor: theme.colors.primaryBackgroundColor},
          headerStyle: {backgroundColor: theme.colors.headerBackground}
        }}
        drawerContent={(props) => {
          const { navigation, state } = props;
          return (
            <DrawerContentScrollView {...props}>
                <DrawerItemList {...props} />
                <DrawerItem 
                  label={i18n[config.locale].logout_label || "Logout"} 
                  onPress={() => logOut()}
                  icon={({size, color}) => <SimpleLineIcons name="logout" size={size} color={color} />} 
                />
                <LanguageMenuItems />
            </DrawerContentScrollView>
          );
        }}
      >
        <Drawer.Screen 
          name="ApprovedWelcome" 
          component={ApprovedWelcomeScreen} 
          options={{ 
            title: i18n[config.locale].screen_admin_welcome_title || 'Welcome',
            drawerLabel: i18n[config.locale].screen_admin_welcome_label || '',
            drawerIcon: ({size, color}) => <SimpleLineIcons name="home" size={size} color={color} /> 
          }} 
        />
        <Drawer.Screen 
          name="Booking" 
          component={ApprovedBookingScreen} 
          options={{ 
            title: i18n[config.locale].screen_booking_title,
            drawerLabel: i18n[config.locale].screen_booking_label,
            drawerIcon: ({size, color}) => <SimpleLineIcons name="game-controller" size={size} color={color} />  
          }} 
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
}

export default ApprovedNavigator;
