import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_CARRIES } from '../../../queries';

const useGetCarries = (fetchPolicy="cache-first") => {
  const { token } = useAuthContext();
  const [getCarries, { loading, error, data }] = useLazyQuery(GET_CARRIES, { 
    fetchPolicy,
    context: {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    }
  });
  const [state, setState] = useState({ isFetching: false, errorFetching: null, carries: [] });
  
  const handle = async (asOptionsArray = false, where = null) => {
    setState(prev => ({ ...prev, isFetching: true, errorFetching: null }))
    try {
      if (!state.carries.length) {
        const carries = await getCarries({ variables: { where } });
        const items = carries?.data?.carries?.items || [];
        setState({ isFetching: false, errorFetching: null, carries: asOptionsArray ? items.map(x => ({value: x.carry_id, label: x.name})) : items });
      } else {
        setState(prev => ({ ...prev, isFetching: false, errorFetching: null }))
      }
    } catch (error) {
      setState({ isFetching: false, errorFetching: error?.response?.data || error, carries: [] })
    }
  }

  return [handle, { ...state }]
}

export default useGetCarries;