import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import ReauthenticateForm from '../components/ReauthenticateForm';
import { useTheme } from '@react-navigation/native';
import { View, Text } from 'react-native';
import { reload, getUser, reauthenticate } from '../../../utils/user';
import { ScreenContainer, ErrorMessage, Button } from '../../../components';
import { useAuthContext } from '../../../context/useAuthContext';

const VerifiedWelcomeScreen = ({ navigation }) => {
  const { user, setUserApproved } = useAuthContext();
  const {firstname, lastname, email} = user || {};

  const [error, setError] = useState(null);
  const {heading1, paragraph} = useTheme();

  const handleReload = async ({ password }) => {
    try {
      const x = await reauthenticate({email, password});
      const xTokenResult = x.user.getIdTokenResult();
      const idpUser = getUser();
      const tokenResult = await idpUser.getIdTokenResult();
      console.log(xTokenResult, tokenResult)
      if (tokenResult.claims.role === 'APPROVED') {
        setUserApproved();
      }
    } catch (error) {
      setError(error);
    }
  }

  return (
    <ScreenContainer>
      <ErrorMessage error={error} />
      <View>
        <Text style={heading1}>Thank you {firstname} {lastname}</Text>
        <Text style={paragraph}>
          Your email "{email}" is now verified.
          Our admins will now approve your account.
          Please wait for notification email and log in again.
          You can also reauthenticate with form bellow to check if your account has been approved.
        </Text>
        <ReauthenticateForm
          onSubmit={handleReload}
          buttonText="Reauthenticate"
        />
      </View>
    </ScreenContainer>
  )
}

VerifiedWelcomeScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default VerifiedWelcomeScreen;