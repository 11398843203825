import React from 'react';
import List from '../../../components/List';
import UserSearchListItem from './UserSearchListItem';
import UserSearchListEmpty from './UserSearchListEmpty';
import UserSearchListSeparator from './UserSearchListSeparator';

const UserSearchList = ({ 
  data, 
  onSelect, 
  onSearch, 
  onRefresh,
  refreshing,
  searchText, 
  searchPlaceholder, 
  emptyText 
}) => (
  <List 
    data={data}
    keyName="user_id"
    placeholder={searchPlaceholder}
    listItemContent ={item => <UserSearchListItem item={item} />}
    onSelect={onSelect}
    onSearch={onSearch}
    onRefresh={onRefresh}
    refreshing={refreshing}
    searchText={searchText}
    emptyDataComponent={() => <UserSearchListEmpty text={emptyText} />}
    separatorComponent={() => <UserSearchListSeparator />}
  />
);

export default UserSearchList;