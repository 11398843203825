import { Platform, Alert } from 'react-native';
import Constants from 'expo-constants';

export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE';
export const setCurrentLocale = (locale) => (dispatch) =>
  dispatch({ type: SET_CURRENT_LOCALE, locale });
export const SET_SETTINGS = 'SET_SETTINGS';
export const setSettings = (settings) => (dispatch) =>
  dispatch({ type: SET_SETTINGS, settings });
/*
export const SET_USER = 'SET_USER';
export const setUser = (data) => async (dispatch) =>
  await dispatch({ type: SET_USER, data });

export const RESET_USER = 'RESET_USER';
export const resetUser = () => async (dispatch) =>
  await dispatch({ type: RESET_USER });

export const RESTORE_TOKEN = 'RESTORE_TOKEN';
export const restoreToken = () => async (dispatch, getState) => {
  const { auth } = getState();
  try {
    dispatch({ type: RESTORE_TOKEN, token: "TOKEN" });
  } catch (err) {
     console.error(err)
     dispatch({ type: SIGN_OUT });
  }
};

export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const signInError = (error) => async (dispatch) =>
  await dispatch({ type: SIGN_IN_ERROR, error: error });

export const SIGN_IN = 'SIGN_IN';
export const signInSuccess = ({idToken, emailVerified}) => async (dispatch) =>
  await dispatch({ type: SIGN_IN, token: idToken, emailVerified });

export const SIGN_OUT = 'SIGN_OUT';
export const signOut = () => async (dispatch) =>
  await dispatch({ type: SIGN_OUT });

export const UPDATE_EMAIL_VERIFIED = 'UPDATE_EMAIL_VERIFIED';
export const updateEmailVerified = (emailVerified) => async (dispatch) =>
  await dispatch({ type: UPDATE_EMAIL_VERIFIED, emailVerified });
*/