import { useState } from 'react';
import { View, Text, StyleSheet, FlatList, Platform } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { SimpleLineIcons } from '@expo/vector-icons';
import CarriesSearchListItem from './CarriesSearchListItem';
import CarriesSearchListEmpty from './CarriesSearchListEmpty';
import SearchField from '../../../components/SearchField';
import ListItem from '../../../components/ListItem';
import Button from '../../../components/Button';
import { COUNTRIES_MAP } from '../../../utils/config';

const CarriesSearchList = ({ 
  data={}, 
  onSelect, 
  onWhere,
  where,
  onRefresh,
  refreshing,
  emptyText,
  onCreate,
  onLoadMore,
  footerActions
}) => {
  const hasNoMoreToLoad = data.carries?.items?.length === data.carries?.totalCount;
  const [searchText, setSearchText] = useState('');

  const filterData = ( data ) => {
    if (!data || !data.carries || !data.carries.items) return [];

    return !searchText
      ? [...data.carries.items]
      : [...data.carries.items].filter(
          x => Object.keys(x).some(
            p => !['carry_id', 'lastModifiedBy', 'bookableFrom', 'createdAt'].includes(p) && x[p] && typeof x[p] === 'string' && x[p].toLowerCase().includes(searchText.toLowerCase())
          )
        )
  }

  const handleWhere = (value) => {
    const newWhere = { ...where, ['countriesBooking']: value ? {['contains']: value} : undefined };
    const hasFilters = Object.keys(newWhere).find(k => newWhere[k]);
    onWhere(hasFilters ? newWhere : null);
  }

  const filtered = filterData(data);
 
  return (
    <View style={styles.container}>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <View style={{flex: 1}}>
          <Picker
            style={styles.formPicker}
            itemStyle={styles.formSelectItem}
            selectedValue={!where ? '' : where['countriesBooking'] && where['countriesBooking']['contains']}
            onValueChange={handleWhere}
          >
            <Picker.Item label={"Used in country"} value="" />
            {Object.keys(COUNTRIES_MAP).map((countryId) => {
              return (
                <Picker.Item label={COUNTRIES_MAP[countryId]} value={countryId} key={countryId} />
              );
            })}
          </Picker>
        </View>
        <Button 
          wrapStyleCustom={{paddingVertical: 0}}
          buttonStyleCustom={{paddingVertical: 0, margin: 0}}
          type="control" 
          icon={() => <SimpleLineIcons name="plus" size={24} color="black" />} 
          onPress={onCreate} 
        />
      </View>
      <SearchField
        onSearch={setSearchText} 
        searchText={searchText} 
        wrapStyle={{backgroundColor: '#ffffff', borderBottomWidth: 1, borderColor: '#EFEFEF'}}
        inputWrapStyle={styles.searchInputWrap}
        inputStyle={[styles.searchInput, Platform.OS === 'web' ? {outline: 'none'} : {}]}
        textColor="#5b5b5b"
      />
      <FlatList
        ListEmptyComponent={() => <CarriesSearchListEmpty text={emptyText} />}
        data={filtered}
        onRefresh={onRefresh}
        refreshing={refreshing}
        renderItem={({ item }) => (
          <ListItem 
            onPress={() => onSelect(item)}
            itemContent={() => <CarriesSearchListItem item={item} />}
          />)}
        keyExtractor={item => item.carry_id}
      />
      {footerActions.length > 0 && (
        <View style={styles.footer}>
          {footerActions.map(a => (
            hasNoMoreToLoad ? null : <Button key={Math.random()} text={a.label} onPress={a.action} />
          ))}
        </View>
      )}
    </View>
  );
}

export default CarriesSearchList;

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  searchInputWrap: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    padding: 10,
    marginHorizontal: 16.5, 
    marginVertical: 10,
    backgroundColor: '#f9f9f9',
    borderWidth: 1, 
    borderColor: '#f4f4f4', 
    borderRadius: 4,
  },
  searchInput: {
    flex: 1
  },
  formSelectItem: {
    fontFamily: 'Roboto',
    height: Platform.OS === 'ios' ? 88 : 36,
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 16
  },
  formPicker: {
    alignSelf: 'stretch',
    padding: 10,
    marginVertical: Platform.OS === 'web' ? 5 : 0,
    marginHorizontal: 'auto',
    borderRadius: 5,
    width: '100%',
    height: Platform.OS === 'ios' ? 88 : 'auto',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 16,
    backgroundColor: Platform.OS === 'web' ? '#f9f9f9' : 'transparent',
    borderColor: '#949494',
    borderWidth: Platform.OS === 'web' ? 1 : 0
  }
});