import * as Yup from 'yup';

export const buildSchema = (editMode=false) => Yup.object({
	carry: Yup.string().required(),
	user: Yup.string().required(),
	dateFrom: Yup.number().required(),
	dateTo: Yup.number().required(),
	comment: Yup.string(),
	adminComment: Yup.string(),
	carries: Yup.array(),
	...(editMode && {booking_id: Yup.string().required()}),
})