import { Platform } from 'react-native';

const basicColors = {
  transparent: 'transparent',
  lotion: '#FDFDFD',
  treasuredWilderness: '#006431',
  black: '#222222',
  verifiedBlack: '#242424',
  pineTree: '#2C2A29',
  white: '#FFFFFF',
  cascadingWhite: '#F6F6F6',
  crystalBell: '#EFEFEF',
  aria: '#E3E3E3',
  grey: '#9D9D9D',
  cerebralGrey: '#CCCCCC',
  greyDark: '#5B5B5B',
  tangledWeb: '#B2B2B2',
  periwinkleDusk: '#8F9BB3',
  greyBorder: '#E8E8E8',
  supportRed: '#FA3E67',
  qingDynastyFire: '#DC2866',
  sludge: '#C36A00',
  redLetterDay: '#C9005B',
  foxTails: '#DC8C00',
  maryBlue: '#006C76',
  deepPeacockBlue: '#008580',
  londonRain: '#0057C2',
  pharaohsGem: '#007566',
  deepLagoon: '#005167',
  observatory: '#009270',
  turquoiseGreen: '#00E58F',
  slimeGirl: '#00B484',
  starfleetBlue: '#0095FF',
  flashOfOrange: '#FFAA00',
  toastedMarshmallowFluff: '#FFF9EB',
  laserTrap: '#FF3D71',
  burningFireflies: '#FF116E',
  lavenderBlush: '#FFF0F4',
  polarExpedition: '#C9E7E5',
  clearSkies: '#EBF7FF',
  gentianFlower: '#3366FF',
  wizardBlue: '#0070D7',
  northAtlanticBreeze: '#337AB7',
};

// Role colors - always use these, never definitions above
const roleColors = {
  primaryTextColor: basicColors.pineTree,
  primaryBackgroundColor: basicColors.white,
  headerBackground: basicColors.lotion,
  headerActive: basicColors.maryBlue,
  headerInactive: basicColors.pineTree,
  errorTextColor: basicColors.supportRed,
  errorBorderColor: basicColors.supportRed,
  successTextColor: basicColors.slimeGirl,
  successBorderColor: basicColors.slimeGirl,
  formInputBackgroundColor: basicColors.white,
  formInputBorderColor: basicColors.cerebralGrey,
  disabledColor: basicColors.greyDark,
  disabledBgColor: basicColors.periwinkleDusk,
  // CtaBgColor
  button_basic_filled_default_CtaBgColor: basicColors.cascadingWhite,
  button_primary_filled_default_CtaBgColor: basicColors.maryBlue,
  button_success_filled_default_CtaBgColor: basicColors.treasuredWilderness,
  button_info_filled_default_CtaBgColor: basicColors.starfleetBlue,
  button_warning_filled_default_CtaBgColor: basicColors.flashOfOrange,
  button_danger_filled_default_CtaBgColor: basicColors.laserTrap,
  button_control_filled_default_CtaBgColor: basicColors.lotion,
  button_basic_outline_default_CtaBgColor: basicColors.cascadingWhite,
  button_primary_outline_default_CtaBgColor: basicColors.polarExpedition,
  button_success_outline_default_CtaBgColor: basicColors.polarExpedition,
  button_info_outline_default_CtaBgColor: basicColors.clearSkies,
  button_warning_outline_default_CtaBgColor: basicColors.toastedMarshmallowFluff,
  button_danger_outline_default_CtaBgColor: basicColors.lavenderBlush,
  button_control_outline_default_CtaBgColor: basicColors.transparent,
  button_basic_filled_focus_CtaBgColor: basicColors.crystalBell,
  button_primary_filled_focus_CtaBgColor: basicColors.maryBlue,
  button_success_filled_focus_CtaBgColor: basicColors.slimeGirl,
  button_info_filled_focus_CtaBgColor: basicColors.wizardBlue,
  button_warning_filled_focus_CtaBgColor: basicColors.foxTails,
  button_danger_filled_focus_CtaBgColor: basicColors.qingDynastyFire,
  button_control_filled_focus_CtaBgColor: basicColors.cascadingWhite,
  // CtaTextColor
  button_basic_filled_CtaTextColor: basicColors.verifiedBlack,
  button_primary_filled_CtaTextColor: basicColors.white,
  button_success_filled_CtaTextColor: basicColors.white,
  button_info_filled_CtaTextColor: basicColors.white,
  button_warning_filled_CtaTextColor: basicColors.white,
  button_danger_filled_CtaTextColor: basicColors.white,
  button_control_filled_CtaTextColor: basicColors.verifiedBlack,
  button_basic_outline_CtaTextColor: basicColors.greyDark,
  button_primary_outline_CtaTextColor: basicColors.deepPeacockBlue,
  button_success_outline_CtaTextColor: basicColors.pharaohsGem,
  button_info_outline_CtaTextColor: basicColors.starfleetBlue,
  button_warning_outline_CtaTextColor: basicColors.flashOfOrange,
  button_danger_outline_CtaTextColor: basicColors.laserTrap,
  button_control_outline_CtaTextColor: basicColors.white,
  button_basic_ghost_CtaTextColor: basicColors.greyDark,
  button_primary_ghost_CtaTextColor: basicColors.gentianFlower,
  button_success_ghost_CtaTextColor: basicColors.pharaohsGem,
  button_info_ghost_CtaTextColor: basicColors.starfleetBlue,
  button_warning_ghost_CtaTextColor: basicColors.flashOfOrange,
  button_danger_ghost_CtaTextColor: basicColors.laserTrap,
  button_control_ghost_CtaTextColor: basicColors.white,
  // CtaBorderColor
  button_basic_default_CtaBorderColor: basicColors.tangledWeb,
  button_primary_default_CtaBorderColor: basicColors.deepPeacockBlue,
  button_success_default_CtaBorderColor: basicColors.turquoiseGreen,
  button_info_default_CtaBorderColor: basicColors.starfleetBlue,
  button_warning_default_CtaBorderColor: basicColors.flashOfOrange,
  button_danger_default_CtaBorderColor: basicColors.burningFireflies,
  button_control_default_CtaBorderColor: basicColors.white,
  button_basic_focus_CtaBorderColor: basicColors.aria,
  button_primary_focus_CtaBorderColor: basicColors.deepLagoon,
  button_success_focus_CtaBorderColor: basicColors.observatory,
  button_info_focus_CtaBorderColor: basicColors.londonRain,
  button_warning_focus_CtaBorderColor: basicColors.sludge,
  button_danger_focus_CtaBorderColor: basicColors.redLetterDay,
  button_control_focus_CtaBorderColor: basicColors.aria,

  separatorColor: basicColors.crystalBell,
  notVerifiedColor: basicColors.burningFireflies,
  notApprovedColor: basicColors.flashOfOrange,
  userRoleColor: basicColors.redLetterDay,
  carryUsageColor: basicColors.redLetterDay,

  primary: basicColors.maryBlue,
  card: basicColors.white,
  text: basicColors.pineTree,
  border: basicColors.greyBorder,
  notification: basicColors.blueDark,
};

const defaultFont = 'Roboto';
const defaultText = {
  fontSize: 14,
  color: roleColors.primaryTextColor,
  fontFamily: defaultFont,
  textAlign: 'left',
  userSelect: 'text',
};

const other = {
  screenContainerStyle: {
    backgroundColor: roleColors.primaryBackgroundColor,
    paddingVertical: 20
  }, 
  safeAreaStyle: {
    paddingHorizontal: 20
  },
  sectionContainer: {
    marginBottom: 10
  }, 
  sectionHeader: {
    backgroundColor: basicColors.northAtlanticBreeze,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3
  }, 
  sectionHeaderText: {
    ...defaultText,
    color: basicColors.white,
    fontSize: 16
  }, 
  sectionBody: {
    padding: 15,
    backgroundColor: basicColors.white,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    borderColor: basicColors.northAtlanticBreeze
  }, 
  sectionBodyText: {
    ...defaultText,
    marginBottom: 10
  },
  rulesBodyText: {
    ...defaultText,
    marginBottom: 10
  },
  errorContainer: {
    borderColor: roleColors.errorBorderColor,
    borderRadius: 3,
    borderWidth: 1,
    padding: 10
  }, 
  errorText: {
    ...defaultText,
    color: roleColors.errorTextColor
  },
  successContainer: {
    borderColor: roleColors.successBorderColor,
    borderRadius: 3,
    borderWidth: 1,
    padding: 10
  }, 
  successText: {
    ...defaultText,
    color: roleColors.successTextColor
  },
  formContainer: {

  },
  formFieldContainer: {
    marginBottom: 15
  }, 
  formFieldLabelContainer: {
    flexDirection: 'row',
    marginBottom: 5,
    alignItems: 'center'
  },
  formFieldLabel: {
    ...defaultText,
    fontFamily: 'RobotoBold',
    userSelect: 'text'
  },
  formDefaultText: {
    ...defaultText
  },
  formFieldLabelRequired: {
    ...defaultText,
    color: roleColors.errorTextColor,
    marginLeft: 5
  },
  formFieldError: {
    ...defaultText,
    color: roleColors.errorTextColor,
    flex: 1,
    textAlign: 'right'
  },
  formFieldInput: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: roleColors.formInputBackgroundColor,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: roleColors.formInputBorderColor,
    ...(Platform.OS === 'web' && {
      outline: 'none'
    })
  },
  formFieldTextarea: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: roleColors.formInputBackgroundColor,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: roleColors.formInputBorderColor,
    ...(Platform.OS === 'web' && {
      outline: 'none'
    }),
    height: 100
  },
  formButtonsContainer: {
    flexDirection: 'row', 
    justifyContent: 'space-between',
    marginBottom: 50  
  },
  heading1: {
    ...defaultText,
    fontSize: 24,
    lineHeight: 32,
    fontWeight: '700',
    marginVertical: 10,
  },
  paragraph: {
    ...defaultText,
    marginVertical: 5
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  tableHeaderRowWrap: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    backgroundColor: '#ECF0F3'
  },
  tableHeaderCellWrap: {
    flex: 1,
    padding: 6
  },
  tableBodyRowWrap: {
    flexDirection: 'row',
  },
  tableBodyCellWrap: {
    flex: 1,
    padding: 6
  },
  userSearchListItemContainer: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    flex: 1
  },
  userSearchListItemName: {
    ...defaultText,
    marginVertical: 5,
    fontFamily: 'RobotoBold'
  },
  userSearchListItemEmail: {
    ...defaultText,
    marginVertical: 5
  },
  userSearchListItemCreated: {
    ...defaultText,
    fontStyle: 'italic', 
    fontSize: 12, 
    //marginVertical: 0
  },
  userSearchListEmptyContainer: {

  },
  userSearchListEmptyText: {
    ...defaultText,
    marginVertical: 5,
    textAlign: 'center'
  },
  UserSearchListSeparatorContainer: {
    backgroundColor: roleColors.separatorColor, 
    height: 1
  },
  userSearchListUnverified: {
    ...defaultText,
    marginVertical: 5,
    textAlign: 'right', 
    color: roleColors.notVerifiedColor, 
  },
  userSearchListUnapproved: {
    ...defaultText,
    marginVertical: 5,
    textAlign: 'right', 
    color: roleColors.notApprovedColor, 
  },
  userSearchListRole: {
    ...defaultText,
    marginVertical: 5,
    textAlign: 'right', 
    color: roleColors.userRoleColor,
  },

  brandSearchListItemContainer: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    flex: 1
  },
  brandSearchListItemName: {
    ...defaultText,
    marginVertical: 5,
    fontFamily: 'RobotoBold'
  },
  brandSearchListItemCountry: {
    ...defaultText,
    marginVertical: 5
  },
  brandSearchListItemCreated: {
    ...defaultText,
    fontStyle: 'italic', 
    fontSize: 12, 
  },
  brandSearchListEmptyContainer: {

  },
  brandSearchListEmptyText: {
    ...defaultText,
    marginVertical: 5,
    textAlign: 'center'
  },
  formSelectItem: {
    fontFamily: defaultFont,
    height: Platform.OS === 'ios' ? 88 : 36,
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 16
  },
  formPicker: {
    alignSelf: 'stretch',
    padding: 10,
    marginVertical: Platform.OS === 'web' ? 5 : 0,
    marginHorizontal: 'auto',
    borderRadius: 5,
    width: '100%',
    height: Platform.OS === 'ios' ? 88 : 'auto',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 16,
    backgroundColor: Platform.OS === 'web' ? '#f9f9f9' : 'transparent',
    borderColor: '#949494',
    borderWidth: Platform.OS === 'web' ? 1 : 0
  }
};

export default { dark: false, colors: { ...roleColors }, ...other };
