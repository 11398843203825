import { Text, View, StyleSheet, ActivityIndicator, Modal, ScrollView } from 'react-native';
import Button from './Button';
import theme from '../utils/theme';

const DeleteModal = ({ visible, hideModal, handleDelete, entryName, deleting }) => {  

  return (
    <Modal
      animationType={'none'}
      transparent={true}
      visible={visible}
    >
      <View style={styles.modalContent}>
        <View style={styles.modalInnerContent}>
          <ScrollView>
            <View style={styles.container}>
              <Text style={[styles.text, styles.question]}>{`Are you sure you want to delete ${entryName} ?`}</Text>
              <View style={styles.buttons}>
                <Button disabled={deleting} type="primary" text="Delete" onPress={handleDelete} wrapStyleCustom={styles.button} />
                <Button type="secondary" layout='outline' text="Cancel" onPress={hideModal} wrapStyleCustom={styles.button} />
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
}

export default DeleteModal;

const styles = StyleSheet.create({
	modalContent: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
    
  },
  modalInnerContent: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '97%',
    marginHorizontal: 10,
    marginVertical: 10,
    alignItems: 'center',
    borderRadius: 5
  },
  container: {
    paddingVertical: 48,
    paddingHorizontal: 10,
    width: 350
  },
  question: {
    width: '100%',
    textAlign: 'left'
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 24
  },
  text: {
    fontSize: 15,
    lineHeight: 20,
    fontFamily: 'Roboto',
  },
  textLabel: {
    fontSize: 15,
    lineHeight: 20,
    fontFamily: 'Roboto',
    fontWeight: '700'
  },
  button: {
    margin: 0,
  },
});