import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Constants from 'expo-constants';
import { View, Text, Pressable, ActivityIndicator, Platform, StyleSheet } from 'react-native';
import { useQuery } from '@apollo/client';
import { useAuthContext } from '../../../context/useAuthContext';
import theme from '../../../utils/theme';
import { TODAY, MONTHS, monthsYearsBetweenDates, monthYearToTimestamp, monthSelectionTimestamp } from '../../../utils/date';
import Button from '../../../components/Button';
import MonthRangeCalendar from '../../../components/MonthRangeCalendar';
import { GET_BOOKINGS } from '../../../queries';

const ApprovedStep1 = ({ handleNext, dateFromExternal, dateToExternal }) => {
  const [booked, setBooked] = useState([]);
  const [dateFrom, setDateFrom] = useState(dateFromExternal);
  const [dateTo, setDateTo] = useState(dateToExternal);
  const [stepError, setStepError] = useState();
  const [showModal, setShowModal] = useState(false);
  const { token, user } = useAuthContext();

  const context = {headers: {Authorization: `Bearer ${token}`}};
  const { loading, error, data } = useQuery(GET_BOOKINGS, { 
    variables: { where: {user: {eq: user.user_id}} },
    context
  });

  const onBookedView = async (y, m) => {
    await setBooked(data.bookings.items.filter(x => monthYearToTimestamp(y,m) >= x.dateFrom && monthYearToTimestamp(y,m) <= x.dateTo) || []);
    setShowModal(true);
  }

  const isMonthBooked = (y, m) => {
    const booked = data.bookings.items.reduce((a,c) => a.concat(monthsYearsBetweenDates(new Date(c.dateFrom), new Date(c.dateTo-86400000))), []);
    return booked.includes(`${y}-${m}`);
  }

  const isMonthSelected = (y, m) => {
    if (dateFrom && !dateTo) {
      const fromDate = new Date(dateFrom);
      return fromDate.getFullYear() === y && MONTHS[fromDate.getMonth()] === m;
    } 

    if (dateFrom && dateTo) {
      const fromDate = new Date(dateFrom);
      const toDate = new Date(dateTo);
      const monthDate = monthYearToTimestamp(y, m);
      return monthDate >= fromDate.valueOf() && monthDate < toDate.valueOf();
    }
  }

  const onMonthClick = (y,m) => {    
    // Can't select past time
    if (monthYearToTimestamp(y,m) < TODAY) return;
      
    if (!dateFrom && !dateTo) {
      setDateFrom(monthYearToTimestamp(y,m));
    } 

    if (dateFrom && dateTo) {
      setDateFrom("");
      setDateTo("");
    }

    if (dateFrom && !dateTo) {
        if (dateFrom === monthYearToTimestamp(y,m)) {
          setDateTo(monthYearToTimestamp(y, m, true));
        } else {
          if (monthYearToTimestamp(y,m) > dateFrom) {
            const betweenMonths = monthsYearsBetweenDates(new Date(dateFrom), new Date(monthYearToTimestamp(y, m)));
            setDateFrom(monthSelectionTimestamp([...betweenMonths].shift()));
            setDateTo(monthSelectionTimestamp([...betweenMonths].pop(), true));
          }
        }
    }
  }

  const canSubmit = dateFrom && dateTo;

  const onSubmitStep = () => {
    handleNext(dateFrom, dateTo);
  }

	return (
		<View style={styles.container}>
      {showModal && (
        <View style={styles.modal}>
          <Pressable onPress={() => setShowModal(false)}><Text>Close Info</Text></Pressable>
          {booked.map(x => (
            <Text>{JSON.stringify(x)}</Text>
          ))}
        </View>
      )}
			<View style={{flex: 1}}>
				<View style={{padding: 10}}>
          <Text>Tap 1st time to select start date</Text>
          <Text>Tap 2nd time to select end date</Text>
          <Text>Tap 'info' to see booking details</Text>
        </View>
        {loading && <ActivityIndicator />}
        {!loading && (
          <MonthRangeCalendar 
            selectedMonthStyle={{backgroundColor: 'red'}}
            disabledMonthStyle={{backgroundColor: '#f0ebeb'}}
            onBookedView={onBookedView}
            isMonthSelected={isMonthSelected}
            isMonthBooked={isMonthBooked}
            onMonthClick={onMonthClick}
          />
        )}
			</View>
			<View style={styles.footer}>
				<Text style={styles.error}>{stepError}</Text>
        <Button disabled={!canSubmit} wrapStyleCustom={styles.button} text={'NEXT'} onPress={onSubmitStep} />
			</View>
		</View>
	);
}

ApprovedStep1.propTypes = {

}

export default ApprovedStep1;

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  footer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: -2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    borderTopWidth: 1,
    borderTopColor: '#dddddd',
  },
  button: {
  },
  error: {
    fontSize: 14,
    fontFamily: 'Roboto',
    textAlign: 'left',
    userSelect: 'text',
    color: theme.colors.errorTextColor
  },
  bookWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    // justifyContent: 'space-between',
    backgroundColor: 'lightgreen',
    padding: 5
  },
  fieldWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '30%'
  },
  modal: {
    position: 'absolute', 
    bottom: 0,
    left:0, 
    right:0,
    zIndex: 2,
    top: '25%',
    backgroundColor: '#ffffff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 10,
    // height: '100%',
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: -2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    borderTopWidth: 1,
    borderTopColor: '#dddddd',
  }
});