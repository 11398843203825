import React from 'react';
import { useTheme } from '@react-navigation/native';
import { View, Text } from 'react-native';

const UserSearchListEmpty = ({ text }) => {
  const { userSearchListEmptyContainer, userSearchListEmptyText } = useTheme();

  return (
    <View style={userSearchListEmptyContainer}>
      <Text style={userSearchListEmptyText}>{text}</Text>
    </View>
  )
}

export default UserSearchListEmpty;