import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useEffect } from 'react';
import UserSearchScreen from '../../../features/users/screens/Search';
import ViewScreen from '../../../features/users/screens/View';
import CreateScreen from '../../../features/users/screens/Create';
import EditScreen from '../../../features/users/screens/Edit';

const Stack = createStackNavigator();

const AdminUsersScreen = ({ navigation }) => {
  /*
  useEffect(() => {
    return () => {
      console.log('leaving users')
      navigation.reset({
        index: 0,
        routes: [{ name: 'Search' },{ name: 'View' },{ name: 'Create' },{ name: 'Edit' }],
      });
    }
  }, []);
  */
  
  return (
    <Stack.Navigator
      initialRouteName='Search'
      screenOptions={{ 
        // unmountOnBlur: true,
        headerShown: false 
      }}
    >
      <Stack.Screen name="Search" component={UserSearchScreen} />
      <Stack.Screen name="View" component={ViewScreen} />
      <Stack.Screen name="Create" component={CreateScreen} />
      <Stack.Screen name="Edit" component={EditScreen} />
    </Stack.Navigator>
  );
}

export default AdminUsersScreen;
