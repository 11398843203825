import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, ActivityIndicator } from 'react-native';
import { ErrorMessage } from '../../../components';
import EditBrandForm from '../components/EditBrandForm';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_BRAND, GET_BRANDS } from '../../../queries';
import { useQuery, useMutation } from '@apollo/client';


const BrandsEditScreen = ({ navigation, brand_id, where, limit, currentPage }) => {

  const scrollViewRef = useRef(null);
  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const { loading, error: getBrandError, data } = useQuery(GET_BRAND, { variables: { brand_id }, context });
  const [error, setError] = useState(getBrandError || null);
  const offset = (currentPage - 1) * limit;

  const scrollToTop = () => scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });

  if (loading) return <ActivityIndicator />;

  return (
    <ScrollView ref={scrollViewRef} style={{paddingHorizontal: 20}}>
      <ErrorMessage error={error} />
      <EditBrandForm
        navigation={navigation}
        buttonText="Save" 
        onBack={() => navigation.navigate('view', { brand_id })} 
        brandData={data}
        where={where}
        limit={limit}
        offset={offset}
      />
    </ScrollView>
  )
}

BrandsEditScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default BrandsEditScreen;