import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TextInput, Platform } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { useFormik, getIn } from 'formik';
import * as Yup from 'yup';
import Button from '../../../components/Button';

const buildValidationSchema = () =>
  Yup.object({
    firstname: Yup.string().required('Name is required'),
    lastname: Yup.string().required('Surname is required'),
    address: Yup.string().required('Address is required'),
    postcode: Yup.string().required('Postcode is required'),
    city: Yup.string().required('City is required'),
    country: Yup.string().matches(/(RS|BA|HR|ME|SI)/).required(),
    mobile: Yup.string().required('Mobile is required'),
    facebookName: Yup.string().required('FB name is required'),
    facebookLink: Yup.string().url('Facebook link must be valid URL').required('Facebook link is required'),
    heLink: Yup.string().url('HE link must be valid URL').required('HE link is required'),
    adminComment: Yup.string(),
  })

const EditUserForm = ({
  userData,
  buttonText = '',
  onSubmit,
  onBack
}) => {
  const validationSchema = buildValidationSchema();
  const { handleChange, handleBlur, handleSubmit, isSubmitting, values, touched, errors } =
    useFormik({
      initialValues: {
        firstname: userData?.user?.firstname || '',
        lastname: userData?.user?.lastname || '',
        address: userData?.user?.address || '',
        postcode: userData?.user?.postcode || '',
        city: userData?.user?.city || '',
        country: userData?.user?.country || '',
        mobile: userData?.user?.mobile || '',
        facebookName: userData?.user?.facebookName || '',
        facebookLink: userData?.user?.facebookLink || '',
        heLink: userData?.user?.heLink || '',
        adminComment: userData?.user?.adminComment || '',
      },
      enableReinitialize: true,
      validationSchema,
      onSubmit,
    });

  const { 
    formContainer, 
    formFieldContainer, 
    formFieldLabelContainer, 
    formFieldLabel, 
    formFieldLabelRequired, 
    formFieldError, 
    formFieldInput ,
    formFieldTextarea,
    formButtonsContainer
  } = useTheme();

  if (!userData || !userData.user) return null;

  return (
    <View style={formContainer}>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Name</Text>
          {validationSchema.fields['firstname'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'firstname') && getIn(touched, 'firstname')) && errors['firstname']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('firstname')}
          onBlur={handleBlur('firstname')}
          value={values.firstname}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Surname</Text>
          {validationSchema.fields['lastname'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'lastname') && getIn(touched, 'lastname')) && errors['lastname']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('lastname')}
          onBlur={handleBlur('lastname')}
          value={values.lastname}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Address</Text>
          {validationSchema.fields['address'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'address') && getIn(touched, 'address')) && errors['address']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('address')}
          onBlur={handleBlur('address')}
          value={values.address}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Postcode</Text>
          {validationSchema.fields['postcode'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'postcode') && getIn(touched, 'postcode')) && errors['postcode']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('postcode')}
          onBlur={handleBlur('postcode')}
          value={values.postcode}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>City</Text>
          {validationSchema.fields['city'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'city') && getIn(touched, 'city')) && errors['city']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('city')}
          onBlur={handleBlur('city')}
          value={values.city}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Country</Text>
          {validationSchema.fields['country'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'country') && getIn(touched, 'country')) && errors['country']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('country')}
          onBlur={handleBlur('country')}
          value={values.country}
          placeholder="RS or BA or HR or ME or SI"
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Mobile</Text>
          {validationSchema.fields['mobile'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'mobile') && getIn(touched, 'mobile')) && errors['mobile']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('mobile')}
          onBlur={handleBlur('mobile')}
          value={values.mobile}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>FB Name</Text>
          {validationSchema.fields['facebookName'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'facebookName') && getIn(touched, 'facebookName')) && errors['facebookName']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('facebookName')}
          onBlur={handleBlur('facebookName')}
          value={values.facebookName}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>FB link</Text>
          {validationSchema.fields['facebookLink'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'facebookLink') && getIn(touched, 'facebookLink')) && errors['facebookLink']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('facebookLink')}
          onBlur={handleBlur('facebookLink')}
          value={values.facebookLink}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>HE link</Text>
          {validationSchema.fields['heLink'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'heLink') && getIn(touched, 'heLink')) && errors['heLink']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('heLink')}
          onBlur={handleBlur('heLink')}
          value={values.heLink}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Admin comment</Text>
          {validationSchema.fields['adminComment'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'adminComment') && getIn(touched, 'adminComment')) && errors['adminComment']}
          </Text>
        </View>
        <TextInput
          style={formFieldTextarea}
          autoCapitalize="none"
          onChangeText={handleChange('adminComment')}
          onBlur={handleBlur('adminComment')}
          value={values.adminComment}
          multiline={true}
          numberOfLines={Platform.OS === "android" ? 5 : null}
        />
      </View>
      <View style={formButtonsContainer}>
        <Button 
          size="small" 
          type="info" 
          text="Back" 
          onPress={onBack} 
        />
        <Button 
          disabled={isSubmitting} 
          size="small" 
          text={buttonText} 
          onPress={handleSubmit} 
          showLoading 
        />
      </View>
    </View>
  )
}

EditUserForm.propTypes = {
  userData: PropTypes.object,
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func
}

export default EditUserForm;