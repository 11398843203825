import Constants from 'expo-constants';
import { View, Pressable, Text, StyleSheet, Image, ActivityIndicator } from 'react-native';
import { useState, useEffect } from 'react';
import useFileUpload from '../hooks/useFileUpload';
import { unixToDateRenderer } from '../../../utils/date';
import { renderFilesize } from '../utils';

/*
            <View style={styles.row}>
              <View style={styles.cell}>
                <Pressable onPress={() => onOpen()}>
                  <Text style={styles.link}>{value.filename}</Text>
                </Pressable>
              </View>
              <View style={styles.cell}><Text style={styles.text}>{unixToDateRenderer(value.uploadTimestamp)}</Text></View>
              <View style={styles.cell}><Text style={styles.text}>{renderFilesize(value.size)}</Text></View>
              <View style={styles.cell}>
                <Pressable onPress={() => onDelete()}>
                  <Text style={styles.link}>{translations.delete_File || 'Delete'}</Text>
                </Pressable>
              </View>
            </View>
            */

const FileSingle = ({ 
  onChange,
  id, 
  type,
  value=null,
  translations={}, 
  containerStyle={}, 
}) => {

  const [pickFile, unpickFile, uploadFile, deleteFile, openFile, { file, uri, pickAndUpload, isUploading }] = useFileUpload({});

  const onUpload = async () => {
    const newFile = await uploadFile(type, id);
    const { filename, size, uploadTimestamp } = newFile;
    onChange({ filename, size, uploadTimestamp });
  }

  const onPickAndUpload = async () => {
    const newFile = await pickAndUpload(type, id);
    const { filename, size, uploadTimestamp } = newFile;
    onChange({ filename, size, uploadTimestamp });
  }

  const onDelete = async () => {
    // await deleteFile(fileId);
    onChange(null);
  }

  const onOpen = async () => await openFile(type, id, value);

  console.log('savedFile', value, file, uri);
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={[styles.content]}>
        
        <View style={styles.body}>
          {!uri && !isUploading && value && (
            <View style={{width: '100%', height: 200}}>
              <Image style={{flex: 1}} resizeMode="contain" source={{ uri: `${Constants?.expoConfig?.extra?.imageBaseUrl}${type}/${id}/${value.filename}` }} /> 
            </View>
          )}
          {isUploading && <ActivityIndicator />}
          {uri && (
            <View style={{width: '100%', height: 200}}>
              <Image style={{flex: 1}} resizeMode="contain" source={{ uri }} /> 
            </View>
          )}
        </View>
        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
          {!file && <Pressable onPress={onPickAndUpload}><Text style={styles.button}>{`+ ${translations.add_File || 'Select file'}`}</Text></Pressable>}
          {file && (
            <>
              {/*<Pressable onPress={onUpload}><Text style={styles.button}>{`${translations.upload_File || 'Upload'} (${file.name})`}</Text></Pressable>*/}
              <Pressable onPress={unpickFile}><Text style={styles.link}>{translations.unpick_File || 'Cancel'}</Text></Pressable>
            </>
          )}
        </View>
      </View>
    </View>
  );
}

export default FileSingle;

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: 'rgba(158, 150, 150, .5)',
    marginBottom: 40
  },
  trigger : {
    flexDirection: 'row', 
    flexWrap: 'wrap'
  },
  triggerText: {
    fontWeight: '700',
    color: '#242424',
    fontFamily: 'Roboto',
    textAlign: 'left',
    fontSize: 16,
    lineHeight: 24,
    marginLeft: 24,
    marginVertical: 12
  },
  triggerIcon : {
    paddingVertical: 12,
    paddingHorizontal: 26,
    marginLeft: 'auto',
  },
  body : {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto'
  },
  row: {
    flexDirection: 'row',
    flex: 1
  },
  cell: {
    padding: 10,
    flex: 1
  },
  text: {
    fontSize: 12
  },
  link: {
    fontSize: 12,
    color: '#008580',
    textDecorationLine: 'underline',
    fontFamily: 'Roboto'
  },
  button: {
    color: '#008580',
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 16,
    padding: 10,
    fontFamily: 'Roboto'
  }
});