import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_BRANDS, DELETE_BRAND } from '../../../queries';

const useDeleteBrand = (navigation, where, limit, offset) => {
  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const [deleteBrand] = useMutation(DELETE_BRAND, { context });

  const [state, setState] = useState({ deleting: false, errorDeleting: null });

  const handle = async (brand_id) => {
    setState({ deleting: true, errorDeleting: null });
    try {
      const x = await deleteBrand({
        variables: { brand_id },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: GET_BRANDS, variables: { where, limit, offset }, context }
        ],
      });
      setState({ deleting: false, errorDeleting: null });
      navigation.navigate('search');
    } catch (error) {
      setState({ deleting: false, errorDeleting: error });
    }
  }

  return [handle, { ...state }];
}

export default useDeleteBrand;