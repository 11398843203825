import React from 'react';
import { Text, View, StyleSheet, Platform} from 'react-native';
import { useController } from 'react-hook-form';
import { Picker } from '@react-native-picker/picker';
import theme from '../../../../utils/theme';
const { colors } = theme;

const FormInputSelect = ({
  name, 
  control, 
  label, 
  rules={}, 
  placeholder, 
  options=[], 
  enabled,
  containerStyle={}, 
  labelContainerStyle={},
  labelStyle={},
  requiredStyle={},
  errorStyle={},
  selectStyle={},
  optionsStyle={},
  onChangeAfter,
  validate= () => null,
  value: valueExternal,
}) => {
  const { field, fieldState } = useController({name, control});
  const { onBlur, onChange, value='' } = field;
  const { isTouched, isDirty, invalid, error } = fieldState;

  const onValueChange = (value, index) => {
    onChange(value, index);
    if (onChangeAfter) onChangeAfter(value, index);
    validate(name);
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={[styles.labelContainer, labelContainerStyle]}>
        <Text style={[styles.label, labelStyle]}>{label}</Text>
        {rules.required && (<Text style={[styles.required, requiredStyle]}>*</Text>)}
        <Text style={[styles.error, errorStyle]}>
          {invalid && error.message}
        </Text>
      </View>

      <Picker
        {...{ onValueChange, selectedValue: !!valueExternal ? valueExternal : value, enabled }} 
        style={[styles.select, selectStyle]}
        itemStyle={[styles.option, optionsStyle]}
      >
        {placeholder && <Picker.Item label={placeholder} value="" />}
        {options.map(op => (
          <Picker.Item key={`${name}-${op.value}`} label={op.label} value={op.value} />
        ))}
      </Picker>
    </View>
  );
}

export default FormInputSelect;

const styles = StyleSheet.create({
  container: {
    marginBottom: 15
  },
  labelContainer: {
    flexDirection: 'row',
    marginBottom: 5,
    alignItems: 'center'
  },
  label: {
    fontFamily: 'RobotoBold',
    fontSize: 14,
    color: colors.primaryTextColor,
    textAlign: 'left'
  },
  required: {
    fontFamily: 'Roboto',
    fontSize: 14,
    textAlign: 'left',
    color: colors.errorTextColor,
    marginLeft: 5
  },
  error: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: colors.errorTextColor,
    flex: 1,
    textAlign: 'right'
  },
  select: {
    alignSelf: 'stretch',
    padding: 10,
    marginVertical: Platform.OS === 'web' ? 5 : 0,
    marginHorizontal: 'auto',
    borderRadius: 5,
    width: '100%',
    height: Platform.OS === 'ios' ? 88 : 'auto',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 16,
    backgroundColor: Platform.OS === 'web' ? '#f9f9f9' : 'transparent',
    borderColor: '#949494',
    borderWidth: Platform.OS === 'web' ? 1 : 0
  },
  option: {
    fontFamily: 'Roboto',
    height: Platform.OS === 'ios' ? 88 : 36,
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 16
  }
});