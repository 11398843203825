import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TextInput } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { useFormik, getIn } from 'formik';
import * as Yup from 'yup';
import Button from '../../../components/Button';


const buildValidationSchema = () =>
  Yup.object({
    email: Yup.string().email('Email is invalid').required('Email is required'),
  })

const ForgotPasswordForm = ({ 
  buttonText = '', 
  onSubmit 
}) => {
  const validationSchema = buildValidationSchema();
  const { handleChange, handleBlur, handleSubmit, isSubmitting, values, touched, errors } =
    useFormik({
      initialValues: { email: '' },
      validationSchema,
      onSubmit
    })

  const { 
    formContainer, 
    formFieldContainer, 
    formFieldLabelContainer, 
    formFieldLabel, 
    formFieldLabelRequired, 
    formFieldError, 
    formFieldInput
  } = useTheme();

  return (
    <View style={formContainer}>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Email</Text>
          {validationSchema.fields['email'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'email') && getIn(touched, 'email')) && errors['email']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('email')}
          onBlur={handleBlur('email')}
          value={values.email}
          keyboardType="email-address"
        />
      </View>

      <Button disabled={isSubmitting} text={buttonText} onPress={handleSubmit} showLoading />
    </View>
  )
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ForgotPasswordForm;