import Constants from 'expo-constants';
import { initializeApp } from 'firebase/app';
import { ApolloClient, InMemoryCache/*, HttpLink, from*/ } from '@apollo/client';
import { offsetLimitPagination } from "@apollo/client/utilities";
// import { onError } from '@apollo/client/link/error';
import { Alert } from 'react-native';
import createUploadLink from './upload';
/*
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({ uri: Constants?.expoConfig?.extra?.graphqlApiUrl })
*/
export const firebaseApp = initializeApp({
  apiKey: Constants?.expoConfig?.extra?.googleIdentityApiKey,
  authDomain: Constants?.expoConfig?.extra?.googleIdentityAuthDomain,
});

export const apolloClient = new ApolloClient({
  // uri: Constants?.expoConfig?.extra?.graphqlApiUrl,
  cache: new InMemoryCache(/*{
    typePolicies: {
      Query: {
        fields: {
          carries: {
            keyArgs: false,
            merge(existing = {}, incoming = {}, { args }) {
              console.log('merge')
              let mergedItems = existing.items ? existing.items.slice(0) : [];
              if (incoming.items) {
                if (args) {
                  const { offset = 0 } = args;
                  if (offset === 0) {
                    console.log('Page 1')
                  }
                  for (let i = 0; i < incoming.items.length; ++i) {
                    mergedItems[offset + i] = incoming.items[i];
                  }
                } else {
                  mergedItems.push(...incoming.items);
                }
              }
              return {
                ...existing, 
                ...incoming,
                items: mergedItems
              };
            },
          }
        },
      },
    },
  }*/),
  link: createUploadLink({
    uri: Constants?.expoConfig?.extra?.graphqlApiUrl,
  }),
  // link: from([errorLink, httpLink]),
  // headers: !auth.isSignout ? { Authorization: `Bearer ${auth.token}` } : {}
});
