import React from 'react';
import { View, Text, StyleSheet, FlatList, Platform } from 'react-native';
import Button from './Button';
import ListItem from './ListItem';
import SearchField from './SearchField';
import { useTheme } from '@react-navigation/native';
import { Picker } from '@react-native-picker/picker';


const List = ({ 
  translations={}, 
  data=[], 
  value, 
  placeholder="Search",
  onSelect, 
  onSearch,
  onWhere,
  whereConfig=[],
  where,
  onRefresh,
  refreshing,
  searchText,
  keyName='id',
  listItemContent,
  listItemBackgroundColor=() => null,
  emptyDataComponent: EmptyDataComponent,
  headerComponent: HeaderComponent,
  separatorComponent: SeparatorComponent,
  createButtonComponent: CreateButtonComponent,
  footerActions=[] //[{label: 'Action', action: () => console.log('Fire Action')}]
}) => {

  const handleWhere = (field) => (value) => {
    const newWhere = { ...where, [field.name]: value ? {[field.operation || 'eq']: value} : undefined };
    const hasFilters = Object.keys(newWhere).find(k => newWhere[k]);
    onWhere(hasFilters ? newWhere : null);
  }

  const { formPicker, formSelectItem } = useTheme();

  return (
    <>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        {onWhere && (
          <View style={{flex: 1}}>
            {whereConfig.map(f => (
              <View key={f.name}>
                {f.type === "select" && (
                  <Picker
                    style={formPicker}
                    itemStyle={formSelectItem}
                    selectedValue={!where ? '' : where[f.name] && where[f.name][f.operation || 'eq']}
                    onValueChange={handleWhere(f)}
                    // onBlur={handleBlur('country')}
                  >
                    <Picker.Item label={f.placeholder || "Izaberite zemlju porekla"} value="" />
                    {Object.keys(f.options).map((countryId) => {
                      return (
                        <Picker.Item label={f.options[countryId]} value={countryId} key={countryId} />
                      );
                    })}
                  </Picker>
                )}
              </View>
            ))}
          </View>
        )}
        {CreateButtonComponent && <CreateButtonComponent />}
      </View>
      {onSearch && (
        <SearchField
          onSearch={onSearch} 
          searchText={searchText} 
          wrapStyle={{backgroundColor: '#ffffff', borderBottomWidth: 1, borderColor: '#EFEFEF'}}
          inputWrapStyle={{
            flexDirection: 'row', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            padding: 10,
            marginHorizontal: 16.5, 
            marginVertical: 10,
            backgroundColor: '#f9f9f9',
            borderWidth: 1, 
            borderColor: '#f4f4f4', 
            borderRadius: 4,
          }}
          inputStyle={[{flex: 1}, Platform.OS === 'web' ? {outline: 'none'} : {}]}
          placeholderText={placeholder}
          textColor="#5b5b5b"
        />
      )}
      <FlatList
        ListEmptyComponent={EmptyDataComponent}
        ListHeaderComponent={HeaderComponent}
        ItemSeparatorComponent={SeparatorComponent}
        data={data}
        onRefresh={onRefresh}
        refreshing={refreshing}
        renderItem={({ item }) => (
          <ListItem 
            backgroundColor={listItemBackgroundColor(item)}
            selected={value === item[keyName]} 
            onPress={() => onSelect(item)}
            itemContent={() => listItemContent(item)}
          />)}
        keyExtractor={item => item[keyName]}
        extraData={value}
        // onEndReachedThreshold={0.5}
        // onEndReached={({ distanceFromEnd }) => console.log('THE END', distanceFromEnd)}
      />
      {footerActions.length > 0 && (
        <View style={styles.footer}>
          {footerActions.map(a => (
            <Button key={Math.random()} text={a.label} onPress={a.action} />
          ))}
        </View>
      )}
    </>
  );
}

export default List;

const styles = StyleSheet.create({
  footer : {
    paddingVertical: 10, 
    paddingHorizontal: 25, 
    backgroundColor: '#ffffff', 
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: -2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    borderTopWidth: 1,
    borderTopColor: '#dddddd',
    alignItems: 'center'
  }
});
