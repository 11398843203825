import { useState } from 'react';
import Constants from 'expo-constants';
import { View, Text, StyleSheet, Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import FormInputSelectConfig from '../../../config/components/FormInputSelectConfig';
import { buildBrandSearchSchema } from '../../utils';

const BrandSearchForm = ({ 
  where, 
  onWhere
}) => {
	const i18n = Constants?.expoConfig?.extra?.i18n;
	const config = useSelector((state) => state.config);
	const schema = buildBrandSearchSchema(config);
	const { handleSubmit, control, reset, watch, formState, trigger } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      country: where?.country?.eq || "",
    }
  });

  const onFieldChanged = (name, value) => {
    const data = {...where, [name]: value ? {['eq']: value} : undefined };
    const hasFilters = Object.keys(data).find(k => data[k]);
    onWhere(hasFilters ? data : null);
  }

	return ( 
		<View style={styles.container}>
		  	<FormInputSelectConfig
		  			onChangeAfter={(v) => onFieldChanged('country', v)}
						validate={trigger}
						control={control} 
						name="country" 
						type="BrandCounties"
						placeholder={i18n[config.locale].brand_field_country_placeholder || "Select country"} 
						rules={{required: schema.fields['country']?.exclusiveTests.required }}
						containerStyle={{marginBottom: 0}}
				/>
		</View>
	);
}

export default BrandSearchForm;

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
});