import React from 'react';
import { useState } from 'react';
import { getDocumentAsync } from 'expo-document-picker';
import * as WebBrowser from 'expo-web-browser';
import { useMutation, useLazyQuery } from '@apollo/client';
// import { uploadFile, deleteFile, apiUrl, getFile } from '../utils';
import { useAuthContext } from '../../../context/useAuthContext';
import { UPLOAD_ASSET, GET_ASSET } from '../../../queries';

const extensions = {
  "image/png": ".png",
  "image/jpeg": ".jpg",
  "text/plain": ".txt",
}
const useFileUpload = ({ 
  multiple = false, 
  type = "*/*", 
  copyToCacheDirectory = true
}) => {
  const { token } = useAuthContext();
  const [getAsset, { loading, error, data }] = useLazyQuery(GET_ASSET, { 
    context: {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    }
  });
  const [uploadAsset] = useMutation(UPLOAD_ASSET, {
    context: {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Apollo-Require-Preflight": true
      }
    }
  });

  // const auth = useSelector((state) => state.auth);
  const [state, setState] = useState({ 
    file: null,
    uri: null,
    isPicking: false, 
    errorPicking: null,
    isUploading: false, 
    errorUploading: null
  });

  const handlePicking = async () => {
    setState(prev => ({ ...prev, isPicking: true, errorPicking: null }))
    try {
      const response = await getDocumentAsync({ 
        multiple, 
        type, 
        copyToCacheDirectory 
      });
      console.log('handlePicking', response);
      setState(prev => ({ ...prev, file: response.assets[0].file, uri: response.assets[0].uri, isPicking: false, errorPicking: null }));
    } catch (error) {
      setState(prev => ({ ...prev, isPicking: false, errorPicking: error }))
    }
  }

  const handleUnPicking = () => 
    setState(prev => ({ ...prev, file: null, uri: null }));

  const handleUpload = async (type, id) => {
    setState(prev => ({ ...prev, isUploading: true, errorUploading: null }))
    try {
      const uploaded = await uploadAsset({
        variables: { id, type, asset: state.file },   
      });
      setState(prev => ({ ...prev, file: null, uri: null, isUploading: false, errorUploading: null }));
      return {
        filename: state.file.name,
        size: state.file.size,
        uploadTimestamp: state.file.lastModified
      };
    } catch (error) {
      console.log(error);
      setState(prev => ({ ...prev, isUploading: false, errorUploading: error }))
    }
  }

  const handlePickAndUpload = async (type, id) => {
    setState(prev => ({ ...prev, isPicking: true, errorPicking: null, isUploading: true, errorUploading: null }));
    try {
      const response = await getDocumentAsync({ multiple, type, copyToCacheDirectory });
      console.log('response', response)
      const uploaded = await uploadAsset({ variables: { id, type, asset: response.assets[0].file } });
      console.log('uploaded', uploaded)
      setState(prev => ({ ...prev, file: response.assets[0].file, uri: response.assets[0].uri, isPicking: false, errorPicking: null, isUploading: false, errorUploading: null }));
      return {
        filename: response.assets[0].file.name,
        size: response.assets[0].file.size,
        uploadTimestamp: response.assets[0].file.lastModified
      };
    } catch (error) {
      setState(prev => ({ ...prev, isPicking: false, isUploading: false, errorUploading: error }))
    }
  }

  const handleDelete = async (fileId) => {
    try {
      // await deleteFile(auth, `${url}/${fileId}`); 
    } catch (error) {
      console.log(error);
    }
  }

  const handleOpen = async (type, id, file) => {
    try {
      const downloaded = await getAsset({
        variables: { id, type, filename: file.filename },   
      });
      // console.log('downloaded', downloaded);
      const fileUrl = downloaded.data.assets.url; 
      // console.log('handleOpen', fileUrl);
      await WebBrowser.openBrowserAsync(fileUrl, {
        windowName: file.filename
      });
    } catch (error) {
      console.log(error);
    }
  }

  return [handlePicking, handleUnPicking, handleUpload, handleDelete, handleOpen, { 
    ...state,
    pickFile: handlePicking, 
    unpickFile: handleUnPicking, 
    uploadFile: handleUpload, 
    pickAndUpload: handlePickAndUpload,
    deleteFile: handleDelete, 
    openFile: handleOpen
  }]
}

export default useFileUpload;