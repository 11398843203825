import Constants from 'expo-constants';
import { useState, useCallback, useEffect } from 'react';
import { useTheme } from '@react-navigation/native';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { ScrollView, RefreshControl, View, Text, Image } from 'react-native';
import { useSelector } from 'react-redux';
import Button from '../../../components/Button';
import ErrorMessage from '../../../components/ErrorMessage';
import { useAuthContext } from '../../../context/useAuthContext';
import { getConfigOptionLabel } from '../../config/utils';
import { GET_CARRY, GET_CARRIES, GET_ASSET } from '../../../queries';
import { dateTimeRenderer } from '../../../utils/date';

const CarryView = ({ carryData, onBack, onEdit, where }) => {
  const config = useSelector((state) => state.config);
  const i18n = Constants?.expoConfig?.extra?.i18n;
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  // const [image, setImage] = useState(null);
  const client = useApolloClient();
  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const {heading1, paragraph, formFieldLabel, formDefaultText} = useTheme();
/*
  const [getAsset, { loading, error: imageError }] = useLazyQuery(GET_ASSET, { context });

  useEffect(() => {
    const fetchImage = async () => {
      const downloaded = await getAsset({
        variables: { id: carryData?.carry?.carry_id, type: 'carry', filename: carryData?.carry?.image?.filename }
      });
      setImage(downloaded.data.assets.url);
    }

    if (carryData?.carry?.image) fetchImage();
  }, [])
*/
  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    const d = await client.refetchQueries({ include: [GET_CARRY] });
    setRefreshing(false)
  }, []);

  const handleDelete = async () => {
    setRefreshing(true);
    try {
      onBack();
    } catch(e) {
      setError(e);
    }
    setRefreshing(false);
  }

  if (!carryData || !carryData.carry) return null;
  const { carry } = carryData;
  const { carry_id, image } = carry;

  return (
    <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />} style={{paddingHorizontal: 10}}>
      <ErrorMessage error={error} />
      <Text style={heading1}>{carry.name}</Text>
      <View style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', paddingVertical: 10 }}>
        <View style={{}}>
          <Text style={formFieldLabel}>Created At</Text>
          <Text style={formDefaultText}>{dateTimeRenderer(carry.createdAt)}</Text>
          <Text style={formFieldLabel}>Last Modified At</Text>
          <Text style={formDefaultText}>
            {dateTimeRenderer(carry.modifiedAt)} {"by"} {carry?.lastModifiedBy?.firstname} {carry?.lastModifiedBy?.lastname}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Button disabled={refreshing} size="small" text="DELETE" onPress={() => handleDelete()} />
        </View>
      </View>
      {image && ( 
        <View style={{width: '100%', height: 200}}>
          <Image style={{flex: 1}} resizeMode="contain" source={{ uri: `${Constants?.expoConfig?.extra?.imageBaseUrl}carry/${carry_id}/${image.filename}` }} /> 
        </View>
      )}
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>Description</Text>
        <Text style={formDefaultText}>{carry.description}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>Type</Text>
        <Text style={formDefaultText}>{getConfigOptionLabel(config, "CarryTypes", carry.type)}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>Brand</Text>
        <Text style={formDefaultText}>{carry?.brand.name}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>Size</Text>
        <Text style={formDefaultText}>{getConfigOptionLabel(config, "Sizes", carry.size)}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>Experience Level</Text>
        <Text style={formDefaultText}>{getConfigOptionLabel(config, "ExperienceRequirement", carry.experienceLevel)}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_color_label || "Color"}</Text>
        <Text style={formDefaultText}>{carry.color}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_origin_label || "Origin"}</Text>
        <Text style={formDefaultText}>{carry.origin}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_fabricDensity_label || "Fabric Density (g/m2)"}</Text>
        <Text style={formDefaultText}>{carry.fabricDensity}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_material_label || "Materials"}</Text>
        <Text style={formDefaultText}>{carry.material}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_monthFrom_label || "Meseci (od)"}</Text>
        <Text style={formDefaultText}>{carry.monthFrom}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_monthTo_label || "Meseci (do)"}</Text>
        <Text style={formDefaultText}>{carry.monthTo}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_weightFrom_label || "Tezina kg (od)"}</Text>
        <Text style={formDefaultText}>{carry.weightFrom}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_weightTo_label || "Tezina kg (do)"}</Text>
        <Text style={formDefaultText}>{carry.weightTo}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_recension_label || "Recensions"}</Text>
        <Text style={formDefaultText}>{carry.recension}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_tags_label || "Tags"}</Text>
        <Text style={formDefaultText}>{carry.tags}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_adminComment_label || "Admin comment"}</Text>
        <Text style={formDefaultText}>{carry.adminComment}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_state_label || "State"}</Text>
        <Text style={formDefaultText}>{carry.state}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_usedFor_label || "Used for"}</Text>
        <Text style={formDefaultText}>{getConfigOptionLabel(config, "Usage", carry.bookTest)}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_countries_label || "Used in Counties"}</Text>
        {(carry.countriesBooking || []).map(x => (
          <Text key={x} style={formDefaultText}>{getConfigOptionLabel(config, "Counties", x)}</Text>
        ))}
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>{i18n[config.locale].carry_field_bookableFrom_label || "Bookable/Testable From"}</Text>
        <Text style={formDefaultText}>{dateTimeRenderer(carry.bookableFrom)}</Text>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button size="small" type="info" text="Back" onPress={onBack} />
        <Button size="small" text="Edit" onPress={onEdit} />
      </View>
    </ScrollView>
  )
}

export default CarryView;