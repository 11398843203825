import React from 'react';
import { useTheme } from '@react-navigation/native';
import { View, Text } from 'react-native';

const BrandsSearchListEmpty = ({ text }) => {
  const { brandSearchListEmptyContainer, brandSearchListEmptyText } = useTheme();

  return (
    <View style={brandSearchListEmptyContainer}>
      <Text style={brandSearchListEmptyText}>{text}</Text>
    </View>
  )
}

export default BrandsSearchListEmpty;