import { useState } from 'react';
import { Text, View, ScrollView, StyleSheet, Platform, Pressable } from 'react-native';
import { SimpleLineIcons } from '@expo/vector-icons'; 
import { useSelector } from 'react-redux';
import Constants from 'expo-constants';
import theme from '../utils/theme';
import { TODAY, THIS_YEAR, THIS_MONTH, MONTHS, monthYearToTimestamp } from '../utils/date';
const { colors } = theme;

const MonthRangeCalendar = ({
  containerStyle={}, 
  errorStyle={},
  selectedMonthStyle={},
  disabledMonthStyle={},
  showYearsBack=1, 
  showYearsAhead=1,
  onBookedView=() => null,
  isMonthSelected=() => false,
  isMonthBooked=() => false,
  onMonthClick=() => false,
}) => {
  const i18n = Constants?.expoConfig?.extra?.i18n;
  const config = useSelector((state) => state.config);

  const minYear = THIS_YEAR - showYearsBack;
  const maxYear = THIS_YEAR + showYearsAhead;
  const yearRange = Array.from({length: maxYear - minYear + 1}, (v, k) => k + minYear);
  const [blocks, setBlocks] = useState({[THIS_YEAR]: true, [THIS_YEAR+1]: THIS_MONTH === 11 ? true : false});
  
  return (
    <ScrollView style={[styles.container, containerStyle]}>    
      <View style={styles.calendarContainer}>
	      {yearRange.map(y => (
	        <View key={`${y}`} style={styles.yearBlock}>
	            <Pressable style={styles.yearTrigger} onPress={() => setBlocks({ ...blocks, [y]: !blocks[y] })}>
	              <Text style={styles.yearText}>{y}</Text>
	              {!blocks[y] && (<SimpleLineIcons name="arrow-down" size={20} color={colors.primaryTextColor} />)}
	              {blocks[y] && (<SimpleLineIcons name="arrow-up" size={20} color={colors.primaryTextColor} />)}
	            </Pressable>
	            <View style={[styles.yearContent, {display: blocks[y] ? 'flex' : 'none' }]}>
	              {MONTHS.map(m => {
	                return (
	                  <Pressable 
	                    key={`${y}-${m}`} 
	                    style={[
	                      styles.monthTrigger,
	                      isMonthSelected(y, m) ? selectedMonthStyle : {},
	                      monthYearToTimestamp(y,m) < TODAY ? disabledMonthStyle : {},
	                    ]} 
	                    onPress={() => onMonthClick(y,m)}
	                  >
	                    <Text style={styles.monthText}>{i18n[config.locale][m] || m}</Text>
	                    {isMonthBooked(y, m) && (
	                    	<Pressable onPress={() => onBookedView(y, m)} style={styles.tag}>
	                    		<SimpleLineIcons name="info" size={20} color={colors.primaryTextColor} />
	                    	</Pressable>
	                    )}
	                  </Pressable>
	                );
	              })}
	            </View>
	        </View>
	      ))}
	  </View>
      
    </ScrollView>
  );
}

export default MonthRangeCalendar;

const styles = StyleSheet.create({
  container: {},
  error: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: colors.errorTextColor,
    flex: 1,
    textAlign: 'right'
  },
  calendarContainer: {
  	paddingHorizontal: 0
  },
  yearBlock: {},
  yearTrigger: {
  	flexDirection: 'row',
  	justifyContent: 'space-between',
  	alignItems: 'center',
    backgroundColor: '#f9f9f9',
    paddingHorizontal: 12,
    paddingVertical: 10
  },
  yearText: {
    fontSize: 14,
    color: colors.primaryTextColor,
    fontFamily: 'RobotoBold',
    textAlign: 'left',
  },
  yearContent: {},
  monthTrigger: {
    flexDirection: 'row',
  	justifyContent: 'space-between',
  	alignItems: 'center',
  },
  monthText: {
    fontSize: 14,
    color: colors.primaryTextColor,
    fontFamily: 'Roboto',
    textAlign: 'center',
    padding: 10
  },
  tag: {
    backgroundColor: 'lightgreen',
    padding: 10
  }
});