import { ScrollView } from 'react-native';
import CreateBrandForm from '../components/CreateBrandForm';

const BrandsCreateScreen = ({ navigation, where }) => (
    <ScrollView style={{paddingHorizontal: 20}}>
      <CreateBrandForm
        buttonText="Create Brand" 
        onBack={() => navigation.navigate('search')} 
        {...{ navigation, where }}
      />
    </ScrollView>
);

export default BrandsCreateScreen;