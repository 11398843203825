import PropTypes from 'prop-types';
import Constants from 'expo-constants';
import { View, Text, TextInput, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from '../../../components/ErrorMessage';
import Button from '../../../components/Button';
import FormInputText from '../../form/components/fields/FormInputText';
import FormInputTextarea from '../../form/components/fields/FormInputTextarea';
import FormInputSelect from '../../form/components/fields/FormInputSelect';
import FormInputSelectConfig from '../../config/components/FormInputSelectConfig';
import useUpdateBrand from '../hooks/useUpdateBrand';
import { buildSchema } from '../utils';

const EditBrandForm = ({
  brandData,
  buttonText = '',
  onBack,
  where,
  limit,
  offset,
  navigation
}) => {
  const config = useSelector((state) => state.config);
  const i18n = Constants?.expoConfig?.extra?.i18n;
  const schema = buildSchema(config);
  const { handleSubmit, control, reset, watch, formState, trigger } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: brandData?.brand?.name || "",
      contact: brandData?.brand?.contact || "",
      email: brandData?.brand?.email || "",
      comment: brandData?.brand?.comment || "",
      country: brandData?.brand?.country || "",
      deleted: brandData?.brand?.deleted || false
    }
  });
  const { isValid, errors } = formState;

  const [onUpdate, { updating, errorUpdating }] = useUpdateBrand(navigation, where, limit, offset);
  const handleUpdate = async (data) => await onUpdate(brandData?.brand?.brand_id, data);

  return (
    <View style={styles.container}>
      <FormInputText 
        control={control} 
        name="name" 
        label={i18n[config.locale].brand_field_name_label || "Name"}
        placeholder={i18n[config.locale].brand_field_name_placeholder || "Unesite naziv proizvođača, brenda"}
        rules={{required: schema.fields['name']?.exclusiveTests.required }} 
      />
      <FormInputText 
        control={control} 
        name="email" 
        label={i18n[config.locale].brand_field_email_label || "Email"}
        placeholder={i18n[config.locale].brand_field_email_placeholder || "Unesite email za kontakt proizvođača"}
        rules={{required: schema.fields['email']?.exclusiveTests.required }} 
      />
      <FormInputSelectConfig
        validate={trigger}
        control={control} 
        name="country" 
        type="BrandCounties"
        label={i18n[config.locale].brand_field_country_label || "Country"} 
        rules={{required: schema.fields['country']?.exclusiveTests.required }}
      />
      <FormInputTextarea 
        control={control} 
        name="contact" 
        label={i18n[config.locale].brand_field_contact_label || "Contact"}
        placeholder={i18n[config.locale].brand_field_contact_placeholder || "Unesite mobilni broj, web sajt, sve društvene mreže, sve što bi moglo biti od koristi"}
        rules={{required: schema.fields['contact']?.exclusiveTests.required }} 
      />
      <FormInputTextarea 
        control={control} 
        name="comment" 
        label={i18n[config.locale].brand_field_comment_label || "Comment"}
        placeholder={i18n[config.locale].brand_field_comment_placeholder || "Unesite mobilni broj, web sajt, sve društvene mreže, sve što bi moglo biti od koristi"}
        rules={{required: schema.fields['comment']?.exclusiveTests.required }} 
      />
      {errorUpdating && <ErrorMessage error={errorUpdating} />}
      <View style={styles.buttons}>
        <Button onPress={onBack} size="small" type="info" text="Back" />
        <Button onPress={handleSubmit(handleUpdate)} disabled={updating} size="small" text={buttonText} showLoading />
      </View>
    </View>
  )
}

EditBrandForm.propTypes = {
  brandData: PropTypes.object,
  buttonText: PropTypes.string,
  onBack: PropTypes.func,
}

export default EditBrandForm;

const styles = StyleSheet.create({
  container: {
    marginTop: 15,
  },
  buttons: {
    flexDirection: 'row', 
    justifyContent: 'space-between',
    marginBottom: 50  
  },
});