import { Text, View, StyleSheet} from 'react-native';
import { useController } from 'react-hook-form';
import DateSelect from '../DateSelect';

const FormInputDate = ({
  name, 
  description,
  control, 
  label, 
  rules={}, 
  min, 
  editable, 
  containerStyle={},
  labelStyle={},
  descriptionStyle={},
  onBlur: onBlurExternal,
}) => {
  const { field, formState } = useController({name, control});
  const { onBlur, onChange, value } = field;
  const { errors } = formState;
  // console.log(field, formState, rules)
  return (
    <View style={[styles.container, containerStyle]}>
      <Text style={[styles.label, labelStyle]}>{label}{rules.required ? ' *' : ''}</Text>
      <DateSelect 
        {...{ onChange, value, min, editable }} 
        onBlur={onBlurExternal || onBlur}
        dateInputContainerStyle={styles.input}
      />
      
    </View>
  );
}

export default FormInputDate;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 0
  },
  label: {
    position: 'absolute',
    left: 15,
    backgroundColor: '#ffffff',
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 12,
    top: -6,
    zIndex: 1
  },
  description: {
    fontSize: 12,
    lineHeight: 12,
    paddingTop: 5
  },
  input: {
    width: 'auto', 
    marginHorizontal: 0,
    marginVertical: 0
  },
  errorStyle: {
    marginHorizontal: 5,
    color: 'red',
  },
});