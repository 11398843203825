import React from 'react';
import { useState } from 'react';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import { ScreenContainer, ErrorMessage, SuccessMessage } from '../../../components';
import { sendPasswordReset } from '../../../utils/user';
import { useAuthContext } from '../../../context/useAuthContext';


const ForgotPasswordScreen = (props) => {

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { user } = useAuthContext();

  const handleForgotPassword = async (values) => {
    try {
      await sendPasswordReset(values);
      setSuccess('Email sucessfully sent.');
    } catch (er) {
      setError(er)
    }
  }

  return (
    <ScreenContainer>
      <ErrorMessage error={error} />
      <SuccessMessage text={success} />
      <ForgotPasswordForm buttonText="Send email" onSubmit={handleForgotPassword} />
    </ScreenContainer>
  )
}

export default ForgotPasswordScreen;