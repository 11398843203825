import { useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { ActivityIndicator } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
WebBrowser.maybeCompleteAuthSession();
import { 
  VerifiedNavigator, 
  GuestNavigator, 
  UnverifiedNavigator, 
  ApprovedNavigator, 
  AdminNavigator 
} from './navigators';
import { useAuthContext } from './context/useAuthContext';
import useGetConfigs from './features/config/hooks/useGetConfigs';
import ErrorMessage from './components/ErrorMessage';

const App = (props) => {

  const { role, user, isLoading, error } = useAuthContext();
  const [getConfigs, { fetching, errorFetching }] = useGetConfigs();
  
  useEffect(() => {
    getConfigs();
  }, [])
  
  if (isLoading || fetching) return (<ActivityIndicator />);
  //  TOO BLOCKING
  // if (error) return (<ErrorMessage error={error} />);

  if (user && role === 'ADMIN') return <AdminNavigator />;
  if (user && role === 'APPROVED') return <ApprovedNavigator />;
  if (user && role === 'VERIFIED') return <VerifiedNavigator />
  if (user && role === 'PENDING') return <UnverifiedNavigator />
  return (<GuestNavigator />);
}

export default App;
