import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, ActivityIndicator } from 'react-native';
import { useQuery } from '@apollo/client';
import { useAuthContext } from '../../../context/useAuthContext';
import { ErrorMessage } from '../../../components';
import BrandView from '../components/BrandView';
import { GET_BRAND } from '../../../queries';

const BrandViewScreen = ({ navigation, route, brand_id, where, limit, currentPage }) => {

  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const { loading, error, data } = useQuery(GET_BRAND, { variables: { brand_id }, context });
  const offset = (currentPage - 1) * limit;

  if (loading) return <ActivityIndicator />;

  return (
    <>
      <ErrorMessage error={error} />
      <BrandView 
        brandData={data}
        onBack={() => navigation.navigate('search')}
        onEdit={() => navigation.navigate('edit', { brand_id })}
        where={where}
        limit={limit}
        offset={offset}
        navigation={navigation}
      />
    </>
  )
}

BrandViewScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default BrandViewScreen;