import { useState } from 'react';
import { View, Platform } from 'react-native';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import ApprovedStep1 from '../components/ApprovedStep1';
import ApprovedStep2 from '../components/ApprovedStep2';
import ApprovedStep3 from '../components/ApprovedStep3';
import ApprovedSummary from '../components/ApprovedSummary';
import { useAuthContext } from '../../../context/useAuthContext';
import useCreate from '../hooks/useCreate';
import { buildSchema } from '../utils';

const ApprovedBookingScreen = ({ navigation, route }) => {
  const auth = useAuthContext();
  const [page, setPage] = useState('calendar'); // summary | comment | carry | calendar (default)
  const [params, setParams] = useState({}); // carry_id
  const [onCreate, { creating, errorCreating }] = useCreate(navigation, {user: {eq: auth.user.user_id}});

  const schema = buildSchema();
  const { handleSubmit, control, reset, watch, formState, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      carry: "",
      user: auth.user.user_id,
      dateFrom: "",
      dateTo: "",
      comment: "",
    }
  });

  const { dateFrom, dateTo, carry, comment, user } = watch();

  const navigate = (page, params) => {
    setPage(page);
    setParams(params);
  }

  const handleCalendarDone = (dateFrom, dateTo) => {
    setValue('dateFrom', dateFrom);
    setValue('dateTo', dateTo);
    console.log(dateFrom, dateTo);
    setPage('carry');
  }

  const handleCarryDone = (carry) => {
    setValue('carry', carry);
    console.log(carry);
    setPage('comment');
  }

  const handleCommentDone = (comment) => {
    setValue('comment', comment);
    console.log(comment);
    setPage('summary');
  }

  return (
    <View style={{flex: 1}}>
      {page === 'calendar' && (
        <ApprovedStep1 
          handleNext={handleCalendarDone} 
          dateFromExternal={dateFrom}
          dateToExternal={dateTo} 
        />
      )}
      {page === 'carry' && (
        <ApprovedStep2 
          handleNext={handleCarryDone} 
          handlePrevious={() => setPage('calendar')} 
          dateFrom={dateFrom}
          carryExternal={carry}
        />
      )}
      {page === 'comment' && (
        <ApprovedStep3 
          handleNext={handleCommentDone} 
          handlePrevious={() => setPage('carry')} 
          commentExternal={comment}
        />
      )}
      {page === 'summary' && (
        <ApprovedSummary 
          handleSubmit={() => handleSubmit(onCreate)} 
          handlePrevious={() => setPage('comment')} 
          formValues={{ dateFrom, dateTo, carry, comment, user }}
        />
      )}
    </View>
  );
}

export default ApprovedBookingScreen;
