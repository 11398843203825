import { View, StyleSheet, Platform } from 'react-native';
import { createElement } from 'react-native-web';

const DateSelect = (props) => {
  const {
    dateInputContainerStyle,
    editable = true,
    onBlur,
    onChange,
    onFocus,
    onKeyPress,
    onLayout,
    onSelectionChange,
    onSubmitEditing,
    min,
    max,
    step = 'any',
    value,
    placeholder,
    required 
  } = props;

  const handleBlur = (e) => {
    if (onBlur) {
      e.nativeEvent.text = e.target.value;
      onBlur(e);
    }
  }

  const handleChange = (e) => {
    const hostNode = e.target;
    const text = hostNode.value;
    e.nativeEvent.text = text;
    if (onChange) {
      onChange(text);
    }
  }

  const handleFocus = (e) => {
    const hostNode = e.target;
    hostNode.showPicker();
    if (onFocus) {
      e.nativeEvent.text = hostNode.value;
      onFocus(e);
    }
  }

  const handleKeyDown = (e) => {
    const hostNode = e.target;
    e.stopPropagation();

    const nativeEvent = e.nativeEvent;
    // If an Input Method Editor is processing key input, the 'keyCode' is 229.
    // https://www.w3.org/TR/uievents/#determine-keydown-keyup-keyCode
    const isComposing = nativeEvent.isComposing || nativeEvent.keyCode === 229;

    if (onKeyPress) {
      onKeyPress(e);
    }

    if (
      e.key === 'Enter' &&
      !e.shiftKey &&
      // Do not call submit if composition is occuring.
      !isComposing &&
      !e.isDefaultPrevented()
    ) {
      if (onSubmitEditing) {
        // prevent "Enter" from inserting a newline or submitting a form
        e.preventDefault(); 
        nativeEvent.text = e.target.value;
        onSubmitEditing(e);
      }
      if (hostNode != null) {
        setTimeout(() => hostNode.blur(), 0);
      }
    }
  }

  const handleSelectionChange = (e) => {
    if (onSelectionChange) {
      try {
        const node = e.target;
        const { selectionStart, selectionEnd } = node;
        e.nativeEvent.selection = {
          start: selectionStart,
          end: selectionEnd
        };
        e.nativeEvent.text = e.target.value;
        onSelectionChange(e);
      } catch (e) {}
    }
  }
  
  const element = createElement('input', { 
    type: 'date',
    value, 
    step, 
    min, 
    max, 
    required,
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    onKeyDown: handleKeyDown,
    onSelect: handleSelectionChange,
    // readOnly: !editable,
    style: [
      styles.dateinput,
      Platform.OS === 'web' && {outline: 'none'},
      props.style
    ]
  });
  
  if (Platform.OS !== 'web') return null;

  return (
    <View style={[styles.dateInputContainerStyle, dateInputContainerStyle]}>
      {element}
    </View>
  );
};

DateSelect.displayName = 'DateSelect';

const styles = StyleSheet.create({
  dateInputContainerStyle: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    padding: 10,
    marginHorizontal: 16.5, 
    marginVertical: 10,
    backgroundColor: '#f9f9f9',
    borderWidth: 1, 
    borderColor: '#949494', 
    borderRadius: 4,
    width: 220
  },
  dateinput: {
    backgroundColor: 'transparent',
    border: '0 solid black',
    borderRadius: 0,
    boxSizing: 'border-box',
    flex: 1
  }
});

export default DateSelect;