import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_BOOKINGS, CREATE_BOOKING } from '../../../queries';

const useCreate = (navigation, where) => {
  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const [createBooking] = useMutation(CREATE_BOOKING, { context });
  const [state, setState] = useState({ creating: false, errorCreating: null });

  const handle = async (data) => {
    setState({ creating: true, errorCreating: null });
    try {
      // console.log(data)
    	
      const x = await createBooking({
        variables: { data },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: GET_BOOKINGS, variables: { where }, context }
        ]
      });
      
      console.log('x', x)
      setState({ creating: false, errorCreating: null });
      // navigation.navigate('calendar');
    } catch (error) {
      setState({ creating: false, errorCreating: error });
    }
  }

  return [handle, { ...state }];
}

export default useCreate;