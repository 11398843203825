import React from 'react';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';
import { SimpleLineIcons } from '@expo/vector-icons';
import { View } from 'react-native';
import AdminWelcomeScreen from '../features/admin/screens/AdminWelcomeScreen';
import AdminUsersScreen from '../features/admin/screens/AdminUsersScreen';
import AdminBrandsScreen from '../features/admin/screens/AdminBrandsScreen';
import AdminCarriesScreen from '../features/admin/screens/AdminCarriesScreen';
import BookingScreen from '../features/booking/screens/BookingScreen';
import LanguageMenuItems from '../features/language-switcher/components/LanguageMenuItems';
import CreateButton from '../components/CreateButton';
import theme from '../utils/theme';
import { logOut } from '../utils/user';
import { useSelector } from 'react-redux';
import Constants from 'expo-constants';


const MyTheme = { ...DefaultTheme, ...theme };
const Drawer = createDrawerNavigator();

const AdminNavigator = (props) => {
  const config = useSelector((state) => state.config);
  const i18n = Constants?.expoConfig?.extra?.i18n;

  return (
    <NavigationContainer theme={MyTheme} linking={{prefixes: ['/'], config: {
      screens: {
        AdminWelcome: {path: ''}, 
        Users: {path: 'users', screens: {
          Search: {path: 'search'},
          View: {path: 'view'},
          Create: {path: 'create'},
          Edit: {path: 'edit'}
        }}, 
        Brands: {path: 'brands', screens: {
          Search: {path: 'search'},
          View: {path: 'view'},
          Create: {path: 'create'},
          Edit: {path: 'edit'}
        }},
        Carries: {path: 'carries', screens: {
          Search: {path: 'search'},
          View: {path: 'view'},
          Create: {path: 'create'},
          Edit: {path: 'edit'}
        }},
        Booking: {path: 'booking'}
      }
    }}}>
      <Drawer.Navigator 
        initialRouteName='AdminWelcome'
        screenOptions={{
          unmountOnBlur: true,
          drawerStyle: {backgroundColor: theme.colors.headerBackground},
          drawerActiveTintColor: theme.colors.headerActive,
          drawerActiveBackgroundColor: theme.colors.headerBackground,
          drawerInactiveTintColor: theme.colors.headerInactive,
          sceneContainerStyle: {backgroundColor: theme.colors.primaryBackgroundColor},
          headerStyle: {backgroundColor: theme.colors.headerBackground},
          headerRight: () => <CreateButton />
        }}
        drawerContent={(props) => {
          const { navigation, state } = props;

          return (
            <DrawerContentScrollView {...props}>
                <DrawerItemList {...props} />
                <DrawerItem 
                  label={i18n[config.locale].logout_label || 'Logout'}
                  onPress={() => logOut()}
                  icon={({size, color}) => <SimpleLineIcons name="logout" size={size} color={color} />} 
                />
                <LanguageMenuItems />
            </DrawerContentScrollView>
          );
        }}
      >
        <Drawer.Screen 
          name="AdminWelcome" 
          component={AdminWelcomeScreen} 
          options={{ 
            title: i18n[config.locale].screen_admin_welcome_title,
            drawerLabel: i18n[config.locale].screen_admin_welcome_label,
            drawerIcon: ({size, color}) => <SimpleLineIcons name="organization" size={size} color={color} /> 
          }} 
        />
        <Drawer.Screen 
          name="Users" 
          component={AdminUsersScreen} 
          options={{ 
            title: i18n[config.locale].screen_admin_users_title,
            drawerLabel: i18n[config.locale].screen_admin_users_label,
            drawerIcon: ({size, color}) => <SimpleLineIcons name="people" size={size} color={color} />  
          }} 
        />
        <Drawer.Screen 
          name="Brands" 
          component={AdminBrandsScreen} 
          options={{ 
            title: i18n[config.locale].screen_admin_brands_title,
            drawerLabel: i18n[config.locale].screen_admin_brands_label,
            drawerIcon: ({size, color}) => <SimpleLineIcons name="badge" size={size} color={color} />  
          }} 
        />
        <Drawer.Screen 
          name="Carries" 
          component={AdminCarriesScreen} 
          options={{ 
            title: i18n[config.locale].screen_admin_carries_title,
            drawerLabel: i18n[config.locale].screen_admin_carries_label,
            drawerIcon: ({size, color}) => <SimpleLineIcons name="layers" size={size} color={color} />  
          }} 
        />
        <Drawer.Screen 
          name="Booking" 
          component={BookingScreen} 
          options={{ 
            title: i18n[config.locale].screen_booking_title,
            drawerLabel: i18n[config.locale].screen_booking_label,
            drawerIcon: ({size, color}) => <SimpleLineIcons name="game-controller" size={size} color={color} />  
          }} 
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
}

export default AdminNavigator;
