import React from 'react';
import { Text, View, StyleSheet, Platform } from 'react-native';
import { useController } from 'react-hook-form';
import Textarea from './Textarea';
import theme from '../../../../utils/theme';
const { colors } = theme;

const FormInputTextarea = ({
  name, 
  control, 
  label, 
  placeholder="",
  rules, 
  i18n=false, 
  containerStyle={}, 
  labelContainerStyle={},
  labelStyle={},
  requiredStyle={},
  errorStyle={},
  inputStyle={},
  onBlur: onBlurExternal,
  onChange: onChangeExternal,
  value: valueExternal,
  disabled=false
}) => {
  const { field, fieldState, formState } = useController({name, control});
  const { onBlur, onChange, value } = field;
  const { isTouched, isDirty, invalid, error } = fieldState;

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={[styles.labelContainer, labelContainerStyle]}>
        <Text style={[styles.label, labelStyle]}>{label}</Text>
        {rules.required && (<Text style={[styles.required, requiredStyle]}>*</Text>)}
        <Text style={[styles.error, errorStyle]}>
          {invalid && error.message}
        </Text>
      </View>
      {i18n && Object.keys(value).map( ln => {
        const onChangeLocalozed = (v) => onChange({...value, [ln]: v});
        return (
          <Textarea 
            key={`${field.name}-${ln}`} 
            placeholder={ln} 
            containerStyle={[styles.inputContainer, {marginBottom: 5}]} 
            inputStyle={[styles.input, inputStyle]}
            onBlur={onBlurExternal || onBlur}
            onChange={onChangeExternal || onChangeLocalozed}
            value={valueExternal || value[ln] || ''} 
            disabled={disabled}
          />
        );
      })}
      {!i18n && (
        <Textarea 
          value={valueExternal || value}
          onChange={onChangeExternal || onChange}
          onBlur={onBlurExternal || onBlur}
          containerStyle={styles.inputContainer}
          inputStyle={[styles.input, inputStyle]}
          disabled={disabled}
          placeholder={`${placeholder}${rules.required ? ' *' : ''}`} 
        />
      )}
    </View>
  );
}

export default FormInputTextarea;

const styles = StyleSheet.create({
  container: {
    marginBottom: 15
  },
  label: {
    fontFamily: 'RobotoBold',
    fontSize: 14,
    color: colors.primaryTextColor,
    textAlign: 'left'
  },
  labelContainer: {
    flexDirection: 'row',
    marginBottom: 5,
    alignItems: 'center'
  },
  required: {
    fontFamily: 'Roboto',
    fontSize: 14,
    textAlign: 'left',
    color: colors.errorTextColor,
    marginLeft: 5
  },
  error: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: colors.errorTextColor,
    flex: 1,
    textAlign: 'right'
  },
  inputContainer: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: colors.formInputBackgroundColor,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.formInputBorderColor,
  },
  input: {
    marginHorizontal: 0,
    marginVertical: 0,
    verticalAlign: 'top',
    height: 100,
    ...(Platform.OS === 'web' && {
      // outline: 'none',
      overflow: 'auto'
    }),
  },
});