import { useState } from 'react';
import { View, Text, StyleSheet, FlatList, Platform } from 'react-native';
import { SimpleLineIcons } from '@expo/vector-icons';
import BrandsSearchListItem from './BrandsSearchListItem';
import BrandsSearchListEmpty from './BrandsSearchListEmpty';
import BrandSearchForm from './forms/BrandSearchForm';
import SearchField from '../../../components/SearchField';
import ListItem from '../../../components/ListItem';
import Button from '../../../components/Button';

const BrandsSearchList = ({ 
  data={}, 
  onSelect, 
  onWhere,
  where,
  onRefresh,
  refreshing,
  emptyText,
  onCreate,
  onLoadMore,
  footerActions
}) => {
  const hasNoMoreToLoad = data.brands?.items?.length === data.brands?.totalCount;
  const [searchText, setSearchText] = useState('');

  const filterData = ( data ) => {
    if (!data || !data.brands || !data.brands.items) return [];

    return !searchText
      ? [...data.brands.items]
      : [...data.brands.items].filter(
          x => Object.keys(x).some(
            p => !['brand_id', 'lastModifiedBy'].includes(p) && x[p] && typeof x[p] === 'string' && x[p].toLowerCase().includes(searchText.toLowerCase())
          )
        )
  }

  const filtered = filterData(data);

  return (
    <View style={styles.container}>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <BrandSearchForm
          onWhere={onWhere}
          where={where}
        />
        <Button 
          wrapStyleCustom={{paddingVertical: 0}}
          buttonStyleCustom={{paddingVertical: 0, margin: 0}}
          type="control" 
          icon={() => <SimpleLineIcons name="plus" size={24} color="black" />} 
          onPress={onCreate} 
        />
      </View>
      <SearchField
        onSearch={setSearchText} 
        searchText={searchText} 
        wrapStyle={{backgroundColor: '#ffffff', borderBottomWidth: 1, borderColor: '#EFEFEF'}}
        inputWrapStyle={styles.searchInputWrap}
        inputStyle={[styles.searchInput, Platform.OS === 'web' ? {outline: 'none'} : {}]}
        textColor="#5b5b5b"
      />
      <FlatList
        ListEmptyComponent={() => <BrandsSearchListEmpty text={emptyText} />}
        data={filtered}
        onRefresh={onRefresh}
        refreshing={refreshing}
        renderItem={({ item }) => (
          <ListItem 
            onPress={() => onSelect(item)}
            itemContent={() => <BrandsSearchListItem item={item} />}
          />)}
        keyExtractor={item => item.brand_id}
      />
      {footerActions.length > 0 && (
        <View style={styles.footer}>
          {footerActions.map(a => (
            hasNoMoreToLoad ? null : <Button key={Math.random()} text={a.label} onPress={a.action} />
          ))}
        </View>
      )}
    </View>
  );
}

export default BrandsSearchList;

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  searchInputWrap: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    padding: 10,
    marginHorizontal: 16.5, 
    marginVertical: 10,
    backgroundColor: '#f9f9f9',
    borderWidth: 1, 
    borderColor: '#f4f4f4', 
    borderRadius: 4,
  },
  searchInput: {
    flex: 1
  }
});