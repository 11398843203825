import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TextInput, Platform } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { useFormik, getIn } from 'formik';
import * as Yup from 'yup';
import Button from '../../../components/Button';


const buildValidationSchema = () =>
  Yup.object({
    firstname: Yup.string().required('Name is required'),
    lastname: Yup.string().required('Surname is required'),
    facebookName: Yup.string().required('FB name is required'),
    address: Yup.string().required('Address is required'),
    postcode: Yup.string().required('Postcode is required'),
    city: Yup.string().required('City is required'),
    country: Yup.string().matches(/(RS|BA|HR|ME|SI)/).required(),
    role: Yup.string().matches(/(APPROVED|ADMIN)/).required(),
    mobile: Yup.string().required('Mobile is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    facebookLink: Yup.string().url('Facebook link must be valid URL').required('Facebook link is required'),
    heLink: Yup.string().url('HE link must be valid URL').required('HE link is required'),
    password: Yup.string().required().min(6),
    signupReason: Yup.string(),
    whatYouKnowAboutUs: Yup.string(),
    referrals: Yup.string(),
    ownedCarries: Yup.string(),
    monthsUsed: Yup.string(),
    confusion: Yup.string(),
    childrenAgeWidth: Yup.string().required(),
    additionalComments: Yup.string(),
    adminComment: Yup.string(),
    termsAccepted: Yup.boolean().required().oneOf([true], 'Accept Terms & Conditions is required'),
  })

const CreateUserForm = ({
  buttonText = '',
  onSubmit,
  onBack,
}) => {
  const validationSchema = buildValidationSchema();
  const { handleChange, handleBlur, handleSubmit, values, touched, errors } =
    useFormik({
      initialValues: {
        firstname: '',
        lastname: '',
        facebookName: '',
        address: '',
        postcode: '',
        city: '',
        country: '',
        role: 'APPROVED',
        mobile: '',
        email: '',
        facebookLink: '',
        heLink: '',
        password: '',
        childrenAgeWidth: '',
        adminComment: '',
        termsAccepted: true
      },
      validationSchema,
      onSubmit,
    });

  const { 
    formContainer, 
    formFieldContainer, 
    formFieldLabelContainer, 
    formFieldLabel, 
    formFieldLabelRequired, 
    formFieldError, 
    formFieldInput ,
    formFieldTextarea,
    formButtonsContainer
  } = useTheme();

  return (
    <View style={formContainer}>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Name</Text>
          {validationSchema.fields['firstname'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'firstname') && getIn(touched, 'firstname')) && errors['firstname']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('firstname')}
          onBlur={handleBlur('firstname')}
          value={values.firstname}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Surname</Text>
          {validationSchema.fields['lastname'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'lastname') && getIn(touched, 'lastname')) && errors['lastname']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('lastname')}
          onBlur={handleBlur('lastname')}
          value={values.lastname}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>FB Name</Text>
          {validationSchema.fields['facebookName'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'facebookName') && getIn(touched, 'facebookName')) && errors['facebookName']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('facebookName')}
          onBlur={handleBlur('facebookName')}
          value={values.facebookName}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Address</Text>
          {validationSchema.fields['address'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'address') && getIn(touched, 'address')) && errors['address']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('address')}
          onBlur={handleBlur('address')}
          value={values.address}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Postcode</Text>
          {validationSchema.fields['postcode'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'postcode') && getIn(touched, 'postcode')) && errors['postcode']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('postcode')}
          onBlur={handleBlur('postcode')}
          value={values.postcode}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>City</Text>
          {validationSchema.fields['city'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'city') && getIn(touched, 'city')) && errors['city']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('city')}
          onBlur={handleBlur('city')}
          value={values.city}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Country</Text>
          {validationSchema.fields['country'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'country') && getIn(touched, 'country')) && errors['country']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('country')}
          onBlur={handleBlur('country')}
          value={values.country}
          placeholder="RS or BA or HR or ME or SI"
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Mobile</Text>
          {validationSchema.fields['mobile'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'mobile') && getIn(touched, 'mobile')) && errors['mobile']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('mobile')}
          onBlur={handleBlur('mobile')}
          value={values.mobile}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Email</Text>
          {validationSchema.fields['email'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'email') && getIn(touched, 'email')) && errors['email']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('email')}
          onBlur={handleBlur('email')}
          value={values.email}
          keyboardType="email-address"
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>FB link</Text>
          {validationSchema.fields['facebookLink'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'facebookLink') && getIn(touched, 'facebookLink')) && errors['facebookLink']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('facebookLink')}
          onBlur={handleBlur('facebookLink')}
          value={values.facebookLink}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>HE link</Text>
          {validationSchema.fields['heLink'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'heLink') && getIn(touched, 'heLink')) && errors['heLink']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('heLink')}
          onBlur={handleBlur('heLink')}
          value={values.heLink}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Password</Text>
          {validationSchema.fields['password'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'password') && getIn(touched, 'password')) && errors['password']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('password')}
          onBlur={handleBlur('password')}
          value={values.password}
          secureTextEntry
          autoCorrect={false}
          autoCompleteType="password"
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Koji je vaš razlog za ulazak i registraciju?</Text>
          {validationSchema.fields['signupReason'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'signupReason') && getIn(touched, 'signupReason')) && errors['signupReason']}
          </Text>
        </View>
        <TextInput
          style={formFieldTextarea}
          autoCapitalize="none"
          onChangeText={handleChange('signupReason')}
          onBlur={handleBlur('signupReason')}
          value={values.signupReason}
          multiline={true}
          numberOfLines={Platform.OS === "android" ? 5 : null}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Šta znate o nama?</Text>
          {validationSchema.fields['whatYouKnowAboutUs'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'whatYouKnowAboutUs') && getIn(touched, 'whatYouKnowAboutUs')) && errors['whatYouKnowAboutUs']}
          </Text>
        </View>
        <TextInput
          style={formFieldTextarea}
          autoCapitalize="none"
          onChangeText={handleChange('whatYouKnowAboutUs')}
          onBlur={handleBlur('whatYouKnowAboutUs')}
          value={values.whatYouKnowAboutUs}
          multiline={true}
          numberOfLines={Platform.OS === "android" ? 5 : null}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Od koga ste čuli za Lenine putujuće nosiljke?</Text>
          {validationSchema.fields['referrals'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'referrals') && getIn(touched, 'referrals')) && errors['referrals']}
          </Text>
        </View>
        <TextInput
          style={formFieldTextarea}
          autoCapitalize="none"
          onChangeText={handleChange('referrals')}
          onBlur={handleBlur('referrals')}
          value={values.referrals}
          multiline={true}
          numberOfLines={Platform.OS === "android" ? 5 : null}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Molimo vas navedite koje sve marame-nosiljke posedujete?</Text>
          {validationSchema.fields['ownedCarries'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'ownedCarries') && getIn(touched, 'ownedCarries')) && errors['ownedCarries']}
          </Text>
        </View>
        <TextInput
          style={formFieldTextarea}
          autoCapitalize="none"
          onChangeText={handleChange('ownedCarries')}
          onBlur={handleBlur('ownedCarries')}
          value={values.ownedCarries}
          multiline={true}
          numberOfLines={Platform.OS === "android" ? 5 : null}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Koliko dugo-meseci nosite svoju bebu u nekoj od marama/nosiljki?</Text>
          {validationSchema.fields['monthsUsed'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'monthsUsed') && getIn(touched, 'monthsUsed')) && errors['monthsUsed']}
          </Text>
        </View>
        <TextInput
          style={formFieldTextarea}
          autoCapitalize="none"
          onChangeText={handleChange('monthsUsed')}
          onBlur={handleBlur('monthsUsed')}
          value={values.monthsUsed}
          multiline={true}
          numberOfLines={Platform.OS === "android" ? 5 : null}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>U obavezi ste da pročitate pravilnik. Ima li nešto što ne razumete i nije vam jasno, i šta tačno?</Text>
          {validationSchema.fields['confusion'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'confusion') && getIn(touched, 'confusion')) && errors['confusion']}
          </Text>
        </View>
        <TextInput
          style={formFieldTextarea}
          autoCapitalize="none"
          onChangeText={handleChange('confusion')}
          onBlur={handleBlur('confusion')}
          value={values.confusion}
          multiline={true}
          numberOfLines={Platform.OS === "android" ? 5 : null}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Current Children age in months and weight in grams</Text>
          {validationSchema.fields['childrenAgeWidth'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'childrenAgeWidth') && getIn(touched, 'childrenAgeWidth')) && errors['childrenAgeWidth']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('childrenAgeWidth')}
          onBlur={handleBlur('childrenAgeWidth')}
          value={values.childrenAgeWidth}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Additional comment</Text>
          {validationSchema.fields['additionalComments'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'additionalComments') && getIn(touched, 'additionalComments')) && errors['additionalComments']}
          </Text>
        </View>
        <TextInput
          style={formFieldTextarea}
          autoCapitalize="none"
          onChangeText={handleChange('additionalComments')}
          onBlur={handleBlur('additionalComments')}
          value={values.additionalComments}
          multiline={true}
          numberOfLines={Platform.OS === "android" ? 5 : null}
        />
      </View>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Admin comment</Text>
          {validationSchema.fields['adminComment'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'adminComment') && getIn(touched, 'adminComment')) && errors['adminComment']}
          </Text>
        </View>
        <TextInput
          style={formFieldTextarea}
          autoCapitalize="none"
          onChangeText={handleChange('adminComment')}
          onBlur={handleBlur('adminComment')}
          value={values.adminComment}
          multiline={true}
          numberOfLines={Platform.OS === "android" ? 5 : null}
        />
      </View> 
      <View style={formButtonsContainer}>
        <Button size="small" type="info"text="Back" onPress={() => onBack()} />
        <Button size="small" text={buttonText} onPress={handleSubmit} showLoading />
      </View>
    </View>
  )
}

CreateUserForm.propTypes = {
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func,
}

export default CreateUserForm;