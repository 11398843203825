import React from 'react';
import PropTypes from 'prop-types'
import { ScrollView } from 'react-native';
import { ScreenContainer, Section } from '../../../components';
import { useTheme } from '@react-navigation/native';

const QaaScreen = () => {

  const { rulesBodyText } = useTheme();

  return (
    <ScreenContainer>
      <ScrollView>
        <Section accordion heading="Question 1" text={['Answer 1']} />
        <Section accordion heading="Question 2" text={['Answer 2']} />
        <Section accordion heading="Question 3" text={['Answer 3']} />
        <Section accordion heading="Question 4" text={['Answer 4']} />
        <Section accordion heading="Question 5" text={['Answer 5']} />
        <Section accordion heading="Question 6" text={['Answer 6']} />
      </ScrollView>
    </ScreenContainer>
  )
}

export default QaaScreen;
