import React from 'react';
import { createContext, useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Constants from 'expo-constants';
import { useLazyQuery, gql, useApolloClient } from '@apollo/client';
import { listenAuthStateChanged } from '../utils/user';
import { AUTHORIZE } from '../queries';

const AuthContext = createContext();

export const AuthContextProvider = ({
  children,
  initialState = { user: null, role: 'GUEST', token: null, emailVerified: false, isSignout: true, isLoading: true, error: null },
}) => {

  const [state, setState] = useState(initialState);
  const client = useApolloClient();

  const newUserRef = useRef(state.user);
  useEffect(() => { newUserRef.current = state.user })

  useEffect(() => {
    const unsubscribe = listenAuthStateChanged(async (idpUser) => {
      if (!idpUser) {
        setState({ user: null, isLoading: false, error: null, isSignout: true, role: 'GUEST' })
      } else {
        try {
          // throw new Error('Auth ERROR');
          const tokenResult = await idpUser.getIdTokenResult();
          const idToken = await idpUser.getIdToken();
          const idpRole = Constants?.expoConfig?.extra?.adminUserEmail === idpUser.email ? 'ADMIN' : tokenResult.claims.role || (idpUser.emailVerified ? 'VERIFIED' : 'PENDING');
          const { data } = await client.query({
            query: AUTHORIZE,
            variables: newUserRef.current ? {data: {...newUserRef.current, __typename: undefined, role: undefined}} : {},
            context: { headers: { Authorization: `Bearer ${idToken}` }},
            fetchPolicy: "no-cache",
          });
          const { authorize } = data;
          // console.log(authorize);
          setState({ user: authorize.payload ? authorize.payload : newUserRef.current, token: idToken, emailVerified: idpUser.emailVerified, isLoading: false, error: null, isSignout: false, role: idpRole });
        } catch(err) {
          setState({
            ...state,
            isSignout: true,
            isLoading: false,
            user: null,
            token: null,
            role: 'GUEST',
            error: err
          });
        }
        
      }
    });

    return () => unsubscribe();
  }, [])

  const handleSetUser = (data) => {
    console.log('AuthContext::handleSetUser')
    setState({ 
      ...state,
      user: data, 
      error: null 
    });
  }

  const handleResetUser = () => {
    setState({ 
      ...state,
      user: null
    });
  }

  const handleSetEmailVerified = (verifiedState) => {
    setState({ 
      ...state,
      emailVerified: verifiedState,
      role: verifiedState ? 'VERIFIED' : 'PENDING'
    });
  }

  const handleSetUserApproved = () => {
    setState({ ...state, role: 'APPROVED' });
  }

  const value = {
    ...state,
    setUser: handleSetUser,
    resetUser: handleResetUser,
    setEmailVerified: handleSetEmailVerified,
    setUserApproved: handleSetUserApproved
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthContextProvider.propTypes = {
  children: PropTypes.node,
  initialState: PropTypes.object,
}

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);

  if (typeof authContext === 'undefined') {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }

  return authContext
}