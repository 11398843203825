import { useState, useCallback } from 'react';
import { ScrollView, RefreshControl, View, Text, ActivityIndicator, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import Button from '../../../components/Button';
import { ErrorMessage } from '../../../components';
import DeleteModal from '../../../components/DeleteModal';
import { dateTimeRenderer } from '../../../utils/date';
import theme from '../../../utils/theme';
import useDeleteBrand from '../hooks/useDeleteBrand';
import { GET_BRAND } from '../../../queries';
import { useApolloClient } from '@apollo/client';
import { getConfigOptionLabel } from '../../config/utils';

const BrandView = ({ brandData, onBack, onEdit, navigation, where, limit, offset }) => {
  const config = useSelector((state) => state.config);
  const [refreshing, setRefreshing] = useState(false);
  const [ deleteModalVisible, setDeleteModalVisible ] = useState(false);
  const client = useApolloClient();

  const [onDelete, { deleting, errorDeleting }] = useDeleteBrand(navigation, where, limit, offset);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    const d = await client.refetchQueries({ include: [GET_BRAND] });
    setRefreshing(false)
  }, []);

  
  if (!brandData || !brandData.brand) return null;
  const { brand } = brandData;
  const { brand_id } = brand;

  const handleDelete = async () => {
    await onDelete(brand_id);
    setDeleteModalVisible(false);
  }

  return (
    <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />} style={{paddingHorizontal: 20}}>
      <DeleteModal hideModal={() => setDeleteModalVisible(false)} visible={deleteModalVisible} entryName={brand.name} handleDelete={handleDelete} deleting={deleting} />
      <Text style={styles.heading}>{brand.name}</Text>
      <View style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', paddingVertical: 10 }}>
        <View style={{}}>
          <Text style={styles.fieldLabel}>Created At</Text>
          <Text style={styles.defaultText}>{dateTimeRenderer(brand.createdAt)}</Text>
          <Text style={styles.fieldLabel}>Last Modified At</Text>
          <Text style={styles.defaultText}>
            {dateTimeRenderer(brand.modifiedAt)} {"by"} {brand.lastModifiedBy.firstname} {brand.lastModifiedBy.lastname}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Button disabled={deleting} size="small" text="DELETE" onPress={() => setDeleteModalVisible(true)} />
          {deleting && <ActivityIndicator />}
          {errorDeleting && <ErrorMessage error={errorDeleting} />}
        </View>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={styles.fieldLabel}>Country</Text>
        <Text style={styles.defaultText}>{getConfigOptionLabel(config, 'BrandCounties', brand.country)}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={styles.fieldLabel}>Email</Text>
        <Text style={styles.defaultText}>{brand.email}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={styles.fieldLabel}>Contact</Text>
        <Text style={styles.defaultText}>{brand.contact}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={styles.fieldLabel}>Comment</Text>
        <Text style={styles.defaultText}>{brand.comment}</Text>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button size="small" type="info" text="Back" onPress={onBack} />
        <Button size="small" text="Edit" onPress={onEdit} />
      </View>
    </ScrollView>
  )
}

export default BrandView;

const styles = StyleSheet.create({
  heading: {
    color: theme.colors.primaryTextColor,
    fontFamily: 'Roboto',
    textAlign: 'left',
    userSelect: 'text',
    fontSize: 24,
    lineHeight: 32,
    fontWeight: '700',
    marginVertical: 10,
  },
  fieldLabel: {
    fontSize: 14,
    color: theme.colors.primaryTextColor,
    textAlign: 'left',
    userSelect: 'text',
    fontFamily: 'RobotoBold',
  }, 
  defaultText: {
    fontSize: 14,
    color: theme.colors.primaryTextColor,
    fontFamily: 'Roboto',
    textAlign: 'left',
    userSelect: 'text',
  }
});