import { Text, View, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useController } from 'react-hook-form';
import Checkbox from '../../form/components/Checkbox';
import { getConfigOptionLabel } from '../utils';
import theme from '../../../utils/theme';
const { colors } = theme;

const FormInputCheckboxGroupConfig = ({
  name, 
  control, 
  label, 
  type=null, // 'CarryTypes', 'BrandCounties'....
  rules={}, 
  containerStyle={},
  labelContainerStyle={},
  labelStyle={},
  errorStyle={},
  optionsContainerStyle={},
  optionContainerStyle={},
  optionLabelStyle={},
  color="#31a08d",
  width=20,
  height=20,
  borderSize=2,
  iconSize,
  checkedIcon='check',
  enabled=true,
  validate= () => null,
  onChangeAfter
}) => {
  const config = useSelector((state) => state.config);
  const { field, fieldState } = useController({name, control});
  const { onChange, value } = field;
  const { invalid, error } = fieldState;
  const settings = config.settings.filter(x => x.type === type);
  const options = settings.map(x => ({value: x.value, label: getConfigOptionLabel(config, type, x.value)}));

  const toggleOption = (o) => {
    onChange(value.includes(o.value) ? value.filter(x => x !== o.value) : value.concat([o.value]));
    if (onChangeAfter) onChangeAfter(o);
    validate(name);
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={[styles.labelContainer, labelContainerStyle]}>
        <Text style={[styles.label, labelStyle]}>{label}</Text>
        {rules.required && (<Text style={[styles.required, requiredStyle]}>*</Text>)}
        <Text style={[styles.error, errorStyle]}>
          {invalid && error.message}
        </Text>
      </View>
      {options.length && (
        <View style={[styles.optionsContainer, optionsContainerStyle]}>
          {options.map((option, index) => (
            <View key={`${name}-${option.value}`}>
              {option.children && (
                <>
                </>
              )}
              {!option.children && (
                <View style={[styles.optionContainer, optionContainerStyle]}>
                  <Text style={[styles.optionLabel, optionLabelStyle]}>{option.label}</Text>
                  <Checkbox 
                    onPress={() => toggleOption(option)} 
                    status={value.includes(option.value) ? 'checked' : 'unchecked'}
                    color={color}
                    width={width}
                    height={height}
                    borderSize={borderSize}
                    iconSize={iconSize}
                    checkedIcon={checkedIcon}
                    disabled={!enabled}
                  /> 
                </View>
              )}
            </View>
          ))}
        </View>
      )}
    </View>
  );
}

export default FormInputCheckboxGroupConfig;

const styles = StyleSheet.create({
  container: {
    marginBottom: 15
  },
  labelContainer: {
    flexDirection: 'row',
    marginBottom: 5,
    alignItems: 'center'
  },
  label: {
    fontFamily: 'RobotoBold',
    fontSize: 14,
    color: colors.primaryTextColor,
    textAlign: 'left'
  },
  required: {
    fontFamily: 'Roboto',
    fontSize: 14,
    textAlign: 'left',
    color: colors.errorTextColor,
    marginLeft: 5
  },
  error: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: colors.errorTextColor,
    flex: 1,
    textAlign: 'right'
  },
  optionsContainer: {
    paddingVertical: 10,
  },
  optionContainer: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingVertical: 5
  },
  optionLabel: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 16
  },
});