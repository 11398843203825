import * as Yup from 'yup';

export const buildSchema = (editMode=false) => Yup.object({
	name: Yup.string().required('Name is required'),
	description: Yup.string(),
	type: Yup.string().required(),
	brand: Yup.string().required(),
	size: Yup.string(),
	experienceLevel: Yup.string(),
	color: Yup.string(),
	origin: Yup.string(),
	fabricDensity: Yup.string(),
	material: Yup.string(),
	monthFrom: Yup.string().matches(/^\d+$/, 'monthFrom must be numeric').required(), // ageLimitFrom
	monthTo: Yup.string().matches(/^\d+$/, 'monthTo must be numeric').required(),
	weightFrom: Yup.string().matches(/^\d+$/, 'weightFrom must be numeric').required(), // weightLimitFrom
	weightTo: Yup.string().matches(/^\d+$/, 'weightTo must be numeric').required(),
	recension: Yup.string(),
	tags: Yup.string(),
	adminComment: Yup.string(),
	state: Yup.string(),
	bookableFrom: Yup.string(),
	bookTest: Yup.string().required(), // usedFor
	countriesBooking: Yup.array(),
	...(editMode && {image: Yup.object().nullable()}),
	...(editMode && {carry_id: Yup.string().required()}),
})