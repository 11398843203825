import { useState } from 'react';
import { View } from 'react-native';
import SearchScreen from './Search';
import ViewScreen from './View';
import CreateScreen from './Create';
import EditScreen from './Edit';
import { useAuthContext } from '../../../context/useAuthContext';

const BookingScreen = ({ navigation, route }) => {
  const { user, role } = useAuthContext();
  const [page, setPage] = useState('search'); // view | create | edit | search (default)
  const [params, setParams] = useState({}); // carry_id
  const [where, setWhere] = useState(role === 'ADMIN' ? null : {user: {eq: user.user_id}}); // {country: {eq: "ME"}}

  const navigate = (page, params) => {
    setPage(page);
    setParams(params);
  }

  return (
    <View style={{}}>
      {page === 'search' && <SearchScreen navigation={{ navigate }} where={where} setWhere={setWhere} />}
      {page === 'view' && <ViewScreen navigation={{ navigate }} {...params} route={{}} where={where} />}
      {page === 'edit' && <EditScreen navigation={{ navigate }} {...params} route={{}} where={where} />}
      {page === 'create' && <CreateScreen navigation={{ navigate }} where={where} />}
    </View>
  );
}

export default BookingScreen;
