import React from 'react';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';
import { SimpleLineIcons } from '@expo/vector-icons';
import GuestWelcomeScreen from '../features/guest-welcome/screens/GuestWelcomeScreen';
import SignUpScreen from '../features/sign-up/screens/SignUpScreen';
import SignInScreen from '../features/sign-in/screens/SignInScreen';
import RulesScreen from '../features/rules/screens/RulesScreen';
import QaaScreen from '../features/questions-answers/screens/QaaScreen';
import ForgotPasswordScreen from '../features/forgot-password/screens/ForgotPasswordScreen';
import LanguageMenuItems from '../features/language-switcher/components/LanguageMenuItems';

import { useSelector } from 'react-redux';
import Constants from 'expo-constants';


import theme from '../utils/theme';
const MyTheme = { ...DefaultTheme, ...theme };
const Drawer = createDrawerNavigator();

const GuestAppNavigator = () => {
  const config = useSelector((state) => state.config);
  const i18n = Constants?.expoConfig?.extra?.i18n;

  return (
    <NavigationContainer theme={MyTheme} linking={{prefixes: ['/'], config: {
      screens: {
        GuestWelcome: {
          path: 'welcome'
        },
        SignIn: {
          path: 'signin'
        },
        SignUp: {
          path: 'signup'
        },
        ForgotPassword: {
          path: 'forgot'
        },
        Rules: {
          path: 'rules'
        },
        Qaa: {
          path: 'qaa'
        }
      }
    }}}>
      <Drawer.Navigator
        initialRouteName='GuestWelcome'
        screenOptions={{
          drawerLabelStyle: {},
          drawerStyle: {backgroundColor: theme.colors.headerBackground},
          drawerActiveTintColor: theme.colors.headerActive,
          drawerActiveBackgroundColor: theme.colors.headerBackground,
          drawerInactiveTintColor: theme.colors.headerInactive,
          sceneContainerStyle: {backgroundColor: theme.colors.primaryBackgroundColor},
          headerStyle: {backgroundColor: theme.colors.headerBackground}
        }}
        drawerContent={(props) => (
          <DrawerContentScrollView {...props}>
              <DrawerItemList {...props} />
              <LanguageMenuItems />
          </DrawerContentScrollView>
        )}
      >
        <Drawer.Screen 
          name="GuestWelcome" 
          component={GuestWelcomeScreen} 
          options={{ 
            title: i18n[config.locale].screen_guest_welcome_title, // 'Welcome', 
            drawerLabel: i18n[config.locale].screen_guest_welcome_label, //'Home', 
            drawerIcon: ({size, color}) => <SimpleLineIcons name="home" size={size} color={color} />,
            drawerItemStyle: {} 
          }} />
        <Drawer.Screen 
          name="SignUp" 
          component={SignUpScreen} 
          options={{ 
            title: i18n[config.locale].screen_signup_title, 
            drawerLabel: i18n[config.locale].screen_signup_label,
            drawerIcon: ({size, color}) => <SimpleLineIcons name="user" size={size} color={color} /> 
          }} 
        />
        <Drawer.Screen 
          name="SignIn" 
          component={SignInScreen} 
          options={{ 
            title: i18n[config.locale].screen_signin_title, 
            drawerLabel: i18n[config.locale].screen_signin_label,
            drawerIcon: ({size, color}) => <SimpleLineIcons name="login" size={size} color={color} /> 
          }} 
        />
        <Drawer.Screen 
          name="ForgotPassword" 
          component={ForgotPasswordScreen} 
          options={{ 
            title: i18n[config.locale].screen_forgot_password_title, 
            drawerLabel: i18n[config.locale].screen_forgot_password_label,
            drawerIcon: ({size, color}) => <SimpleLineIcons name="options" size={size} color={color} /> 
          }} 
        />
        <Drawer.Screen
          name="Rules" 
          component={RulesScreen} 
          options={{ 
            title: i18n[config.locale].screen_rules_title,
            drawerLabel: i18n[config.locale].screen_rules_label,
            drawerIcon: ({size, color}) => <SimpleLineIcons name="info" size={size} color={color} /> 
          }} 
        />
        <Drawer.Screen 
          name="Qaa" 
          component={QaaScreen} 
          options={{ 
            title: i18n[config.locale].screen_qaa_title, 
            drawerLabel: i18n[config.locale].screen_qaa_label,
            drawerIcon: ({size, color}) => <SimpleLineIcons name="question" size={size} color={color} /> 
          }} 
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
}

export default GuestAppNavigator