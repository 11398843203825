import React from 'react';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_CARRIES, CREATE_CARRY } from '../../../queries';

const useCreate = (navigation, where) => {
  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const [createCarry] = useMutation(CREATE_CARRY, { context });
  const [state, setState] = useState({ creating: false, errorCreating: null });

  const handle = async (data) => {
    setState({ creating: true, errorCreating: null });
    try {
      const x = await createCarry({
        variables: {data: { 
          ...data, 
          size: !data.size ? null : data.size,
          experienceLevel: !data.experienceLevel ? null : data.experienceLevel,
          bookableFrom: new Date(data.bookableFrom).valueOf() || undefined,
        }},
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: GET_CARRIES, variables: { where }, context }
        ]
      });
      setState({ creating: false, errorCreating: null });
      navigation.navigate('search');
    } catch (error) {
      setState({ creating: false, errorCreating: error });
    }
  }

  return [handle, { ...state }];
}

export default useCreate;