import { useState } from 'react';
import PropTypes from 'prop-types';
import { View, Text, ScrollView } from 'react-native';
import { ErrorMessage } from '../../../components';
import CreateBookingForm from '../components/CreateBookingForm';


const BookingCreateScreen = ({ navigation, where }) => {
  const [error, setError] = useState(null);

  const backToSearchScreen = () => navigation.navigate('search');

  return (
    <ScrollView style={{paddingHorizontal: 20}}>
      <ErrorMessage error={error} />
      <CreateBookingForm
        buttonText="Create Booking" 
        onBack={backToSearchScreen} 
        {...{ navigation, where }}
      />
    </ScrollView>
  )
}

BookingCreateScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default BookingCreateScreen;