import * as Yup from 'yup';

export const buildSchema = (config={}) => {
	const BRAND_COUNTRIES = config.settings.filter(x => x.type === "BrandCounties").map(x => x.value).join('|');

	return Yup.object({
		name: Yup.string().required('Name is required'),
	    contact: Yup.string(),
	    email: Yup.string(),
	    comment: Yup.string(),
	    country: Yup.string().matches(new RegExp(`(${BRAND_COUNTRIES})`)).required(),
	    deleted: Yup.boolean()
	});
}

export const buildBrandSearchSchema = (config={}) => {
	return Yup.object({
	    country: Yup.string(),
	});
}