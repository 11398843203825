import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import theme from '../../../utils/theme';
const { colors } = theme;

const CarriesSearchListEmpty = ({ text }) => {

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{text}</Text>
    </View>
  )
}

export default CarriesSearchListEmpty;

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 14,
    color: colors.primaryTextColor,
    fontFamily: 'Roboto',
    marginVertical: 5,
    textAlign: 'center'
  },
});