import Constants from 'expo-constants';
const i18n = Constants?.expoConfig?.extra?.i18n;

export const COUNTRIES_MAP = {
	"RS": "Serbia",
	"BA": "Bosnia and Hercegovina",
	"HR": "Croatia",
	"ME": "Montenegro",
	"PL": "Poland",
	"US": "United States",
	"BE": "Belgium",
	"FI": "Finland",
	"DE": "Germany",
	"CA": "Canada",
	"UK": "England",
	"SE": "Sweden",
	"NL": "Netherlands",
	"MX": "Mexico",
	"AU": "Australia",
	"IN": "India",
	"IT": "Italy",
	"HU": "Hungary",
	"NZ": "New Zealand",
	"PT": "Portugal",
	"TR": "Turkey",
	"RO": "Romania",
	"SI": "Slovenia",
	"SK": "Slovakia",
	"CZ": "Czech Republic",
	"AT": "Austria",
	"FR": "France",
	"BG": "Bulgaria",
	"MY": "Malaysia",
	"RU": "Russia",
	"SCT":  "Scotland",
	"DK": "Denmark",
	"IL": "Israel",
	"NO": "Norway",
	"UA": "Ukraine"
};

export const BRAND_COUNTRIES_MAP = {
	"RS": "Serbia",
	"BA": "Bosnia and Hercegovina",
	"HR": "Croatia",
	"ME": "Montenegro",
	"PL": "Poland",
	"US": "United States",
	"BE": "Belgium",
	"FI": "Finland",
	"DE": "Germany",
	"CA": "Canada",
	"UK": "England",
	"SE": "Sweden",
	"NL": "Netherlands",
	"MX": "Mexico",
	"AU": "Australia",
	"IN": "India",
	"IT": "Italy",
	"HU": "Hungary",
	"NZ": "New Zealand",
	"PT": "Portugal",
	"TR": "Turkey",
	"RO": "Romania",
	"SI": "Slovenia",
	"SK": "Slovakia",
	"CZ": "Czech Republic",
	"AT": "Austria",
	"FR": "France",
	"BG": "Bulgaria",
	"MY": "Malaysia",
	"RU": "Russia",
	"SCT":  "Scotland",
	"DK": "Denmark",
	"IL": "Israel",
	"NO": "Norway",
	"UA": "Ukraine"
};