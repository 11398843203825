import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_TYPE } from '../../../queries';
import { setSettings } from '../../../store/actions/';

const useGetConfigs = () => {
  const locale = useSelector((state) => state.config.locale);
  const dispatch = useDispatch();
  const [getType, { loading, error, data }] = useLazyQuery(GET_TYPE);
  const [state, setState] = useState({ fetching: false, errorFetching: null });

  const handle = async () => {
    setState({ fetching: true, errorFetching: null });
    try {
      const carryTypes = await getType({variables: { name: "CarryTypes"}});
      const carryTypesOptions = carryTypes.data.__type.enumValues.map(x => ({value: x.name, label: x.name, type: "CarryTypes" }));
      const brandCounties = await getType({variables: {name: "BrandCounties"}});
      const brandCountiesOptions = brandCounties.data.__type.enumValues.map(x => ({value: x.name, label: x.name, type: "BrandCounties" }));
      const counties = await getType({variables: {name: "Counties"}});
      const countiesOptions = counties.data.__type.enumValues.map(x => ({value: x.name, label: x.name, type: "Counties" }));
      const sizesTypes = await getType({variables: {name: "Sizes"}});
      const sizesTypesOptions = sizesTypes.data.__type.enumValues.map(x => ({value: x.name, label: x.name, type: "Sizes" }));
      const experienceLevels = await getType({variables: {name: "ExperienceRequirement"}});
      const experienceLevelsOptions = experienceLevels.data.__type.enumValues.map(x => ({value: x.name, label: x.name, type: "ExperienceRequirement" }));
      const carryUsage = await getType({variables: {name: "Usage"}});
      const carryUsageOptions = carryUsage.data.__type.enumValues.map(x => ({value: x.name, label: x.name, type: "Usage" }));
      
      dispatch(setSettings([].concat(
        carryTypesOptions, 
        brandCountiesOptions, 
        countiesOptions,
        sizesTypesOptions,
        experienceLevelsOptions,
        carryUsageOptions
      )));
      setState({ fetching: false, errorFetching: null });
    } catch (error) {
      setState({ fetching: false, errorFetching: error });
    }
  }

  return [handle, { ...state }];
}

export default useGetConfigs;