import { combineReducers } from 'redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistReducer } from 'redux-persist';
// import authReducer from './authReducer';
import configReducer from './configReducer';

export default combineReducers({
  // auth: persistReducer({key: 'auth', storage: AsyncStorage}, authReducer),
  config: persistReducer({key: 'config', storage: AsyncStorage}, configReducer),
});
