import React from 'react';
import { Text, Linking, Platform } from 'react-native';

const Anchor = ({ href, target, ...props }) => {
  const nativeProps = Platform.select({
    web: {
      href,
      target,
    },
    default: {
      onPress: (event) => {
        props.onPress && props.onPress(event);
        if (Platform.OS !== 'web' && href !== undefined) {
          Linking.openURL(href);
        }
      },
    },
  });

  return <Text role="link" {...props} {...nativeProps} />;
}

export default Anchor;