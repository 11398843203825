import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useAuthContext } from '../../../context/useAuthContext';
import { UPDATE_BRAND, GET_BRAND, GET_BRANDS } from '../../../queries';

const useUpdateBrand = (navigation, where, limit, offset) => {
  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const [updateBrand] = useMutation(UPDATE_BRAND, { context });
  const [state, setState] = useState({ updating: false, errorUpdating: null });

  const handle = async (brand_id, data) => {
    setState({ updating: true, errorUpdating: null });
    try {   
      const x = await updateBrand({
        variables: { brand_id, data },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: GET_BRAND, variables: { brand_id }, context },
          { query: GET_BRANDS, variables: { where, limit, offset }, context },
        ]
      });
      navigation.navigate('view', { brand_id });
      setState({ updating: false, errorUpdating: null });
    } catch (error) {
      setState({ updating: false, errorUpdating: error });
    }
  }

  return [handle, { ...state }];
}

export default useUpdateBrand;