import React from 'react';
import { connect } from 'react-redux';
import { DrawerItem } from '@react-navigation/drawer';
import { SimpleLineIcons } from '@expo/vector-icons';
import { setCurrentLocale } from '../../../store/actions';
import Constants from 'expo-constants';

const LanguageMenuItems = ({ config, setCurrentLocale }) => {
  const locales = config.allowedLocales.filter(x => x !== config.locale);
  const i18n = Constants?.expoConfig?.extra?.i18n;

  if (!locales.length) return null;

  return (
    <> 
      {locales.map(l => {
        return (
          <DrawerItem 
            key={l}
            label={i18n[config.locale][`language_label_${l}`] || l}
            onPress={() => setCurrentLocale(l)}
            icon={({size, color}) => <SimpleLineIcons name="globe" size={size} color={color} />} 
          />
        );
      })}
    </>       
  );
}

const mapStateToProps = state => ({ 
  config: state.config, 
});

export default connect(mapStateToProps, { setCurrentLocale })(LanguageMenuItems);
