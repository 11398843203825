import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { View, Text, ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import Constants from 'expo-constants';
import { ErrorMessage } from '../../../components';
import UserSearchList from '../components/UserSearchList';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_ALL_IDP_USERS } from '../../../queries';
import { useQuery, useApolloClient } from '@apollo/client';

const UserSearchScreen = ({ navigation }) => {
  const config = useSelector((state) => state.config);
  const i18n = Constants?.expoConfig?.extra?.i18n;

  const client = useApolloClient();

  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const [searchText, setSearchText] = useState('');
  const [refreshing, setRefreshing] = useState(false);

  const handleView = ({ user_id, disabled }) => {
    navigation.navigate('Users', {screen: 'View', params: { user_id, disabled }})
  }

  const handleRefresh = async () => {
    setRefreshing(true);
    await client.refetchQueries({
      include: [GET_ALL_IDP_USERS],
    });
    setRefreshing(false);
  }

  const filterUsers = ( data ) => {
    if (!data || !data.idpUsers) return [];

    return !searchText
      ? [...data.idpUsers].filter(
          x => !x.emailVerified || !x.role
        ).sort((a, b) => b.createdAt < a.createdAt ? -1 : (b.createdAt > a.createdAt ? 1 : 0)) 
      : [...data.idpUsers].filter(
          x => Object.keys(x).some(
            p => !['user_id'].includes(p) && x[p] && typeof x[p] === 'string' && x[p].toLowerCase().includes(searchText.toLowerCase())
          )
        ).sort((a, b) => b.createdAt < a.createdAt ? -1 : (b.createdAt > a.createdAt ? 1 : 0));
  }

  const { loading, error, data } = useQuery(GET_ALL_IDP_USERS, { context });

  if (loading) return <ActivityIndicator />;
  const filtered = filterUsers(data);

  return (
    <>
      <ErrorMessage error={error} />
      <UserSearchList 
        data={filtered}
        onSelect={handleView}
        onSearch={setSearchText}
        onRefresh={handleRefresh}
        refreshing={refreshing}
        searchText={searchText}
        searchPlaceholder={i18n[config.locale].user_search_placeholder}
        emptyText={i18n[config.locale].user_search_empty}
      />
    </>
  )
}

UserSearchScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default UserSearchScreen;