import React from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';

const Checkbox = ({
  disabled, // boolean
  onPress,
  indeterminateIcon='CheckboxMinusIcon',
  checkedIcon='CheckboxMarkIcon',
  color="#cccccc",
  value, 
  status, // 'checked' | 'unchecked' | 'indeterminate'
  testID,
  index,
  width=20,
  height=20,
  borderSize=2,
  thicknessSize=2,
  ...rest
}) => {
  const checked = status === 'checked';
  const indeterminate = status === 'indeterminate';

  return (
    <Pressable
      {...rest}
      role="checkbox"
      accessibilityState={{ disabled, checked }}
      onPress={disabled ? undefined : onPress}
      style={[styles.container, {width, height, borderColor: color, borderWidth: borderSize}]}
    >
      <View style={{ opacity: indeterminate || checked ? 1 : 0 }}>
        {checked && (<View style={{ width: width-10, height: height-10, backgroundColor: color }} />)}
      </View>
    </Pressable>
  );
};

export default Checkbox;

const styles = StyleSheet.create({
  container: {
    borderRadius: 3,
    backgroundColor: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center'
  },
});

