import React from 'react';
import { SimpleLineIcons } from '@expo/vector-icons';
import { View, TextInput, StyleSheet } from 'react-native';

const Textinput = ({
  disabled, // boolean 
  icon,  // magnifier
  onBlur,
  onFocus,
  onChange,
  value,
  placeholder,
  placeholderTextColor, 
  containerStyle={},
  textInputStyle={},
  ...rest
}) => {

  return (
    <View style={[styles.container, containerStyle]}>
        <TextInput
            {...rest}
            style={[{flex: 1, outline: 'none'}, textInputStyle]}
            readOnly={disabled ? true : false}
            onBlur={onBlur}
            onFocus={onFocus}
            onChangeText={onChange}
            value={value}
            placeholder={placeholder}
            placeholderTextColor={placeholderTextColor? placeholderTextColor : "#5b5b5b"}
        />
        {icon ? (<SimpleLineIcons name={icon} size={24} color="#5b5b5b" />) : null}
    </View>
  );
};

export default Textinput;

const styles = StyleSheet.create({
  container: {}
});