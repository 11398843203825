import { useState } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, ActivityIndicator } from 'react-native';
import { ErrorMessage } from '../../../components';
import EditCarryForm from '../components/EditCarryForm';
import { useAuthContext } from '../../../context/useAuthContext';
import { GET_CARRY } from '../../../queries';
import { useQuery } from '@apollo/client';

const CarryEditScreen = ({ navigation, route, carry_id, where }) => {
  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const { loading, error, data } = useQuery(GET_CARRY, { variables: { carry_id }, context });

  const backToViewScreen = () => navigation.navigate('view', { carry_id });

  if (loading) return <ActivityIndicator />;

  return (
    <ScrollView style={{paddingHorizontal: 20}}>
      <ErrorMessage error={error} />
      <EditCarryForm
        buttonText="Save" 
        onBack={backToViewScreen} 
        carryData={data}
        navigation={navigation}
        where={where}
      />
    </ScrollView>
  )
}

CarryEditScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default CarryEditScreen;