import React from 'react';
import { View, TextInput } from 'react-native';
import { SimpleLineIcons } from '@expo/vector-icons';

const Search = ({ 
  onSearch, 
  searchText, 
  wrapStyle, 
  inputWrapStyle, 
  inputStyle, 
  placeholderText, 
  textColor 
}) => (      
  <View style={wrapStyle}>
    <View style={inputWrapStyle}>
      <TextInput
        style={inputStyle}
        onChangeText={onSearch}
        value={searchText}
        placeholder={placeholderText} //"Hae kohdetta"
        placehoderTextColor={textColor} //'#5b5b5b'
      />
      <SimpleLineIcons name="magnifier" size={24} color="black" />
    </View>
  </View>
);

export default Search;
