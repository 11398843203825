import React from 'react';
import { useState, useEffect } from 'react';
import { Text, Pressable, View, StyleSheet, ActivityIndicator } from 'react-native';
import { useTheme } from '@react-navigation/native';

const BUTTON_TEXT_FONT_SIZE = { tiny: 10, small: 12, medium: 14, large: 16, giant: 18 };
const BUTTON_TEXT_LINE_HEIGHT = { tiny: 12, small: 16, medium: 16, large: 20, giant: 24 };
const BUTTON_PADDING_VERTICAL = { tiny: 6, small: 8, medium: 12, large: 14, giant: 16 };
const BUTTON_PADDING_HORIZONTAL = { tiny: 14, small: 19, medium: 23, large: 24, giant: 28 };

/**
 *  - text
 *  - icon: 
 *  - disabled: true | false
 *  - showLoading: true | false
 *  - layout: filled |outline | ghost
 *  - type: basic | primary | success | info | warning | danger | control
 *  - size: tiny | small | medium | large | giant
 *  - buttonStyleCustom: {}
 *  - buttonTextStyleCustom: {}
 *  - wrapStyleCustom: {}
 *  - onPress: () => null
 */

const Button = ({ 
  onPress, 
  text, 
  icon: IconComponent, 
  type='primary', 
  layout='filled', 
  size='medium', 
  disabled, 
  showLoading=false, 
  buttonStyleCustom, 
  buttonTextStyleCustom, 
  wrapStyleCustom 
}) => {

  const { colors } = useTheme();
  // default , hover, active, focus, disabled, loading
  const [stateValue, setStateValue] = useState('default');
  const [isLoading, setIsLoading] = useState(false);

  const hasIconAndText = IconComponent && !!text;

  useEffect(() => {
    setStateValue(disabled ? 'disabled' : 'default');
  }, [disabled]);

  const buttonTextStyleEnriched = {
    fontSize: BUTTON_TEXT_FONT_SIZE[size],
    lineHeight: BUTTON_TEXT_LINE_HEIGHT[size],
    color: disabled ? colors.disabledColor : colors[`button_${type}_${layout}_CtaTextColor`],
    marginLeft: isLoading ? 14 : 0
  };

  const buttonStyleEnriched = {
    paddingVertical: BUTTON_PADDING_VERTICAL[size],
    paddingHorizontal: BUTTON_PADDING_HORIZONTAL[size],
    backgroundColor: disabled
                        ? colors.disabledBgColor 
                        : layout !== 'ghost' 
                          ? colors[`button_${type}_${layout}_${stateValue}_CtaBgColor`]
                          : 'transparent',
    borderWidth: layout === 'outline' || stateValue === 'focus' ? 1 : 0,
    borderColor: disabled 
                        ? colors.disabledBgColor
                        : colors[`button_${type}_${stateValue}_CtaBorderColor`],
    margin: layout === 'outline' || stateValue === 'focus' ? -1 : 2,
    opacity: disabled ? 0.24 : 1,
    flexDirection: hasIconAndText || isLoading ? 'row' : null,
    alignItems: hasIconAndText || isLoading ? 'center' : null,
    width: hasIconAndText ? '100%' : null,
    justifyContent: hasIconAndText ? 'flex-start' : null,
  };

  const onClick = async () => {
    if (showLoading) setIsLoading(true);
    // await new Promise(r => setTimeout(r, 5000));
    await onPress();
    if (showLoading) setIsLoading(false);
  }

  return (
    <View style={[styles.wrapStyle, wrapStyleCustom]}>
      <Pressable
        disabled={stateValue === 'disabled' || isLoading}
        onPress={onClick}
        onFocus={() => setStateValue(stateValue !== 'disabled' ? 'focus' : stateValue)}
        onBlur={() => setStateValue(stateValue !== 'disabled' ? 'default' : stateValue)}
        style={[styles.buttonStyle, buttonStyleEnriched, buttonStyleCustom]}>
        {!isLoading && IconComponent && <IconComponent fill={buttonTextStyleCustom?.color || buttonTextStyleEnriched.color} />}
        {isLoading && <ActivityIndicator size="small" color={buttonTextStyleCustom?.color || buttonTextStyleEnriched.color} />}
        {!!text && <Text style={[styles.buttonTextStyle, buttonTextStyleEnriched, buttonTextStyleCustom]}>{text}</Text>}
      </Pressable>
    </View>
  );
}

export default Button;

const styles = StyleSheet.create({
  wrapStyle: {
    alignItems: 'center',
    paddingVertical: 10,
  },
  buttonStyle: {
    borderStyle: 'solid',
    borderRadius: 4
  },
  buttonTextStyle: {
    fontSize: 36,
    fontWeight: '700',
    fontFamily: 'Roboto',
    lineHeight: 48,
    textAlign: 'left',
    textAlign: 'center',
    userSelect: 'text'
  },
});
