import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TextInput } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { useFormik, getIn } from 'formik';
import * as Yup from 'yup';
import Button from '../../../components/Button';


const buildValidationSchema = () =>
  Yup.object({
    password: Yup.string().required('Password is required').min(6),
  })

const ReauthenticateForm = ({
  buttonText = '',
  onSubmit,
}) => {
  const validationSchema = buildValidationSchema();
  const { handleChange, handleBlur, handleSubmit, values, touched, errors } =
    useFormik({
      initialValues: { password: '' },
      validationSchema,
      onSubmit,
    });

  const { 
    formContainer, 
    formFieldContainer, 
    formFieldLabelContainer, 
    formFieldLabel, 
    formFieldLabelRequired, 
    formFieldError, 
    formFieldInput ,
    formFieldTextarea
  } = useTheme();

  return (
    <View style={formContainer}>
      <View style={formFieldContainer}>
        <View style={formFieldLabelContainer}>
          <Text style={formFieldLabel}>Password</Text>
          {validationSchema.fields['password'].exclusiveTests.required && (<Text style={formFieldLabelRequired}>*</Text>)}
          <Text style={formFieldError}>
            {(getIn(errors, 'password') && getIn(touched, 'password')) && errors['password']}
          </Text>
        </View>
        <TextInput
          style={formFieldInput}
          autoCapitalize="none"
          onChangeText={handleChange('password')}
          onBlur={handleBlur('password')}
          value={values.password}
          secureTextEntry
          autoCorrect={false}
          autoCompleteType="password"
        />
      </View>
      <Button text={buttonText} onPress={handleSubmit} showLoading />
    </View>
  )
}

ReauthenticateForm.propTypes = {
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

export default ReauthenticateForm;