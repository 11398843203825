import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@react-navigation/native';
import { View, Text } from 'react-native';
import { ScreenContainer, ErrorMessage } from '../../../components';
import { useAuthContext } from '../../../context/useAuthContext';

const AdminWelcomeScreen = ({ navigation }) => {
  const { user } = useAuthContext();
  const {firstname, lastname, email} = user || {};
  const [error, setError] = useState(null);
  const {heading1, paragraph} = useTheme();

  return (
    <ScreenContainer>
      <ErrorMessage error={error} />
      <View>
        <Text style={heading1}>Hello {firstname} {lastname}!</Text>
        <Text style={paragraph}>Your are ADMIN.</Text>
      </View>
    </ScreenContainer>
  )
}

AdminWelcomeScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default AdminWelcomeScreen;