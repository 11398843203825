import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import { useTheme } from '@react-navigation/native';

const SuccessMessage = ({ text }) => {
  const { successContainer, successText } = useTheme();

  if (!text) return null

  return (
    <View style={successContainer}>
      <Text style={successText}>{text}</Text>
    </View>
  )
}

SuccessMessage.propTypes = {
  text: PropTypes.string,
}

export default SuccessMessage;