import React from 'react';
import { useState, useCallback } from 'react';
import { useTheme } from '@react-navigation/native';
import { ScrollView, RefreshControl, View, Text, Platform } from 'react-native';
import Button from '../../../components/Button';
import { useAuthContext } from '../../../context/useAuthContext';
import Anchor from '../../../components/Anchor';
import { dateTimeRenderer } from '../../../utils/date';
import { fullUser, GET_ALL_IDP_USERS, SET_USER_ROLE, DISABLE_USER, ENABLE_USER } from '../../../queries';
import { useMutation, useApolloClient } from '@apollo/client';

const UserView = ({ disabled, userData, onBack, onEdit }) => {

  const [isDisabled, setIsDisabled] = useState(disabled === "true" || false);
  const [refreshing, setRefreshing] = useState(false);
  const client = useApolloClient();
  const { token } = useAuthContext();
  const context = {headers: {Authorization: `Bearer ${token}`}};
  const {heading1, paragraph, formFieldLabel, formDefaultText} = useTheme();

  const [setUserRole] = useMutation(SET_USER_ROLE, { context });
  const [disableUser] = useMutation(DISABLE_USER, { context });
  const [enableUser] = useMutation(ENABLE_USER, { context });

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    const d = await client.refetchQueries({
      include: [fullUser],
    });
    setRefreshing(false);
  }, []);

  const handleApproved = async () => {
    setRefreshing(true);
    await setUserRole({
      variables: { user_id, role: 'APPROVED' },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: fullUser, variables: { user_id }, context }
      ]
    });
    setRefreshing(false);
  }

  const handleAdmin = async () => {
    setRefreshing(true);
    await setUserRole({
      variables: { user_id, role: 'ADMIN' },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: fullUser, variables: { user_id }, context }
      ]
    });
    setRefreshing(false);
  }

  const handleDisable = async () => {
    setRefreshing(true);
    await disableUser({
      variables: { user_id },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: fullUser, variables: { user_id }, context },
        { query: GET_ALL_IDP_USERS, context }
      ]
    });
    setIsDisabled(true);
    setRefreshing(false);
  }

  const handleEnable = async () => {
    setRefreshing(true);
    await enableUser({
      variables: { user_id },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: fullUser, variables: { user_id }, context },
        { query: GET_ALL_IDP_USERS, context }
      ]
    });
    setIsDisabled(false);
    setRefreshing(false);
  }

  if (!userData || !userData.user) return null;
  const { user } = userData;
  const { user_id } = user;

  return (
    <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />} style={{paddingHorizontal: 20}}>
      <Text style={heading1}>{user.firstname} {user.lastname}</Text>
      <View style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', paddingVertical: 10 }}>
        <View style={{}}>
          <Text style={formFieldLabel}>Status</Text>
          <Text style={formDefaultText}>{user.role}</Text>
          <Text style={formFieldLabel}>Created At</Text>
          <Text style={formDefaultText}>{dateTimeRenderer(user.createdAt)}</Text>
          <Text style={formFieldLabel}>Last Modified At</Text>
          <Text style={formDefaultText}>
            {dateTimeRenderer(user.modifiedAt)} {"by"} {user.lastModifiedBy.firstname} {user.lastModifiedBy.lastname}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          {['VERIFIED','PENDING'].includes(user.role) && (
            <Button disabled={refreshing} size="small" text="APPROVE" onPress={() => handleApproved()} />
          )}
          {['APPROVED'].includes(user.role) && (
            <Button disabled={refreshing} size="small" text="Make Admin" onPress={() => handleAdmin()} />
          )}
          {['ADMIN'].includes(user.role) && (
            <Button disabled={refreshing} size="small" text="Remove from Admin's" onPress={() => handleApproved()} />
          )}
          {isDisabled && <Button disabled={refreshing} size="small" type="success" text="Enable" onPress={() => handleEnable()} />}
          {!isDisabled && <Button disabled={refreshing} size="small" type="danger" text="Disable" onPress={() => handleDisable()} />}
        </View>
      </View>
      <View style={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column', alignItems: 'flex-start', justifyContent: 'space-between', paddingVertical: 10 }}>
        <View style={{}}>
          <Text style={formFieldLabel}>Address</Text>
          <Text style={formDefaultText}>{user.address}</Text>
          <Text style={formDefaultText}>{user.postcode}</Text>
          <Text style={formDefaultText}>{user.city}</Text>
          <Text style={formDefaultText}>{user.country}</Text>
        </View>
        <View style={{}}>
          <Text style={formFieldLabel}>Email</Text>
          <Text style={formDefaultText}>{user.email}</Text>
        </View>
        <View style={{}}>
          <Text style={formFieldLabel}>Mobile</Text>
          <Text style={formDefaultText}>{user.mobile}</Text>
        </View>
      </View>
      <View style={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column', alignItems: 'flex-start', justifyContent: 'space-between', paddingVertical: 10 }}>
        <View style={{}}>
          <Text style={formFieldLabel}>FB name</Text>
          <Text style={formDefaultText}>{user.facebookName}</Text>
        </View>
        <View style={{}}>
          <Text style={formFieldLabel}>FB link</Text>
          <Anchor style={{color: 'blue', textDecorationLine: 'underline'}} href={user.facebookLink} target="_blank">{user.facebookLink}</Anchor>
        </View>
        <View style={{}}>
          <Text style={formFieldLabel}>HE link</Text>
          <Anchor style={{color: 'blue', textDecorationLine: 'underline'}} href={user.heLink} target="_blank">{user.heLink}</Anchor>
        </View>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>Koji je vaš razlog za ulazak i registraciju?</Text>
        <Text style={formDefaultText}>{user.signupReason}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>Šta znate o nama?</Text>
        <Text style={formDefaultText}>{user.whatYouKnowAboutUs}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>Od koga ste čuli za Lenine putujuće nosiljke?</Text>
        <Text style={formDefaultText}>{user.referrals}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>Molimo vas navedite koje sve marame-nosiljke posedujete?</Text>
        <Text style={formDefaultText}>{user.ownedCarries}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>Koliko dugo-meseci nosite svoju bebu u nekoj od marama/nosiljki?</Text>
        <Text style={formDefaultText}>{user.monthsUsed}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>U obavezi ste da pročitate pravilnik. Ima li nešto što ne razumete i nije vam jasno, i šta tačno?</Text>
        <Text style={formDefaultText}>{user.confusion}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>Current Children age in months and weight in grams</Text>
        <Text style={formDefaultText}>{user.childrenAgeWidth}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>Additional comment</Text>
        <Text style={formDefaultText}>{user.additionalComments}</Text>
      </View>
      <View style={{paddingVertical: 10}}>
        <Text style={formFieldLabel}>adminComment</Text>
        <Text style={formDefaultText}>{user.adminComment}</Text>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button size="small" type="info" text="Back" onPress={onBack} />
        <Button size="small" text="Edit" onPress={onEdit} />
      </View>
    </ScrollView>
  )
}

export default UserView;